import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, Checkbox } from "antd";
import { useState, useEffect } from "react";
import { State, City } from "country-state-city";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetSignup } from "../../../slices/signupSlice";
import { uploadProfile } from "../../../slices/axiosSlice"; // Update the path
import loadingGif from "../../assets/loading.gif";
import Loading from "../../assets/loading-circle.gif";

import {
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
const OPTIONS = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function Corp_SignUpSelector() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadStatus = useSelector((state) => state.axios.status);

  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const options = [
    { label: "BRONZE", value: "NGO_BRONZE" },
    { label: "SILVER", value: "NGO_SILVER" },
    { label: "GOLD", value: "NGO_GOLD" },
    { label: "PLATINUM", value: "NGO_PLATINUM" },
  ];
  const [form] = Form.useForm();

  const signupData = useSelector((state) => state.signup);
  const CheckboxGroup = Checkbox.Group;
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
  }, []);
  const formDataWithFiles = new FormData();
  const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };
  const onsubmit = (values) => {
    setBlur(true);
    setLoading(true);
    const corpProfile = {
      id: "",
      name: values.name,
      regCinNumber: values.regCinNumber,
      gstNumber: values.gstNumber,
      pan: values.pan,
      headOfOrg: values.headOfOrg,
      categoryPref: values.categoryPref.toString(),
      cityPref: "",
      membershipLevelPref: values.membershipLevelPref.toString(),
      about: values.about,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const contactInfo = {
      entityId: "",
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: "India",
      pinCode: values.pinCode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const corpCoordinator = {
      entityId: "",
      coordinatorEmail: values.coordinatorEmail,
      coordinatorName: values.coordinatorName,
      coordinatorPhone: values.coordinatorPhone,
      designation: values.designation,
      createdDate: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
    };

    formDataWithFiles.append("userCred", JSON.stringify(signupData));
    formDataWithFiles.append("corpProfile", JSON.stringify(corpProfile));
    formDataWithFiles.append("contactInfo", JSON.stringify(contactInfo));
    formDataWithFiles.append(
      "corpCoordinator",
      JSON.stringify(corpCoordinator)
    );

    dispatch(uploadProfile({ url: "corp/signup", formData: formDataWithFiles }))
      .unwrap()
      .then((response) => {
        setBlur(false);
        setLoading(false);
        window.toastr.warning("Profile creation request received successfully");
        navigate("/emailsent");
        dispatch(resetSignup());
      })
      .catch((error) => {
        let logStsDesc = error.message.messageDetails[2];
        window.toastr.warning(logStsDesc);
        navigate("/corpsignin");
      });
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
        <body className="bg-light  glasscard m-4 coporatesignup">
          <Form
            onFinish={onsubmit}
            layout="vertical"
            form={form}
            scrollToFirstError={true}
            name="validateOnly"
            // labelCol={{ span: 8 }}
          >
            <div className="text-center">
              <div
                className="pt-2 b"
                style={{ fontSize: "16px", color: "black" }}
              >
                Company Details
              </div>
              <hr style={{ borderTop: "1px dashed" }} />
            </div>

            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-md-6  mt-1"
                  // style={{ borderRight: "1px solid #d9d9d9" }}
                >
                  <div className="m-0">
                    <Form.Item
                      className=""
                      name="name"
                      label="Company Name :"
                      rules={[
                        {
                          required: true,
                          message: "Company Name is required",
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        placeholder="Enter the Company Name"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="regCinNumber"
                      label="CIN Number / Registration Number :"
                      rules={[
                        {
                          required: true,
                          message: "Registration Number is required",
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        placeholder="Enter the Registration Number"
                        maxLength={21}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="gstNumber"
                      label="GST Number :"
                      rules={[
                        {
                          required: true,
                          message: "GST Number is required",
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        placeholder="Enter the GST Number"
                        maxLength={15}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="pan"
                      label="PAN Number :"
                      rules={[
                        {
                          required: true,
                          message: "Pan Number is required",
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        placeholder="Enter the Pan Number"
                        maxLength={10}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="headOfOrg"
                      label=" CEO / Head of Organization :"
                      rules={[
                        {
                          required: true,
                          message: "CEO Name is required",
                        },
                      ]}
                    >
                      <Input size="medium" placeholder="CEO Name of Company" />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="categoryPref"
                      label="Category Preferences :"
                      rules={[
                        {
                          required: true,
                          message: "Category Preferences is Required",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="--NGO Category--"
                        value={selectedItems}
                        onChange={setSelectedItems}
                        style={{
                          width: "100%",
                        }}
                        options={filteredOptions.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="email"
                      label="Company Email :"
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                        {
                          type: "email",
                          message: "Enter valid email",
                        },
                      ]}
                    >
                      <Input size="medium" placeholder="Enter the Email" />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="phone"
                      label="Company Phone:"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required",
                        },
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        type="number"
                        placeholder="Enter the Phone number"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: "State is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="--Select State--"
                        onChange={handleStateChange}
                        value={selectedState}
                        showSearch
                      >
                        {states.map((state) => (
                          <Option key={state} value={state}>
                            {state}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="--Select City--"
                        onChange={handleCityChange}
                        showSearch
                        value={selectedCity}
                        disabled={!selectedState}
                      >
                        {cities.map((city) => (
                          <Option key={city.value} value={city.value}>
                            {city.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="pinCode"
                      label="PinCode :"
                      rules={[
                        {
                          required: true,
                          message: "PinCode is required",
                        },
                      ]}
                    >
                      <Input
                        size="medium"
                        placeholder="Enter the PinCode"
                        maxLength={6}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className=" "
                      name="addressLine1"
                      label="Company Address :"
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                        },
                      ]}
                    >
                      <Input.TextArea
                        showCount
                        maxLength={250}
                        style={{ height: 100, resize: "none" }}
                        placeholder="Address of Company (In 250 words)"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-md-6 mt-1">
                  <div className="row ">
                    {/* <legend className="  mx-3">
                CONTACT DETAILS:
              </legend> */}
                    <div>
                      <Form.Item
                        className=""
                        name="membershipLevelPref"
                        label="NGO Preference"
                        rules={[
                          {
                            required: true,
                            message: "NGO Prefrence is required",
                          },
                        ]}
                      >
                        <Checkbox.Group options={options} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=""
                        name="website"
                        label="Website"
                        values={"NGO"}
                        requiredMark="optional"
                        rules={[
                          {
                            type: "url",
                            message: "please enter correct url",
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          placeholder="Website if you already have."
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=""
                        name="facebook"
                        label="Facebook"
                        rules={[
                          {
                            type: "url",
                            message: "please enter correct url",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<FacebookOutlined />}
                          placeholder="FaceBook ID"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=""
                        name="instagram"
                        label="Instagram"
                        rules={[
                          {
                            type: "url",
                            message: "please enter correct url",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<InstagramOutlined />}
                          placeholder="Instagram ID"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=""
                        name="linkedin"
                        label="Linkedin"
                        rules={[
                          {
                            type: "url",
                            message: "please enter correct url",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<LinkedinOutlined />}
                          placeholder="LinkedIn ID"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=""
                        name="twitter"
                        label="Twitter"
                        rules={[
                          {
                            type: "url",
                            message: "please enter correct url",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<TwitterOutlined />}
                          placeholder="Twitter ID"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>

                    {/* <div>
                <Form.Item className="" name="otherlinks" label="Other links">
                  <Input
                    addonBefore={<LinkOutlined />}
                    placeholder="Other Links(Optional)"
                  />
                </Form.Item>
              </div> */}

                    <div>
                      <Form.Item
                        className=" "
                        name="coordinatorEmail"
                        label="Coordinator Email :"
                        rules={[
                          {
                            required: true,
                            message: "coordinatorEmail is required",
                          },
                          {
                            type: "email",
                            message: "Enter valid email",
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          placeholder="Enter the coordinatorEmail"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=" "
                        name="coordinatorName"
                        label="Coordinator Name :"
                        rules={[
                          {
                            required: true,
                            message: "coordinatorName is required",
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          placeholder="Enter the coordinatorName"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=" "
                        name="coordinatorPhone"
                        label="Coordinator Phone :"
                        rules={[
                          {
                            required: true,
                            message: "coordinatorPhone is required",
                          },
                          {
                            validator: validatePhoneNumber,
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          type="number"
                          placeholder="Enter the coordinatorPhone"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=" "
                        name="designation"
                        label="Designation :"
                        rules={[
                          {
                            required: true,
                            message: "designation is required",
                          },
                        ]}
                      >
                        <Input
                          size="medium"
                          placeholder="Enter the designation"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className=" "
                        name="about"
                        label="About Company:"
                        rules={[
                          {
                            required: true,
                            message: "About is required",
                          },
                        ]}
                      >
                        <Input.TextArea
                          showCount
                          maxLength={350}
                          style={{ minHeight: 175, resize: "none" }}
                          placeholder="About the Company (In 350 words)"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col text-end">
                <NavLink to="/corpsignup">
                  <Button
                    name="signupback"
                    className="btnnca"
                    style={{ width: "90px" }}
                  >
                    {" "}
                    <i className="fa fa-arrow-left mx-2"></i>
                    Back
                  </Button>
                </NavLink>
              </div>
              &nbsp;&nbsp;
              <div className="col">
                <Button
                  htmlType="submit"
                  className="btnnca btn-gold signupbtn"
                  style={{ width: "90px" }}
                >
                  Submit <i className="fa fa-arrow-right mx-1"></i>
                </Button>
              </div>
            </div>
          </Form>
        </body>
      </div>
    </>
  );
}

export default Corp_SignUpSelector;
