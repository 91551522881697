import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Upload,
  Tooltip,
  Modal,
  Select,
  Checkbox,
} from "antd";
import { useState, useEffect } from "react";
import { State, City } from "country-state-city";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadProfile } from "../../../slices/axiosSlice"; // Update the path
import UploadService from "../../service/file-upload.service";

import {
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
const OPTIONS = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function Corp_Addinfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadStatus = useSelector((state) => state.axios.status);

  const { Option } = Select;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userCred, setUserCred] = useState();
  const [userRoleAuthMapping, setUserRoleAuthMapping] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const options = [
    { label: "BRONZE", value: "NGO_BRONZE" },
    { label: "SILVER", value: "NGO_SILVER" },
    { label: "GOLD", value: "NGO_GOLD" },
    { label: "PLATINUM", value: "NGO_PLATINUM" },
  ];
  const [form] = Form.useForm();
  const [emailvalidation, setEmailvalidation] = useState();
  const movetohome = () => {
    window.location.href = "/";
  };
  let token = "";
  let eToken = "";

  const signupData = useSelector((state) => state.signup);

  const CheckboxGroup = Checkbox.Group;
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  const handleOk = () => {
    navigate("/");
  };
  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    token = url.searchParams.get("token");
    eToken = url.searchParams.get("eToken");
    const userAuthTracker = {
      id: 0,
      userId: "",
      email: "",
      uniqueToken: token,
      emailToken: eToken,
      authType: "",
      authMode: "",
      timeNow: null,
      expiryTime: 0,
      modifiedTime: null,
      status: "",
      count: 0,
    };

    const formDataEmailverify = new FormData();

    formDataEmailverify.append(
      "userAuthTracker",
      JSON.stringify(userAuthTracker)
    );

    UploadService.create(
      "corp/validateEmailVerifyTokens/getCorpProfile",
      formDataEmailverify
    ).then((response) => {
      setUserCred(response.data.data.userCred);
      setUserRoleAuthMapping(response.data.data.userRoleAuthMapping);
      form.setFieldsValue({
        name: response.data.data.corpProfile.name,
        regCinNumber: response.data.data.corpProfile.regCinNumber,
        gstNumber: response.data.data.corpProfile.gstNumber,
        pan:response.data.data.corpProfile.pan,
        headOfOrg: response.data.data.corpProfile.headOfOrg,
        categoryPref: response.data.data.corpProfile.categoryPref,
        membershipLevelPref: response.data.data.corpProfile.membershipLevelPref,
        about: response.data.data.corpProfile.about,
        addressLine1: response.data.data.contactInfo.addressLine1,
        addressLine2: "",
        city: response.data.data.contactInfo.city,
        state: response.data.data.contactInfo.state,
        pinCode: response.data.data.contactInfo.pinCode,
        email: response.data.data.contactInfo.email,
        phone: response.data.data.contactInfo.phone,
        website: response.data.data.contactInfo.website,
        facebook: response.data.data.contactInfo.facebook,
        instagram: response.data.data.contactInfo.instagram,
        linkedin: response.data.data.contactInfo.linkedin,
        twitter: response.data.data.contactInfo.twitter,
        otherlinks: response.data.data.contactInfo.otherlinks,
        coordinatorEmail: response.data.data.corpCoordinator.coordinatorEmail,
        coordinatorName: response.data.data.corpCoordinator.coordinatorName,
        coordinatorPhone: response.data.data.corpCoordinator.coordinatorPhone,
        designation: response.data.data.corpCoordinator.designation,
      });
    });
  }, []);
  const formDataWithFiles = new FormData();

  const onsubmit = (values) => {
    const corpProfile = {
      id: userCred.associatedEntity,
      name: values.name,
      regCinNumber: values.regCinNumber,
      gstNumber: values.gstNumber,
      pan: values.pan,
      headOfOrg: values.headOfOrg,
      categoryPref: values.categoryPref.toString(),
      cityPref: "",
      membershipLevelPref: values.membershipLevelPref.toString(),
      about: values.about,
      createdBy: userCred.createdBy,
      createdDate: userCred.createdDate,
      updatedBy: userCred.updatedBy,
      updatedDate: userCred.updatedDate,
    };
    const contactInfo = {
      entityId: userCred.associatedEntity,
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: "India",
      pinCode: values.pinCode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "",
      createdBy: userCred.createdBy,
      createdDate: userCred.createdDate,
      updatedBy: userCred.updatedBy,
      updatedDate: userCred.updatedDate,
    };
    const corpCoordinator = {
      entityId: userCred.associatedEntity,
      coordinatorEmail: values.coordinatorEmail,
      coordinatorName: values.coordinatorName,
      coordinatorPhone: values.coordinatorPhone,
      designation: values.designation,
      createdDate: userCred.createdDate,
      createdBy: userCred.createdBy,
      updatedDate: userCred.updatedDate,
      updatedBy: userCred.updatedBy,
    };

    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    formDataWithFiles.append("corpProfile", JSON.stringify(corpProfile));
    formDataWithFiles.append("contactInfo", JSON.stringify(contactInfo));
    formDataWithFiles.append(
      "corpCoordinator",
      JSON.stringify(corpCoordinator)
    );

    dispatch(
      uploadProfile({
        url: "corp/additionalInfo/updateProfile",
        formData: formDataWithFiles,
      })
    )
      .unwrap()
      .then((response) => {
        setIsModalOpen(true);
      })
      .catch((error) => {
        // navigate("/corpsignin");
      });
  };

  return (
    <>
      <body className="bg-light  glasscard m-4 coporatesignup">
        <Form
          onFinish={onsubmit}
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          name="validateOnly"
          // labelCol={{ span: 8 }}
        >
          <div className="text-center">
            <div
              className="pt-2 b"
              style={{ fontSize: "16px", color: "black" }}
            >
              Company Details
            </div>
            <hr style={{ borderTop: "1px dashed" }} />
          </div>
          <div className="col-md-12 row ">
            <div
              className="col-md-6   mt-1"
              // style={{ borderRight: "1px solid #d9d9d9" }}
            >
              {/* <legend className="  mx-3">
              PROFILE DETAILS:
            </legend> */}

              <div className="m-0">
                <Form.Item
                  className=""
                  name="name"
                  label="Company Name :"
                  rules={[
                    {
                      required: true,
                      message: "Company Name is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Company Name" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="regCinNumber"
                  label="CIN Number / Registration Number :"
                  rules={[
                    {
                      required: true,
                      message: "Registration Number is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the Registration Number"
                    maxLength={21}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="gstNumber"
                  label="GST Number :"
                  rules={[
                    {
                      required: true,
                      message: "GST Number is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the GST Number"
                    maxLength={15}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="pan"
                  label="PAN Number :"
                  rules={[
                    {
                      required: true,
                      message: "Pan Number is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the Pan Number"
                    maxLength={10}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="headOfOrg"
                  label=" CEO / Head of Organization :"
                  rules={[
                    {
                      required: true,
                      message: "CEO Name is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="CEO Name of Company" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="categoryPref"
                  label="Category Preferences :"
                  rules={[
                    {
                      required: true,
                      message: "Category Preferences is Required",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="--NGO Category--"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{
                      width: "100%",
                    }}
                    options={filteredOptions.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="email"
                  label="Company Email :"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Email" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="phone"
                  label="Company Phone:"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Phone number" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "State is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="--Select State--"
                    onChange={handleStateChange}
                    value={selectedState}
                    showSearch
                  >
                    {states.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "City is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="--Select City--"
                    onChange={handleCityChange}
                    showSearch
                    value={selectedCity}
                    disabled={!selectedState}
                  >
                    {cities.map((city) => (
                      <Option key={city.value} value={city.value}>
                        {city.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="pinCode"
                  label="PinCode :"
                  rules={[
                    {
                      required: true,
                      message: "PinCode is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the PinCode"
                    maxLength={6}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="addressLine1"
                  label="Company Address :"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={250}
                    style={{ height: 150, resize: "none" }}
                    placeholder="Address of Company (In 250 words)"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="col-md-6 mt-1">
              <div className="row ">
                {/* <legend className="  mx-3">
                CONTACT DETAILS:
              </legend> */}
                <div>
                  <Form.Item
                    className=""
                    name="membershipLevelPref"
                    label="NGO Preference"
                    rules={[
                      {
                        required: true,
                        message: "NGO Prefrence is required",
                      },
                    ]}
                  >
                    <Checkbox.Group options={options} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="website"
                    label="Website"
                    values={"NGO"}
                    requiredMark="optional"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Website if you already have."
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="facebook"
                    label="Facebook"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<FacebookOutlined />}
                      placeholder="FaceBook ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="instagram"
                    label="Instagram"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<InstagramOutlined />}
                      placeholder="Instagram ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="linkedin"
                    label="Linkedin"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<LinkedinOutlined />}
                      placeholder="LinkedIn ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="twitter"
                    label="Twitter"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<TwitterOutlined />}
                      placeholder="Twitter ID"
                      rules={[
                        {
                          type: "url",
                          message: "please enter correct url",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>

                {/* <div>
                <Form.Item className="" name="otherlinks" label="Other links">
                  <Input
                    addonBefore={<LinkOutlined />}
                    placeholder="Other Links(Optional)"
                  />
                </Form.Item>
              </div> */}

                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorEmail"
                    label="Coordinator Email :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorEmail is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorEmail"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorName"
                    label="Coordinator Name :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorName is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorName"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorPhone"
                    label="Coordinator Phone :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorPhone is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorPhone"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="designation"
                    label="Designation :"
                    rules={[
                      {
                        required: true,
                        message: "designation is required",
                      },
                    ]}
                  >
                    <Input size="medium" placeholder="Enter the designation" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="about"
                    label="About Company:"
                    rules={[
                      {
                        required: true,
                        message: "About is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={350}
                      style={{ minHeight: 155, resize: "none" }}
                      placeholder="About the Company (In 350 words)"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="">
              <Button
                htmlType="submit"
                className="btnnca btn-gold signupbtn"
                style={{ width: "90px" }}
              >
                Update <i className="fa fa-arrow-right mx-1"></i>
              </Button>
            </div>
          </div>
        </Form>
      </body>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          Profile updated successfully and sent for admin approval.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default Corp_Addinfo;
