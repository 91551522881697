import { Link } from "react-router-dom";

import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import { Helmet } from "react-helmet";

import Blog1 from "../images/blog1.jpg";
import Blog2 from "../images/blog2.jpg";
import Blog3 from "../images/blog3.jpg";
import Blog4 from "../images/blog4.jpg";

function Blog() {
  return (
    <>
      {/* <Helmet>
        <title>Blogs</title>
        <meta
          name="description"
          content="Doaram  publishes NGO blogs related to child education, child welfare and rights, case studies of happy children , child NGO campaigns, provides solutions to child right's issues in India."
        />
        <link rel="canonical" href="https://www.doaram.com/blog" />
      </Helmet> */}
      <div style={{ backgroundColor: "#eeeeee" }}>
        <Header />
        {/* <!-- Blog Start --> */}
        <div className="container" style={{marginTop:"135px"}}>
          <h1 className="text-center pt-4">Blogs</h1>
          <div className="card-group d-flex justify-content-around mt-5">
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <div className="card mb-3" >
                  <div className="row gutters">
                    <div className="col-md-4">
                      <img src={Blog1} className="card-img mt-3 mt-3" alt="..." style={{height:"200px",width:"150px"}}/>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body" style={{maxWidth:"540px"}}>
                        <p className="card-title font-weight-bold" style={{fontSize:"12px"}}><strong>A Powerful Medium To Create Public Awareness Is Street Play</strong></p>
                        <p className="card-description">Street play is one of the oldest theatre forms where the artists enact in public places with no specific target audience...</p>
                        <button type="button" className="btn btn-outline-success btn-warning text-white btn-sm"><Link to="/blog1"  style={{color:"white"}}>Read More</Link></button>
                      </div>                
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col">
                <div className="card mb-3">
                  <div className="row gutters">
                    <div className="col-md-4">
                      <img src={Blog2} className="card-img mt-3" alt="..." style={{height:"200px",width:"150px"}}/>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body" style={{maxWidth:"540px"}}>
                        <p className="card-title font-weight-bold" style={{fontSize:"12px"}}><strong>Moral Science, A Subject In Schools Should Be Made Mandatory</strong></p>
                        <p className="card-description">Moral education is a value added learning which helps the children to develop a good moral and civic sense to serve the society well.</p>
                        <button type="button" className="btn btn-outline-success btn-warning btn-sm"><Link to="/blog2"  style={{color:"white"}}>Read More</Link></button>
                      </div>                
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col">
                <div className="card mb-3">
                  <div className="row gutters">
                    <div className="col-md-4">
                      <img src={Blog3} className="card-img mt-3" alt="..." style={{height:"200px",width:"150px"}}/>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body" style={{maxWidth:"540px"}}>
                        <p className="card-title font-weight-bold" style={{fontSize:"12px"}}><strong>Self-Help Group: The Role Of NGOs In Promoting SHGs And Its Scope.</strong></p>
                        <p className="card-description">A self-help group is an independent voluntary association formed by women from the same...</p>
                        <button type="button" className="btn btn-outline-success btn-warning btn-sm"><Link to="/blog3"  style={{color:"white"}}>Read More</Link></button>
                      </div>                
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col">
                <div className="card mb-3" >
                  <div className="row gutters">
                    <div className="col-md-4">
                      <img src={Blog4} className="card-img mt-3" alt="..." style={{height:"200px",width:"150px"}}/>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body" style={{maxWidth:"540px"}}>
                        <p className="card-title font-weight-bold" style={{fontSize:"12px"}}><strong>Foot Binding Was A Symbol Of Status, Modesty And Moral Virtue</strong></p>
                        <p className="card-description">Foot binding was one of the unique, ancient practices amongst the upper class women in China. It started...</p>
                        <button type="button" className="btn  btn-warning text-white btn-sm" ><Link to="/blog4" style={{color:"white"}}>Read More</Link></button>
                      </div>                
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            
          </div>
        </div>

        <Footer />
      </div>  
    </>
  );
}

export default Blog;
