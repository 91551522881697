import { NavLink } from "react-router-dom";
import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, Modal, message } from "antd";
import { HomeFilled } from "@ant-design/icons";
import UploadService from "../../service/file-upload.service";
import { useNavigate } from "react-router-dom";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
function Admin_ForgotPassword() {
  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };
  const onsubmit = (values) => {
    const userCred = {
      userId: values.email,
      userName: "",
      password: "",
      termsConditions: "",
      systemUpdateCommunication: "",
      userType: "",
      userRole: "",
      associatedEntity: "",
      status: "Inactive",
      comments: "",
      passwordSetTime: "",
      emailVerified: "Y",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const formDataForgotPassword = new FormData();
    formDataForgotPassword.append("userCred", JSON.stringify(userCred));

    UploadService.create("/admin/forgotPassword", formDataForgotPassword).then(
      (response) => {
        Modal.info({
          content: (
            <div>
              <p>We have sent ResetLink To your Email Sucessfully!</p>
            </div>
          ),
          onOk() {},
        });
      }
    );
  };

  return (
    <>
      <div
        className="bg_dtheme fontfamilynca  col-md-12 row "
        style={{ height: "100vh" }}
      >
        <div className="row ">
          <div className="col-md-6  signupimg" style={boyImageCom}></div>

          <div className="col-md-6  mb-5 signup">
            <NavLink to="/">
              <HomeFilled className="homeicon" />
            </NavLink>
            <div
              className="col-md-9 "
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Form
                onFinishFailed={showerror}
                layout="vertical"
                autoComplete="off"
                onFinish={onsubmit}
                className="row g-3"
              >
                <div className="col-12 text-center">
                  <div className="col-12 text-center mt-5">
                    <img
                      src={require("./../../assets/da_img/logo1.png")}
                      width={100}
                      height={100}
                      alt="DoAram_Logo"
                      className="rounded mx-auto d-block"
                    />
                  </div>
                  <small className="text-white font20 text-sm ">
                    Let's Do Aram
                  </small>
                  <br />
                  <div className="row text-end mt-4 mb-2">
                    <NavLink to="/adminsignin" className="text-white font14">
                      Already User?
                      <span
                        className="text-warning text-sm toolTip top  "
                        data-tip="Login in with your existing account"
                      >
                        &nbsp;<b>Sign In</b>
                      </span>
                    </NavLink>
                  </div>
                </div>

                <div className="input-group mt-0">
                  <span Name="d-input-group-text">
                    <i
                      Name="fa fa-envelope  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div Name="form-floating  border-0">
                    <Form.Item
                      name="email"
                      label="Email Id"
                      rules={[
                        { required: true, message: "email is required" },
                        {
                          type: "email",
                          message: "Enter valid email address",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="doaram@gmail.com" bordered={false} />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>
                <div className="col-12  mt-4">
                  <div className="d-flex  justify-content-center">
                    <Button
                      htmlType="submit"
                      className="btnnca btn-gold col-md-5 signupbtn"
                      style={{ height: "45px" }}
                    >
                      Send Email
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin_ForgotPassword;
