import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";

import {
  Form,
  Input,
  message,
  Button,
  Select,
  Upload,
  Modal,
  Table,
  Tooltip,
  Tabs,
} from "antd";
import {
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import $ from "jquery";
function Admin_MembershipProfile() {
  const [Orderstatus, setOrderstatus] = useState([]);
  const [fileName, setfileName] = useState();
  const [ngoProfile, setngoProfile] = useState();

  const [Membershiphistory, setMembershiphistory] = useState([]);

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const beforeUpload = (file) => {
    return false;
  };
  const navigate = useNavigate();

  let viewdata = null;
  let selectedprofile = null;

  let { state } = useLocation();
  if (state != null) {
    viewdata = state;
    selectedprofile = state;
  } else {
    viewdata = null;
    selectedprofile = null;
  }


  const orderstatuscolumns = [
    {
      title: "OrderId",
      dataIndex: "orderId",
    },
    {
      title: "UserId",
      dataIndex: "userId",
    },
    {
      title: "MembershipLevel",
      dataIndex: "membershipLevel",
    },
    {
      title: "Membership_Fee",
      dataIndex: "membershipFee",
    },
    {
      title: "OrderStatus",
      dataIndex: "orderStatus",
    },
    {
      title: "PaymentStatus",
      dataIndex: "paymentStatus",
    },
  ];

  const MembershipHistorycolumns = [
    {
      title: "NGO_Id",
      dataIndex: "ngoId",
    },
    {
      title: "MembershipLevel",
      dataIndex: "membershipLevel",
    },
    {
      title: "MembershipDuration",
      dataIndex: "membershipDuration",
    },
    {
      title: "MembershipStartDate",
      dataIndex: "membershipStartDate",
    },
    {
      title: "MembershipEndDate",
      dataIndex: "membershipEndDate",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  const [form] = Form.useForm();
  const OrderstatusformData = new FormData();
  OrderstatusformData.append("userCred", JSON.stringify(userCred));
  OrderstatusformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  OrderstatusformData.append(
    "ngoAccountUpgradeApproval",
    JSON.stringify(viewdata.viewdata)
  );

  const callImageAPI = (filename) => {
    setfileName(filename);

    const profileimageformData = new FormData();
    profileimageformData.append("userCred", JSON.stringify(userCred));
    profileimageformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    profileimageformData.append("ngoProfile", JSON.stringify(ngoProfile));

    profileimageformData.append("imageName", filename);
    UploadService.filedownload(
      "/admin/ngo/profile/image/get",
      profileimageformData
    )
      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }
  const [logoFilename, setlogoFilename] = useState("");
  const [cert12aFilename, setcert12aFilename] = useState("");

  const [microSiteFilename1, setmicroSiteFilename1] = useState("");
  const [microSiteFilename2, setmicroSiteFilename2] = useState("");
  const [microSiteFilename3, setmicroSiteFilename3] = useState("");

  const [certIncorpRocFilename, setcertIncorpRocFilename] = useState("");
  const [pancardFilename, setpancardFilename] = useState("");
  const [regTrustDeedFilename, setregTrustDeedFilename] = useState("");
  const [moaAoaFilename, setmoaAoaFilename] = useState("");
  const [tanFilename, settanFilename] = useState("");
  const [csrForm1Filename, setcsrForm1Filename] = useState("");
  const [cert80gFilename, setcert80gFilename] = useState("");
  const [certFcraFilename, setcertFcraFilename] = useState("");

  const [itrFormFilename1, setitrFormFilename1] = useState("");
  const [itrFormFilename2, setitrFormFilename2] = useState("");
  const [itrFormFilename3, setitrFormFilename3] = useState("");

  const [fcraReturnsFilename1, setfcraReturnsFilename1] = useState("");
  const [fcraReturnsFilename2, setfcraReturnsFilename2] = useState("");
  const [fcraReturnsFilename3, setfcraReturnsFilename3] = useState("");

  const [form10BFilename1, setform10BFilename1] = useState("");
  const [form10BFilename2, setform10BFilename2] = useState("");
  const [form10BFilename3, setform10BFilename3] = useState("");

  const islogoEmpty = logoFilename.trim().length === 0;
  const iscert12aEmpty = cert12aFilename.trim().length === 0;

  const ismicroSiteEmpty1 = microSiteFilename1.trim().length === 0;
  const ismicroSiteEmpty2 = microSiteFilename2.trim().length === 0;
  const ismicroSiteEmpty3 = microSiteFilename3.trim().length === 0;

  const iscertIncorpRocEmpty = certIncorpRocFilename.trim().length === 0;
  const ispancardEmpty = pancardFilename.trim().length === 0;
  const isregTrustDeedEmpty = regTrustDeedFilename.trim().length === 0;
  const ismoaAoaEmpty = moaAoaFilename.trim().length === 0;
  const istanEmpty = tanFilename.trim().length === 0;
  const iscsrForm1Empty = csrForm1Filename.trim().length === 0;
  const iscert80gEmpty = cert80gFilename.trim().length === 0;
  const iscertFcraEmpty = certFcraFilename.trim().length === 0;

  const isitrFormEmpty1 = itrFormFilename1.trim().length === 0;
  const isitrFormEmpty2 = itrFormFilename2.trim().length === 0;
  const isitrFormEmpty3 = itrFormFilename3.trim().length === 0;

  const isform10BEmpty1 = form10BFilename1.trim().length === 0;
  const isform10BEmpty2 = form10BFilename2.trim().length === 0;
  const isform10BEmpty3 = form10BFilename3.trim().length === 0;

  const isfcraReturnsEmpty1 = fcraReturnsFilename1.trim().length === 0;
  const isfcraReturnsEmpty2 = fcraReturnsFilename2.trim().length === 0;
  const isfcraReturnsEmpty3 = fcraReturnsFilename3.trim().length === 0;
  useEffect(() => {
    UploadService.create("admin/ngo/profile/gets", OrderstatusformData).then(
      (response) => {
        setngoProfile(response.data.data.ngoProfile);
        if (response.data !== null) {
          form.setFieldsValue({
            name: response.data.data.ngoProfile.name,
            category: response.data.data.ngoProfile.category,
            about: response.data.data.ngoProfile.about,
            history: response.data.data.ngoProfile.history,
            vision: response.data.data.ngoProfile.vision,
            mission: response.data.data.ngoProfile.mission,
            geoFocus: response.data.data.ngoProfile.geoFocus,
            staticWebPageLink: "",
            certIncorpRoc: "",
            moaAoa: "",
            regTrustDeed: "",
            pan: "",
            tan: "",
            csrForm1: "",
            cert12a: "",
            cert80g: "",
            certFcra: "",
            itrForm: "",
            form10B: "",
            fcraReturns: "",
            logo: "",
            micrositeImages: "",

            addressLine1: response.data.data.contactInfo.addressLine1,
            city: response.data.data.contactInfo.city,
            state: response.data.data.contactInfo.state,
            country: response.data.data.contactInfo.country,
            pinCode: response.data.data.contactInfo.pinCode,
            email: response.data.data.contactInfo.email,
            phone: response.data.data.contactInfo.phone,
            website: response.data.data.contactInfo.website,
            facebook: response.data.data.contactInfo.facebook,
            instagram: response.data.data.contactInfo.instagram,
            linkedin: response.data.data.contactInfo.linkedin,
            twitter: response.data.data.contactInfo.twitter,
            otherlinks: response.data.data.contactInfo.otherlinks,

            coordinatorName: response.data.data.coordinator.coordinatorName,
            coordinatorEmail: response.data.data.coordinator.coordinatorEmail,
            coordinatorPhone: response.data.data.coordinator.coordinatorPhone,

            bankName: response.data.data.bankDetails.bankName,
            branchName: response.data.data.bankDetails.branchName,
            accountName: response.data.data.bankDetails.accountName,
            accountNumber: response.data.data.bankDetails.accountNumber,
            ifscCode: response.data.data.bankDetails.ifscCode,
            upiCode: response.data.data.bankDetails.upiCode,
          });
          setcertIncorpRocFilename(response.data.data.ngoProfile.certIncorpRoc);
          setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
          setpancardFilename(response.data.data.ngoProfile.pan);
          setlogoFilename(response.data.data.ngoProfile.logo);
          setcert12aFilename(response.data.data.ngoProfile.cert12a);

          const micrositeimageNames =
            response.data.data.ngoProfile.micrositeImages;
          const micrositeindividualimage = micrositeimageNames.split(",");
          if (micrositeindividualimage[0]) {
            setmicroSiteFilename1(micrositeindividualimage[0]);
          }
          if (micrositeindividualimage[1]) {
            setmicroSiteFilename2(micrositeindividualimage[1]);
          }
          if (micrositeindividualimage[2]) {
            setmicroSiteFilename3(micrositeindividualimage[2]);
          }
          setregTrustDeedFilename(response.data.data.ngoProfile.regTrustDeed);
          setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
          setcertFcraFilename(response.data.data.ngoProfile.certFcra);
          const itrFormimageNames = response.data.data.ngoProfile.itrForm;
          const itrformindividualimage = itrFormimageNames.split(",");
          if (itrformindividualimage[0]) {
            setitrFormFilename1(itrformindividualimage[0]);
          }
          if (itrformindividualimage[1]) {
            setitrFormFilename2(itrformindividualimage[1]);
          }
          if (itrformindividualimage[2]) {
            setitrFormFilename3(itrformindividualimage[2]);
          }
          settanFilename(response.data.data.ngoProfile.tan);
          setcert80gFilename(response.data.data.ngoProfile.cert80g);
          setcsrForm1Filename(response.data.data.ngoProfile.csrForm1);

          const fcraNames = response.data.data.ngoProfile.fcraReturns;
          const fcraindividualimage = fcraNames.split(",");
          if (fcraindividualimage[0]) {
            setfcraReturnsFilename1(fcraindividualimage[0]);
          }
          if (fcraindividualimage[1]) {
            setfcraReturnsFilename2(fcraindividualimage[1]);
          }
          if (fcraindividualimage[2]) {
            setfcraReturnsFilename3(fcraindividualimage[2]);
          }

          const form10BNames = response.data.data.ngoProfile.form10B;
          const form10Bindividualimage = form10BNames.split(",");
          if (form10Bindividualimage[0]) {
            setform10BFilename1(form10Bindividualimage[0]);
          }
          if (form10Bindividualimage[1]) {
            setform10BFilename2(form10Bindividualimage[1]);
          }
          if (form10Bindividualimage[2]) {
            setform10BFilename3(form10Bindividualimage[2]);
          }
        }
      }
    );
    // UploadService.create(
    //   "admin/ngo/membership/order/status/get",
    //   OrderstatusformData
    // ).then((response) => {
    //   if (response.data !== null) {
    //     Orderstatus.push(response.data.data.ngoMembershipUpgradeOrder);
    //   }
    // });
  }, []);

  const orderHistory = () => {
    UploadService.create(
      "admin/ngo/membership/history/get",
      OrderstatusformData
    ).then((response) => {
      if (response.data !== null) {
      }

      setMembershiphistory(response.data.data.ngoMembershipHistory);
    });
  };

  const Approvemembership = () => {
    Modal.confirm({
      title: "Are you sure,You want to Approve this Profile?",
      okText: "Approve",
      onOk: () => {
        UploadService.create(
          "admin/ngo/account/upgrade/approve",
          OrderstatusformData
        ).then((response) => {
          if (response.data !== null) {
            Modal.confirm({
              title:"profile approved Scuessfully",
              onOk: () => {
                navigate("/NGOMembership");
              },
            });
          }
        });
      },
    });
  };
  const Rejectmembership = () => {
    Modal.confirm({
      title: "Are you sure,You want to Reject this Profile?",
      okText: "Reject",
      onOk: () => {
        UploadService.create(
          "admin/ngo/account/upgrade/reject",
          OrderstatusformData
        ).then((response) => {
          if (response.data !== null) {
            Modal.confirm({
              title: "Profile Rejected Sucessfully",
              onOk: () => {
                navigate("/NGOMembership");
              },
            });
          }
        });
      },
    });
  };

  return (
    <>
     
      <div className="col-md-12 row fontfamilynca">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 row m-2">
                <div className="col">
                  <NavLink to="/NGOMembership">
                    <button className="btn btn-violet btn-sm">
                      <i className="fa fa-arrow-left"></i> Back
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          centered
          onChange={(key) => {
            if (key === "2") {
              orderHistory();
            }
          }}
          type="card"
          large
          items={[
            {
              label: " NGO Profile",
              key: "1",
              children: (
                <Form
                  scrollToFirstError={true}
                  name="validateOnly"
                  form={form}
                  labelCol={{ span: 9 }}
                >
                  <div className="col-sm-6">
                    <h6 className="pt-2">Profile Details</h6>
                  </div>
                  <div className="ms-3 me-3">
                    <div className="col-md-12 glasscard p-3">
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ borderRight: "1px solid #f3f3f3" }}
                        >
                          <fieldset>
                            <div className=" p-0">
                              <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                                {/* Name of NGO*/}
                                <legend className="para b themeCol-orange mx-3">
                                  NGO Information:{" "}
                                </legend>
                                <div className="row mb-3">
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="name"
                                      label="NGO Name"
                                      values={"NGO"}
                                      //colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "NGO name is required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        placeholder="Name of  the NGO"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="phone"
                                      label="Contact No"
                                      values={"NGO"}
                                      //colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Contact number is required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        type="number"
                                        placeholder="Contact Number"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="addressLine1"
                                      label="Registered Address"
                                      //colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Address is required",
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        //showCount
                                        style={{ height: 100, resize: "none" }}
                                        placeholder="Registered Address"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="city"
                                      label="City"
                                    >
                                      <Select
                                        placeholder="-Select City-"
                                        disabled
                                      ></Select>
                                    </Form.Item>
                                  </div>

                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="state"
                                      label="State"
                                    >
                                      <Select
                                        placeholder="-Select State-"
                                        disabled
                                      ></Select>
                                    </Form.Item>
                                  </div>

                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="country"
                                      label="Country"
                                    >
                                      <Select
                                        placeholder="-Select Country-"
                                        // options={[
                                        //   {
                                        //     value: "India",
                                        //     label: "India",
                                        //   },
                                        // ]}
                                        disabled
                                      ></Select>
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="category"
                                      label="Category"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Category is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        mode="multiple"
                                        placeholder="--NGO Category--"
                                        style={{
                                          width: "100%",
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className=""
                                      name="website"
                                      label="Website"
                                      values={"NGO"}
                                      requiredMark="optional"
                                      rules={[
                                        {
                                          type: "url",
                                          message: "please enter correct url",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        placeholder="Website if you already have."
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="upload">
                                    <Form.Item
                                      className=""
                                      name="logo"
                                      label="Logo"
                                    >
                                      {islogoEmpty ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm"
                                          onClick={() =>
                                            callImageAPI(logoFilename)
                                          }
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm"
                                        >
                                          Preview
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </div>

                                  <div className="upload">
                                    <Form.Item
                                      className=""
                                      name="micrositeImages"
                                      label=" Images for your website"
                                    >
                                      {ismicroSiteEmpty1 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm"
                                          onClick={() =>
                                            callImageAPI(microSiteFilename1)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                      {ismicroSiteEmpty2 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm mx-3"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm mx-3"
                                          onClick={() =>
                                            callImageAPI(microSiteFilename2)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                      {ismicroSiteEmpty3 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm"
                                          onClick={() =>
                                            callImageAPI(microSiteFilename3)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className=""
                                      name="facebook"
                                      label="Facebook"
                                      rules={[
                                        {
                                          type: "url",
                                          message: "please enter correct url",
                                        },
                                      ]}
                                    >
                                      <Input
                                        addonBefore={<FacebookOutlined />}
                                        placeholder="FaceBook ID"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className=""
                                      name="instagram"
                                      label="Instagram"
                                      rules={[
                                        {
                                          type: "url",
                                          message: "please enter correct url",
                                        },
                                      ]}
                                    >
                                      <Input
                                        addonBefore={<InstagramOutlined />}
                                        placeholder="Instagram ID"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className=""
                                      name="linkedin"
                                      label="Linkedin"
                                      rules={[
                                        {
                                          type: "url",
                                          message: "please enter correct url",
                                        },
                                      ]}
                                    >
                                      <Input
                                        addonBefore={<LinkedinOutlined />}
                                        placeholder="LinkedIn ID"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className=""
                                      name="twitter"
                                      label="Twitter"
                                      rules={[
                                        {
                                          type: "url",
                                          message: "please enter correct url",
                                        },
                                      ]}
                                    >
                                      <Input
                                        addonBefore={<TwitterOutlined />}
                                        placeholder="Twitter ID"
                                        rules={[
                                          {
                                            type: "url",
                                            message: "please enter correct url",
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  </div>
                                  {/* Dont use this for now
                                       <div>
                                        <Form.Item
                                          className=""
                                          name={["contactInfo", "otherlinks"]}
                                          label="Other links"
                                        >
                                          <Input
                                            addonBefore={<LinkOutlined />}
                                            placeholder="Other Links(Optional)"
                                          />
                                        </Form.Item>
                                      </div> */}
                                  {/* *************************ngoCoordinator********************** */}
                                  <legend className="para b themeCol-orange mx-4">
                                    NGO Coordinator Information:{" "}
                                  </legend>
                                  <div>
                                    <Form.Item
                                      name="coordinatorName"
                                      label="Name"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Coordinator name is required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        placeholder="NGO CoordinatoselectedItemsr Name"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      name="coordinatorPhone"
                                      label="Mobile"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Coordinator phone number is required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        type="number"
                                        placeholder="NGO Coordinator Mobile Numner"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      name="coordinatorEmail"
                                      label="Email"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Coordinator email is required",
                                        },
                                        {
                                          type: "email",
                                          message: "Enter valid email",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="medium"
                                        placeholder="NGO Coordinator Email"
                                      />
                                    </Form.Item>
                                  </div>
                                  {/* **********genenral info*********** */}
                                  <legend className="para b themeCol-orange mx-4">
                                    General NGO Information:{" "}
                                  </legend>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="about"
                                      label="About"
                                      //colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: "About is required",
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        showCount
                                        maxLength={250}
                                        style={{ height: 150, resize: "none" }}
                                        placeholder="About the NGO (In 250 words)"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      name="history"
                                      label="Brief History"
                                      //colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: " Brief History is required",
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        showCount
                                        maxLength={250}
                                        style={{ height: 150, resize: "none" }}
                                        placeholder=" Brief History of the NGO (In 250 words)"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item name="vision" label="  Vision">
                                      <Input.TextArea
                                        showCount
                                        maxLength={150}
                                        style={{ height: 130, resize: "none" }}
                                        placeholder="  Vision of the NGO (In 150 words)"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item name="mission" label="  Mission">
                                      <Input.TextArea
                                        showCount
                                        maxLength={100}
                                        style={{ height: 130, resize: "none" }}
                                        placeholder="  Mission of the NGO (In 100 words)"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item
                                      className="para b themeCol-orange"
                                      name="geoFocus"
                                      label="Geography"
                                    >
                                      <Input
                                        size="medium"
                                        placeholder="Geographic Focus of the activity"
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset>
                            <div className=" p-0">
                              {/* *****************Certificate***************** */}
                              <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                                <legend className="para b themeCol-orange mx-2">
                                  Copy of below Certificates to be uploaded:{" "}
                                </legend>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="certIncorpRoc"
                                    label="Incorporation from ROC"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Incorporation  is required",
                                      },
                                    ]}
                                  >
                                    {iscertIncorpRocEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(certIncorpRocFilename)
                                        }
                                      >
                                        Priview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="moaAoa"
                                    label="MOA / AOA"
                                    rules={[
                                      {
                                        required: true,
                                        message: "MOA  is required",
                                      },
                                    ]}
                                  >
                                    {ismoaAoaEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(moaAoaFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="regTrustDeed"
                                    label="  Registered Trust Deed"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Trust Deed  is required",
                                      },
                                    ]}
                                  >
                                    {isregTrustDeedEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(regTrustDeedFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>

                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="pan"
                                    label="PAN"
                                    rules={[
                                      {
                                        required: true,
                                        message: "TAN  is required",
                                      },
                                    ]}
                                  >
                                    {ispancardEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(pancardFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>

                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="tan"
                                    label="TAN"
                                    rules={[
                                      {
                                        required: true,
                                        message: "TAN  is required",
                                      },
                                    ]}
                                  >
                                    {istanEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(tanFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="cert12a"
                                    label=" 12A Certificate"
                                    rules={[
                                      {
                                        required: true,
                                        message: "12A Certificate is required",
                                      },
                                    ]}
                                  >
                                    {iscert12aEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(cert12aFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="cert80g"
                                    label=" 80G Certificate"
                                    rules={[
                                      {
                                        required: true,
                                        message: "80G certificate  is required",
                                      },
                                    ]}
                                  >
                                    {iscert80gEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(cert80gFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="csrForm1"
                                    label=" CSR Form 1"
                                  >
                                    {iscsrForm1Empty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(csrForm1Filename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="certFcra"
                                    label=" FCRA Reg"
                                  >
                                    {iscertFcraEmpty ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(certFcraFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <legend className="para b themeCol-orange">
                                  Only for available Years. Upload all 3 years
                                  together.
                                </legend>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="itrForm"
                                    label="  ITR of last three AYs"
                                  >
                                    {isitrFormEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                    {isitrFormEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm mx-3"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mx-3"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                    {isitrFormEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="upload">
                                  <div className="upload">
                                    <Form.Item
                                      className=""
                                      name="form10B"
                                      label=" Form 10B of last three AYs"
                                    >
                                      {isform10BEmpty1 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm"
                                          onClick={() =>
                                            callImageAPI(form10BFilename1)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                      {isform10BEmpty2 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm mx-3"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm mx-3"
                                          onClick={() =>
                                            callImageAPI(form10BFilename2)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                      {isform10BEmpty3 ? (
                                        <Button
                                          type="button"
                                          className="btnnca btn-pink btn-sm"
                                        >
                                          No File
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm"
                                          onClick={() =>
                                            callImageAPI(form10BFilename3)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="upload">
                                  <Form.Item
                                    className=""
                                    name="fcraReturns"
                                    label=" FCRA Returns of last three FYs"
                                  >
                                    {isfcraReturnsEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                    {isfcraReturnsEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm mx-3"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mx-3"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                    {isfcraReturnsEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-pink btn-sm"
                                      >
                                        No File
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    )}
                                  </Form.Item>
                                </div>
                                {/* ************Bank Details************ */}
                                <legend className="para b themeCol-orange">
                                  Banking Information:{" "}
                                </legend>
                                <div>
                                  <Form.Item
                                    name="bankName"
                                    label=" Bank Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: " Bank name is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="Your Bank Name"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="accountName"
                                    label="Account Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: " Account  name is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="Your Account Name"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="branchName"
                                    label="Branch Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: " Branch name is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="Your Branch Name"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="accountNumber"
                                    label=" Account Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "  Account Number is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      type="number"
                                      placeholder="Your  Account Number"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="ifscCode"
                                    label="IFSC Code"
                                    rules={[
                                      {
                                        required: true,
                                        message: "IFSC Code is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="Your IFSC Code"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="upiCode"
                                    label=" UPI Code"
                                    rules={[
                                      {
                                        required: true,
                                        message: "  UPI Code is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="Your  UPI Code"
                                    />
                                  </Form.Item>
                                </div>

                                <div className="row">
                                  <div className="row">
                                    <div className="d-flex justify-content-center">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold signupbtn"
                                        onClick={Approvemembership}
                                      >
                                        Approve
                                      </Button>

                                      <Button
                                        type="button"
                                        className="btnnca btn-red signupbtn ms-3"
                                        onClick={Rejectmembership}
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              ),
            },
            // {
            //   label: " Order Status",
            //   key: "2",
            //   children: (
            //     <>
            //       <div className="col-sm-6">
            //         <h6 className="pt-2">Order Status:</h6>
            //       </div>
            //       <section className="content  glasscard">
            //         <div className="row">
            //           <div className="mt-3 table-responsive">
            //             <Table
            //               columns={orderstatuscolumns}
            //               dataSource={Orderstatus}
            //               style={{ padding: "5px" }}
            //               bordered
            //               scroll={{
            //                 x: 1000,
            //               }}
            //             />
            //           </div>
            //         </div>
            //       </section>
            //     </>
            //   ),
            // },
            {
              label: "History",
              key: "2",
              children: (
                <>
                    <div className="col-md-12 row fontfamilynca  pt-2 px-4">
                    <div className="col-sm-6">
                      <h6 className="tableheading">MemberShip History:</h6>
                    </div>
                    <section className="content">
                      <div className="row">
                        <div className="mt-3">
                          <Table
                            columns={MembershipHistorycolumns}
                            dataSource={Membershiphistory}
                            style={{ padding: "5px" }}
                            bordered
                            scroll={{
                              x: 1000
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              ),
            },
          ]}
        ></Tabs>
      </div>
    </>
  );
}
export default Admin_MembershipProfile;
