import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Checkbox,
} from "antd";
import { useState, useEffect } from "react";
import { State, City } from "country-state-city";
import { useSelector, useDispatch } from "react-redux";
import { uploadProfile } from "../../../slices/axiosSlice"; // Update the path
import $ from "jquery";
import UploadService from "../../service/file-upload.service";
import Company from "../../assets/icons/comapny.jpeg";
import Loading from "../../assets/loading-circle.gif";

import {
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
const OPTIONS = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

const options = [
  { label: "BRONZE", value: "NGO_BRONZE" },
  { label: "SILVER", value: "NGO_SILVER" },
  { label: "GOLD", value: "NGO_GOLD" },
  { label: "PLATINUM", value: "NGO_PLATINUM" },
];

function Corp_Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadStatus = useSelector((state) => state.axios.status);

  const { Option } = Select;
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const CorpprofileformData = new FormData();

  const [form] = Form.useForm();

  const signupData = useSelector((state) => state.signup);

  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  useEffect(() => {
    CorpprofileformData.append("userCred", JSON.stringify(userCred));
    CorpprofileformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );

    UploadService.create("corp/getProfile", CorpprofileformData).then(
      (response) => {
        setProfile(response.data.data);
        form.setFieldsValue({
          name: response.data.data.corpProfile.name,
          regCinNumber: response.data.data.corpProfile.regCinNumber,
          gstNumber: response.data.data.corpProfile.gstNumber,
          headOfOrg: response.data.data.corpProfile.headOfOrg,
          categoryPref: response.data.data.corpProfile.categoryPref,
          membershipLevelPref:
            response.data.data.corpProfile.membershipLevelPref,
          about: response.data.data.corpProfile.about,
          addressLine1: response.data.data.contactInfo.addressLine1,
          addressLine2: "",
          city: response.data.data.contactInfo.city,
          state: response.data.data.contactInfo.state,
          pinCode: response.data.data.contactInfo.pinCode,
          email: response.data.data.contactInfo.email,
          phone: response.data.data.contactInfo.phone,
          website: response.data.data.contactInfo.website,
          facebook: response.data.data.contactInfo.facebook,
          instagram: response.data.data.contactInfo.instagram,
          linkedin: response.data.data.contactInfo.linkedin,
          twitter: response.data.data.contactInfo.twitter,
          otherlinks: response.data.data.contactInfo.otherlinks,
          coordinatorEmail: response.data.data.corpCoordinator.coordinatorEmail,
          coordinatorName: response.data.data.corpCoordinator.coordinatorName,
          coordinatorPhone: response.data.data.corpCoordinator.coordinatorPhone,
          designation: response.data.data.corpCoordinator.designation,
        });
      }
    );

    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
  }, []);

  const CorpUpdateformData = new FormData();

  const onsubmit = (values) => {
    setBlur(true);
    setLoading(true);
    const corpProfile = {
      id: profile.corpProfile.id,
      name: profile.corpProfile.name,
      regCinNumber: profile.corpProfile.regCinNumber,
      gstNumber: profile.corpProfile.gstNumber,
      headOfOrg: profile.corpProfile.headOfOrg,
      categoryPref: values.categoryPref.toString(),
      cityPref: "",
      membershipLevelPref: values.membershipLevelPref.toString(),
      about: values.about,
      createdBy: profile.corpProfile.createdBy,
      createdDate: profile.corpProfile.createdDate,
      updatedBy: profile.corpProfile.updatedBy,
      updatedDate: profile.corpProfile.updatedBy,
    };
    const contactInfo = {
      entityId: profile.contactInfo.entityId,
      addressLine1: profile.contactInfo.addressLine1,
      addressLine2: "",
      city: profile.contactInfo.city,
      state: profile.contactInfo.state,
      country: "India",
      pinCode: profile.contactInfo.pinCode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: values.otherlinks,
      createdBy: profile.contactInfo.createdBy,
      createdDate: profile.contactInfo.createdDate,
      updatedBy: profile.contactInfo.updatedBy,
      updatedDate: profile.contactInfo.updatedDate,
    };
    const corpCoordinator = {
      entityId: profile.corpCoordinator.entityId,
      coordinatorEmail: values.coordinatorEmail,
      coordinatorName: values.coordinatorName,
      coordinatorPhone: values.coordinatorPhone,
      designation: values.designation,
      createdDate: profile.corpCoordinator.createdDate,
      createdBy: profile.corpCoordinator.createdBy,
      updatedDate: profile.corpCoordinator.updatedDate,
      updatedBy: profile.corpCoordinator.updatedBy,
    };
    CorpUpdateformData.append("userCred", JSON.stringify(userCred));
    CorpUpdateformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpUpdateformData.append("corpProfile", JSON.stringify(corpProfile));
    CorpUpdateformData.append("contactInfo", JSON.stringify(contactInfo));
    CorpUpdateformData.append(
      "corpCoordinator",
      JSON.stringify(corpCoordinator)
    );

    dispatch(
      uploadProfile({ url: "corp/updateProfile", formData: CorpUpdateformData })
    )
      .unwrap()
      .then((response) => {
        setBlur(false);
        setLoading(false);
        message.success("Profile Updated Sucessfully");
      })
      .catch((error) => {
        setBlur(false);
        setLoading(false);
        let logStsDesc = error.message.messageDetails[2];
        window.toastr.danger(logStsDesc);
      });
  };
  const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };
  return (
    <>
    {loading && (
      <div className="loader">
        <img src={Loading} alt="Loading..." />
      </div>
    )}
    <div className={blur ? "blur" : ""}>
    <section className="section profile">
      {profile ? (
        <div className="row mt-3">
          <div className="col-xl-3" style={{ paddingLeft: "20px" }}>
            <div className="card card-full-height">
              <div className="profile-card d-flex flex-column align-items-center">
                {/* <img
                  src={Company}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ height: "100px", width: "110px" }}
                /> */}
                <p className="tableheading mt-5">
                  {profile.corpProfile.name.toUpperCase()}
                </p>
              </div>
              <div>
                <div className="smallsubheading p-0">Registration Number:</div>
                <p>{profile.corpProfile.regCinNumber}</p>
                <p className="smallsubheading">CEO Name :</p>
                <p>{profile.corpProfile.headOfOrg}</p>
                <p className="smallsubheading">GST Number :</p>
                <p>{profile.corpProfile.gstNumber}</p>
                <p className="smallsubheading">PAN Number :</p>
                <p>{profile.corpProfile.pan}</p>
                <p className="smallsubheading">Address:</p>
                <p>{profile.contactInfo.addressLine1}</p>
                <p className="smallsubheading">City :</p>
                <p>{profile.contactInfo.city}</p>
                <p className="smallsubheading">State :</p>
                <p>{profile.contactInfo.state}</p>
                <p className="smallsubheading"> PinCode :</p>
                <p>{profile.contactInfo.pinCode}</p>
              </div>
              <div className="social-links mt-2">
                <a
                  href={profile.contactInfo.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <i className="bi bi-twitter" style={{ color: "gray" }}></i>
                </a>
                <a
                  href={profile.contactInfo.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook mx-3"
                >
                  <i className="bi bi-facebook" style={{ color: "gray" }}></i>
                </a>
                <a
                  href={profile.contactInfo.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="instagram me-3"
                >
                  <i className="bi bi-instagram" style={{ color: "gray" }}></i>
                </a>
                <a
                  href={profile.contactInfo.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin"
                >
                  <i className="bi bi-linkedin" style={{ color: "gray" }}></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-xl-9">
            <div className="card card-full-height" >
              <div className="card-body pt-3">
                <Form
                  onFinish={onsubmit}
                  layout="vertical"
                  form={form}
                  scrollToFirstError={true}
                  name="validateOnly"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <Form.Item
                          name="categoryPref"
                          label="Category Preferences :"
                          rules={[
                            {
                              required: true,
                              message: "Category Preferences is Required",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="--NGO Category--"
                            value={selectedItems}
                            onChange={setSelectedItems}
                            style={{
                              width: "100%",
                            }}
                            options={filteredOptions.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="membershipLevelPref"
                          label="NGO Preference"
                          rules={[
                            {
                              required: true,
                              message: "NGO Prefrence is required",
                            },
                          ]}
                        >
                          <Checkbox.Group options={options} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="email" label="Company Email :">
                          <Input size="medium" placeholder="Enter the Email" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="phone"
                          label="Company Phone:"
                          rules={[
                            {
                              required: true,
                              message: "Phone number is required",
                            },
                            {
                              validator: validatePhoneNumber,
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            type="number"
                            placeholder="Enter the Phone number"
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className=""
                          name="facebook"
                          label="Facebook"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<FacebookOutlined />}
                            placeholder="FaceBook ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="instagram"
                          label="Instagram"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<InstagramOutlined />}
                            placeholder="Instagram ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="linkedin"
                          label="Linkedin"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<LinkedinOutlined />}
                            placeholder="LinkedIn ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="twitter"
                          label="Twitter"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<TwitterOutlined />}
                            placeholder="Twitter ID"
                            rules={[
                              {
                                type: "url",
                                message: "please enter correct url",
                              },
                            ]}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <Form.Item
                          className=" "
                          name="coordinatorName"
                          label="Coordinator Name :"
                          rules={[
                            {
                              required: true,
                              message: "coordinatorName is required",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter the coordinatorName"
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className=" "
                          name="designation"
                          label="Designation :"
                          rules={[
                            {
                              required: true,
                              message: "designation is required",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter the designation"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=" "
                          name="coordinatorEmail"
                          label="Coordinator Email :"
                          rules={[
                            {
                              required: true,
                              message: "coordinatorEmail is required",
                            },
                            {
                              type: "email",
                              message: "Enter valid email",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter the coordinatorEmail"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=" "
                          name="coordinatorPhone"
                          label="Coordinator Phone :"
                          rules={[
                            {
                              required: true,
                              message: "coordinatorPhone is required",
                            },
                            {
                              validator: validatePhoneNumber,
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter the coordinatorPhone"
                            type="number"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="website"
                          label="Website"
                          values={"NGO"}
                          requiredMark="optional"
                          rules={[
                            {
                              type: "url",
                              message: "please enter correct url",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Website if you already have."
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=" "
                          name="about"
                          label="About Company:"
                          rules={[
                            {
                              required: true,
                              message: "About is required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={350}
                            style={{ minHeight: 200, resize: "none" }}
                            placeholder="About the Company (In 350 words)"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="">
                        <Button
                          htmlType="submit"
                          className="btnnca btn-gold signupbtn"
                          style={{ width: "90px" }}
                        >
                          Update <i className="fa fa-arrow-right mx-1"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "Loading"
      )}
    </section>
    </div>
    </>
  );
}

export default Corp_Profile;
