import { Link } from "react-router-dom";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
      {/* <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="
          Get in touch. Address : DoAram, NH 47, Avinashi Road, Kaniyur, Coimbatore - 641 659. E-Mail :info@doaram.com. Telephone : +91 95858 24555."
        />
        <link rel="canonical" href="https://doaram.com/contact-us" />
      </Helmet> */}
      {/* Header */}
      <Header />
      {/* <!-- Contact Start --> */}
      <div style={{ backgroundColor: "#eeeeee" }}>
        <div className="container-xxl py-5 webbody pt-5">
          <div className="container pt-5">
            <div className="row g-5 justify-content-center mb-5 pt-5">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="bg-warning text-center h-100 p-5 border8">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-phone-alt fa-2x txt-primary"></i>
                  </div>
                  <h4 className="mb-3">Phone Number</h4>
                  <p className="mb-2 text-center font14 text-white">
                    +91 95858 24555
                  </p>
                  <br />
                  <Link
                    to="tel:+0123456789"
                    className="btn btn-secondary  px-4"
                  >
                    Call Us <i className="fa fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="bg-warning text-center h-100 p-5 border8">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-envelope-open fa-2x txt-primary"></i>
                  </div>
                  <h4 className="mb-3">Email Address</h4>
                  <p className="mb-2 text-center font14 text-white">
                    info@doaram.com
                  </p>
                  <br />
                  <Link
                    to="mailto:info@example.com"
                    className="btn btn-secondary  px-4"
                  >
                    Email Us <i className="fa fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="bg-warning text-center h-100 p-5 border8">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: " 90px" }}
                  >
                    <i className="fa fa-map-marker-alt fa-2x txt-primary"></i>
                  </div>
                  <h4 className="mb-3">Office Address</h4>
                  <p className="mb-2 text-center font14 text-white">
                    DoAram, NH 47, Avinashi Road, Kaniyur, Coimbatore - 641 659
                  </p>
                  <Link
                    to="https://www.google.com/maps/place/11%C2%B000'25.1%22N+76%C2%B058'37.8%22E/@11.006976,76.977157,14z/data=!4m4!3m3!8m2!3d11.0069761!4d76.9771567?hl=en&entry=ttu"
                    className="btn btn-secondary  px-4"
                    target="blank"
                  >
                    Visit Us <i className="fa fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-6 card"
                style={{
                   borderWidth: "1px" }}
              >
                <h1 className="text-center">HAVE ANY QUESTION?</h1>
                <form>
                  <div className="form-floating mt-5">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                    />
                    <label for="name">Name</label>
                  </div>

                  <div className="form-floating mt-4">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                    />
                    <label for="email">Email</label>
                    <div className="form-floating mt-4">
                      <select
                        class="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                      >
                        <option selected>Subject</option>
                        <option value="1">General Enquiry</option>
                        <option value="2">Corporate</option>
                        <option value="3">NGOs</option>
                        <option value="3">Individuals</option>
                        <option value="3">Concept Note</option>
                        <option value="3">Other</option>
                      </select>
                    </div>
                    {/* <div className="mt-4">
                    <label for="subject mt-2">Subject</label>
                    <div
                      className="form-floating pt-3"
                      style={{
                        background: "#fff",
                        padding: "5px",
                        borderradius: "5px",
                      }}
                    >
                      <div className="row">
                        <div className="col">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s1"
                              value="option1"
                            />
                            <label className="form-check-label" for="s1">
                              General Enquiry
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          {" "}
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s2"
                              value="option2"
                            />
                            <label className="form-check-label" for="s2">
                              Corporate
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          {" "}
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s3"
                              value="option2"
                            />
                            <label className="form-check-label" for="s3">
                              NGOs
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {" "}
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s4"
                              value="option1"
                            />
                            <label className="form-check-label" for="s4">
                              Individuals
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s5"
                              value="option2"
                            />
                            <label className="form-check-label" for="s5">
                              Concept Note
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subject"
                              id="s6"
                              value="option2"
                            />
                            <label className="form-check-label" for="s6">
                              Other
                            </label>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div> */}
                    <div className="col-12">
                      <div className="form-floating mt-4">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ resize: "none", height: "150px" }}
                        ></textarea>
                        <label for="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <button className="btn btn-warning py-3 px-5 mt-5">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-6 wow fadeInUp" data-wow-delay="0.5s">
                <div style={{ width: " 100%" }}>
                  <iframe
                    width="100%"
                    height="600"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=11.0069761,76.9771567+(DoAram)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <Link to="https://www.maps.ie/distance-area-calculator.html">
                      area maps
                    </Link>
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}
      <Footer />
    </>
  );
}
export default Contact;
