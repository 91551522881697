import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import UploadService from "../../service/file-upload.service";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Modal, 
  message,
  DatePicker,

} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
const category = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function Admin_ViewCause(props) {
  const navigate = useNavigate();
  let viewdata = null;
  let selectedcause = null;
  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
    selectedcause = state;
  } else {
    viewdata = null;
    selectedcause = null;
  }
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const ApprovecauseformData = new FormData();
  const approveCause = () => {
    Modal.confirm({
      title: "Are you sure,You want to Approve this Cause?",
      okText: "Approve",
      onOk: () => {
        ApprovecauseformData.append("userCred", JSON.stringify(userCred));
        ApprovecauseformData.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );
        let approveCause = {
          id: viewdata.selectedcause.id,
          entityId: viewdata.selectedcause.entityId,
          causeId: viewdata.selectedcause.causeId,
          userId: viewdata.selectedcause.userId,
          entityId: viewdata.selectedcause.entityId,
          version: viewdata.selectedcause.version,
          adminId: userCred.userId,
          status: "Approved",
          comments: "",
          createdTime: viewdata.selectedcause.createdTime,
          modifiedTime: viewdata.selectedcause.modifiedTime,
          communication: viewdata.selectedcause.communication,
        };
        ApprovecauseformData.append(
          "ngoCauseApproval",
          JSON.stringify(approveCause)
        );

        UploadService.create(
          "/admin/ngo/cause/decision/approve",
          ApprovecauseformData
        ).then((response) => {
          Modal.confirm({
            title:"Cause Approved Sucessfully",
            onOk: () => {
              navigate("/Admin_Cause");
            },
          });
        });
      },
    });
  };

  const RejectcauseformData = new FormData();

  const rejectCause = () => {
    Modal.confirm({
      title: "Are you sure,You want to Reject this Cause?",
      okText: "Reject",
      onOk: () => {
        RejectcauseformData.append("userCred", JSON.stringify(userCred));
        RejectcauseformData.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );

        let rejectCause = {
          id: viewdata.selectedcause.id,
          entityId: viewdata.selectedcause.entityId,
          causeId: viewdata.selectedcause.causeId,
          userId: viewdata.selectedcause.userId,
          entityId: viewdata.selectedcause.entityId,
          version: viewdata.selectedcause.version,
          adminId: userCred.userId,
          status: "Rejected",
          comments: "",
          createdTime: viewdata.selectedcause.createdTime,
          modifiedTime: viewdata.selectedcause.modifiedTime,
          communication: viewdata.selectedcause.communication,
        };
        RejectcauseformData.append(
          "ngoCauseApproval",
          JSON.stringify(rejectCause)
        );

        UploadService.create(
          "/admin/ngo/cause/decision/reject",
          RejectcauseformData
        ).then((response) => {
          Modal.confirm({
            title: "Cause Rejected Sucessfully",
            onOk: () => {
              navigate("/Admin_Cause");
            },
          });
        });
      },
    });
  };

  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);

  const filteredOptions = category.filter((o) => !selectedItems.includes(o));

  const callImageAPI = (filename) => {

    const causeimageformData = new FormData();
    causeimageformData.append("userCred", JSON.stringify(userCred));
    causeimageformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    causeimageformData.append("cause", JSON.stringify(viewdata.viewdata));
    causeimageformData.append("imageName", filename);
    UploadService.filedownload("/admin/ngo/cause/image/get", causeimageformData)
      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }

  const [image1name, setimage1name] = useState("");
  const [image2name, setimage2name] = useState("");
  const [image3name, setimage3name] = useState("");
  const [supportiveDoc1name, setsupportiveDoc1name] = useState("");
  const [supportiveDoc2name, setsupportiveDoc2name] = useState("");

  const isimage1Empty = image1name.trim().length === 0;
  const isimage2Empty = image2name.trim().length === 0;
  const isimage3Empty = image3name.trim().length === 0;
  const issupportiveDoc1Empty = supportiveDoc1name.trim().length === 0;
  const issupportiveDoc2Empty = supportiveDoc2name.trim().length === 0;

  useEffect(() => {
    const startDate = dayjs(new Date(viewdata.viewdata.startDate));
    const endDate = dayjs(new Date(viewdata.viewdata.endDate));
    form.setFieldsValue({
      category: viewdata.viewdata.category,
      title: viewdata.viewdata.title,
      description: viewdata.viewdata.description,
      goalType: viewdata.viewdata.goalType,
      goalAmount: viewdata.viewdata.goalAmount,
      city: viewdata.viewdata.city,
      state: viewdata.viewdata.state,
      country: viewdata.viewdata.country,
      startDate: startDate,
      endDate: endDate,
    });
    const imageNames = viewdata.viewdata.images;
    const individualimage = imageNames.split(",");
    setimage1name(individualimage[0]);
    setimage2name(individualimage[1]);
    setimage3name(individualimage[2]);

    const supporitivedocsNames = viewdata.viewdata.supportiveDocs;
    const individualsupporitivedocs = supporitivedocsNames.split(",");
    setsupportiveDoc1name(individualsupporitivedocs[0]);
    setsupportiveDoc2name(individualsupporitivedocs[1]);
  }, []);

  return (
    <>
      
      <div className="col-md-12 row fontfamilynca ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-sm-8 row">
                <div className="col">
                  <NavLink to="/Admin_Cause">
                    <button className="btnnca btn-info btn-sm">
                      <i className="fa fa-arrow-left"></i> Back To Cause
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row mt-0 ">
              <div className="tab-content" id="nav-tabContent">
                <div className="glasscard">
                  <Form
                    autoComplete="off"
                    form={form}
                    onFinish={onsubmit}
                    labelCol={{ span: 4 }}
                    wrapperCol={{
                      span: 17,
                    }}
                  >
                    <legend className="para b themeCol-orange mx-3 mt-2">
                      Cause Details:{" "}
                    </legend>
                    <div className="mt-4">
                      <Form.Item
                        className="para b themeCol-orange"
                        name="category"
                        label="Category"
                      >
                        <Select
                          mode="multiple"
                          placeholder="--NGO Category--"
                          value={selectedItems}
                          onChange={setSelectedItems}
                          style={{
                            width: "100%",
                            pointerEvents: "none",
                          }}
                          options={filteredOptions.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="title"
                        label="Cause Title"
                      >
                        <Input
                          size="medium"
                          placeholder="Enter Cause Title"
                          readOnly={true}
                        />
                      </Form.Item>
                    </div>
                    <div className="upload">
                      <Form.Item
                        className=""
                        name="campaignimage"
                        label="Campaign Image"
                      >
                        {isimage1Empty ? (
                          <Upload maxCount={1}>
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        ) : (
                          <Button
                            type="button"
                            className="btnnca btn-gold btn-sm"
                            onClick={() => callImageAPI(image1name)}
                          >
                            Preview
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                    <legend className="para b themeCol-orange mx-3 mt-2">
                      Goal Details:{" "}
                    </legend>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="goalType"
                        label="Goal Type"
                      >
                        <Select
                          placeholder="-Select Goal-"
                          style={{ pointerEvents: "none" }}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item name="goalAmount" label="Goal Amount(₹)">
                        <Input
                          readOnly={true}
                          type="number"
                          placeholder="Amount In Rupees"
                          style={{ width: "300px" }}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className=""
                        name="startDate"
                        label="Start Date"
                      >
                        <DatePicker style={{ pointerEvents: "none" }} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item className="" name="endDate" label="End Date">
                        <DatePicker style={{ pointerEvents: "none" }} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="description"
                        label="Cause Description"
                      >
                        <Input.TextArea
                          readOnly={true}
                          showCount
                          maxLength={250}
                          style={{ height: 200, resize: "none" }}
                          placeholder="Enter Cause  Description(250 words)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="city"
                        label="City"
                      >
                        <Select
                          placeholder="-Select City-"
                          style={{ pointerEvents: "none" }}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="state"
                        label="State"
                      >
                        <Select
                          placeholder="-Select State-"
                          style={{ pointerEvents: "none" }}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="country"
                        label="Country"
                      >
                        <Select
                          placeholder="-Select Country-"
                          style={{ pointerEvents: "none" }}
                        ></Select>
                      </Form.Item>
                    </div>
                    <legend className="para b themeCol-orange mx-3">
                      Gallery:{" "}
                    </legend>
                    <div className="upload">
                      <Form.Item
                        className=""
                        name="galleryimage1"
                        label="Image"
                      >
                        {isimage2Empty ? (
                          <Upload maxCount={1}>
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        ) : (
                          <Button
                            type="button"
                            className="btnnca btn-gold btn-sm"
                            onClick={() => callImageAPI(image2name)}
                          >
                            Preview
                          </Button>
                        )}
                      </Form.Item>
                    </div>

                    <div className="upload">
                      <Form.Item
                        className=""
                        name="galleryimage2"
                        label="Image"
                      >
                        {isimage3Empty ? (
                          <Upload maxCount={1}>
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        ) : (
                          <Button
                            type="button"
                            className="btnnca btn-gold btn-sm"
                            onClick={() => callImageAPI(image3name)}
                          >
                            Preview
                          </Button>
                        )}
                      </Form.Item>
                    </div>

                    <legend className="para b themeCol-orange mx-3">
                      Supportive Document:{" "}
                    </legend>
                    <div className="upload">
                      <Form.Item
                        className=""
                        name="Supportivedocument1"
                        label="Document"
                      >
                        {issupportiveDoc1Empty ? (
                          <Upload maxCount={1}>
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        ) : (
                          <Button
                            type="button"
                            className="btnnca btn-gold btn-sm"
                            onClick={() => callImageAPI(supportiveDoc1name)}
                          >
                            Preview
                          </Button>
                        )}
                      </Form.Item>
                    </div>

                    <div className="upload">
                      <Form.Item
                        className=""
                        name="Supportivedocument2"
                        label="Document"
                      >
                        {issupportiveDoc2Empty ? (
                          <Upload maxCount={1}>
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        ) : (
                          <Button
                            type="button"
                            className="btnnca btn-gold btn-sm"
                            onClick={() => callImageAPI(supportiveDoc2name)}
                          >
                            Preview
                          </Button>
                        )}
                      </Form.Item>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button
                        type="button"
                        className="btnnca btn-gold signupbtn"
                        onClick={approveCause}
                      >
                        Approve Cause
                      </Button>

                      <Button
                        type="button"
                        className="btnnca btn-danger signupbtn ms-3"
                        onClick={rejectCause}
                      >
                        Reject Cause
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            {/* my work */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Admin_ViewCause;
