import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Noaccount.png";

import $ from "jquery";
import { Table, Tag, Tabs, Button, Pagination } from "antd";

function Admin_PaymentStatus() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(7);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  let tablelength = dataSource.length;
  const [reRendertable, setRerendertable] = useState();

  const [causepage, setCausepage] = useState(1);
  const [causesize, setCausesize] = useState(7);
  const [causetotalElements, setCauseTotalElements] = useState();
  const [causedataSource, setCauseDataSource] = useState([]);

  const pagination = {
    position: ["topRight"],
    pageSize: size,
    total: totalElements,
    showSizeChanger: false,
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const AdminPaymentstatusformData = new FormData();

  AdminPaymentstatusformData.append("userCred", JSON.stringify(userCred));
  AdminPaymentstatusformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminPaymentstatusformData.append("page", JSON.stringify(page - 1));
  AdminPaymentstatusformData.append("size", JSON.stringify(size));

  const CausePaymentstatusformData = new FormData();

  CausePaymentstatusformData.append("userCred", JSON.stringify(userCred));
  CausePaymentstatusformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  CausePaymentstatusformData.append("page", JSON.stringify(page - 1));
  CausePaymentstatusformData.append("size", JSON.stringify(size));
  CausePaymentstatusformData.append("status", "pending");

  useEffect(() => {
   

    UploadService.create(
      "admin/ngo/membership/order/payment/status/pending",
      AdminPaymentstatusformData
    ).then((response) => {
      setTotalElements(response.data.data.ngoMembershipUpgradeOrder.totalE);
      if (response.data !== null) {
        setDataSource(response.data.data.ngoMembershipUpgradeOrder.content);
      }
    });

    UploadService.create(
      "admin/donations/get",
      CausePaymentstatusformData
    ).then((response) => {
      setCauseDataSource(response.data.data.donationUserDetailsPage.content);
      setCauseTotalElements(
        response.data.data.donationUserDetailsPage.totalElements
      );
    });
  }, [reRendertable, page]);

  const generateColor = (userId) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    // Use a simple hash of userId to determine the index of the color
    const index = userId.charCodeAt(0) % colors.length;
    return colors[index];
  };
  const columns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.userId.charAt(0).toUpperCase();
        const color = generateColor(record.userId);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "User ID",
      dataIndex: "userId",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
    },
    {
      title: "Amount",
      dataIndex: "membershipFee",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "MembershipLevel",
      dataIndex: "membershipLevel",
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      render: (text) => {
        if (text === "Pending") {
          return <Tag color="#F89880">Pending</Tag>;
        }
      },
    },

    {
      title: "Action",
      key: "operation",
      render: (record) => (
        <div>
          <Button
            type="button"
            className="btnnca btn-pink btn-sm"
            onClick={() => checkPaymentstatus(record)}
          >
            Check Status
          </Button>
        </div>
      ),
    },
  ];
  const causecolumns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.email.charAt(0).toUpperCase();
        const color = generateColor(record.email);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "email",
    },

    {
      title: "OrderId",
      dataIndex: "orderId",
    },
    {
      title: "Amount",
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        if (text === "Pending") {
          return <Tag color="#F89880">Pending</Tag>;
        }
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (record) => (
        <div>
          <Button
            type="button"
            className="btnnca btn-pink btn-sm"
            onClick={() => checkCausePaymentstatus(record)}
          >
            Check Status
          </Button>
        </div>
      ),
    },
  ];
  const checkPaymentstatus = (record) => {
    const paymentstatusformData = new FormData();
    paymentstatusformData.append("userCred", JSON.stringify(userCred));
    paymentstatusformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    paymentstatusformData.append("orderId", record.orderId);

    UploadService.paymentstatus(
      "payments/statusApi",
      paymentstatusformData
    ).then((response) => {
    });
  };
  const checkCausePaymentstatus = (record) => {
    const CausepaymentstatusformData = new FormData();

    CausepaymentstatusformData.append("userCred", JSON.stringify(userCred));
    CausepaymentstatusformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CausepaymentstatusformData.append("orderId", record.orderId);

    UploadService.create(
      "payments/cause/donation/statusApi",
      CausepaymentstatusformData
    ).then((response) => {
    });
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        centered
        bordered={false}
        type="card"
        large
        items={[
          {
            label: "Account PaymentStatus",
            key: "1",
            children: (
              <>
                <div className="col-md-12 row fontfamilynca">
                  <div className="col-sm-6">
                    <h4 className="pt-2 tableheading">
                      Account Payment Pending:
                    </h4>
                  </div>
                  <section className="content ">
                    {tablelength > 0 && (
                      <div className="row">
                        <div className="mt-3">
                          <Table
                            columns={columns}
                            dataSource={dataSource}
                            className="custom-table"
                            style={{ padding: "5px" }}
                            onChange={handlePageChange}
                            bordered
                            scroll={{
                              x: 1000,
                            }}
                            pagination={false}
                          />
                          <Pagination
                            Current={page}
                            total={totalElements}
                            pageSize={size}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                </div>
              </>
            ),
          },
          {
            label: "Donation PaymentStatus",
            key: "2",
            children: (
              <>
                <div className="col-md-12 row fontfamilynca  pt-2 px-4">
                  <div className="col-sm-6">
                    <h4 className=" tableheading">
                      Cause Donation Payment Pending:
                    </h4>
                  </div>
                  <section className="content ">
                    {tablelength > 0 && (
                      <div className="row">
                        <div className="mt-3">
                          <Table
                            columns={causecolumns}
                            dataSource={causedataSource}
                            style={{ padding: "5px" }}
                            onChange={handlePageChange}
                            bordered
                            scroll={{
                              x: 1000,
                            }}
                            pagination={false}
                            className="custom-table"
                          />
                          <Pagination
                            Current={causepage}
                            total={causetotalElements}
                            pageSize={causesize}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                </div>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default Admin_PaymentStatus;
