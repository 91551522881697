// feature/uploadSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UploadService from '../doaram/service/file-upload.service';

// Async thunk for uploading profile
export const uploadProfile = createAsyncThunk(
  'upload/formdata',
  async ({ url, formData }, { rejectWithValue }) => {
    try {
      const response = await UploadService.create(url, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice definition
const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {
    // You can add other reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(uploadProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      });
  },
});

export default uploadSlice.reducer;
