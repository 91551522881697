import React from "react";
import { Link } from "react-router-dom";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import blog1img from "../doaram/assets/blog/blog1.jpg";
import blog2img from "../doaram/assets/blog/blog2.jpg";
import blog3img from "../doaram/assets/blog/blog3.jpg";
import blog4img from "../doaram/assets/blog/blog4.jpg";
function BlogDetails2() {
  return (
    <>
      <Header />
      {/* <!-- Facts Start --> */}
      <div className="container mt-5 webbody pt-5">
        <div
          className="text-center mx-auto wow fadeInUp pt-5  mb-5"
          data-wow-delay="0.1s"
        >
          {" "}
          <h1 className="mb-5 pt-5">
            {" "}
            Moral science, a bygone subject in schools should be made mandatory
          </h1>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="mb-5">
              <div className="post-slide ">
                <div className="post-img">
                  <img src={blog2img} alt="blog2img" />
                </div>
                <div className="post-content row">
                  <div className="col-md-9">
                    <h3 className="post-title">
                      <Link to="">
                        {" "}
                        Moral science, a bygone subject in schools should be
                        made mandatory
                      </Link>
                    </h3>
                    <p>
                      &ldquo;To educate a man in mind and not morals is to
                      educate a menace to the society- Theodore Roosevelt&rdquo;
                    </p>
                    <p>
                      Moral education is a value added learning which helps the
                      children to develop a good moral and civic sense to serve
                      the society well. A decade ago, there existed a separate
                      subject called moral science in almost all the schools be
                      it private or a public sector. Sadly, this substantial
                      subject was removed from the curriculum as our education
                      system confined more to the subject knowledge forgetting
                      the importance of teaching life skills for the children.
                    </p>
                    <p>
                      Educational institutions contribute a major role in
                      incorporating values to the future generations. Education
                      is not only about acquiring theoretical knowledge; it is
                      about the way it shapes the mind and soul of a child.
                      Moral science teaches ethics and values. It influences
                      critical thinking and helps a student to differentiate the
                      right from wrong. When a person chooses to be right it
                      exhibits his moral value, and if his morality reflects the
                      willingness to do so, then it is called ethics.
                      Etiquettes, soft skill and ethics all become a part of
                      curriculum right from a very beginning a child starts
                      school.
                    </p>
                    <p>
                      Childhood is a very gullible age, whatever is being taught
                      at this tender age will leave a deep impression throughout
                      his lifetime. Moral education makes a child self reliant,
                      confident and responsible. It helps the child to be
                      mentally, emotionally and physically strong to face any
                      kind of situations in their life. When a child learns to
                      practice early to differentiate the right and the wrong
                      there wouldn&rsquo;t be any blue whale suicides, child
                      abuse, Juvenile Delinquency, communalism or any other
                      social problems.
                    </p>
                    <p>
                      It is the responsibility of the modern day schools to
                      introduce Moral science as a mandatory subject from
                      primary to the secondary as per the directions of the
                      Supreme Court. Donating a 45 minutes Moral Science period
                      for a week will make a huge difference!
                    </p>
                    <p>
                      {" "}
                      Share : &nbsp; &nbsp; &nbsp;
                      <div className="d-flex pt-2 ms-5">
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </p>

                    <div className="row mt-3">
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          style={{ resize: "none" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                      <button className="btn btn-secondary btn-sm  col-md-3">
                        Leave comment
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-3 mb-5"
                    style={{ borderleft: "1px solid #ddd" }}
                  >
                    <div className="row mb-3">
                      <div className="categories">
                        <h6>
                          <u>Latest Blogs</u>
                        </h6>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src={blog1img}
                                  alt="..."
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                Street play is one of...
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog3img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  A self-help group...
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog4img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  Foot binding was...
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts End --> */}

      <Part />
      <Footer />
    </>
  );
}

export default BlogDetails2;
