import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, Select, Checkbox, message, Modal } from "antd";
import { HomeFilled, HomeOutlined } from "@ant-design/icons";
import UploadService from "../../service/file-upload.service";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SHA256 from "crypto-js/sha256";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
function ResetPassword() {
  const navigate = useNavigate();
  let token = "";
  let eToken = "";
  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };
  const hashPassword = (password) => {
    const hashedPassword = SHA256(password).toString();
    return hashedPassword;
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    token = url.searchParams.get("token");
    eToken = url.searchParams.get("eToken");
  });
  const onsubmit = (values) => {
    const userCred = {
      userId: values.email,
      userName: "",
      password: hashPassword(values.password),
      termsConditions: "",
      systemUpdateCommunication: "",
      userType: "",
      userRole: "",
      associatedEntity: "",
      status: "Inactive",
      comments: "",
    };

    const userAuthTracker = {
      id: 1,
      userId: "",
      email: "",
      uniqueToken: token,
      emailToken: eToken,
      authType: "resetPassword",
      authMode: "emailLink",
      timeNow: null,
      expiryTime: "",
      modifiedTime: null,
      status: "",
      count: 1,
    };
    const formDataResetPassword = new FormData();
    formDataResetPassword.append("userCred", JSON.stringify(userCred));
    formDataResetPassword.append(
      "userAuthTracker",
      JSON.stringify(userAuthTracker)
    );

    UploadService.create("/common/resetPassword", formDataResetPassword).then(
      (response) => {
        Modal.info({
          content: (
            <div>
              <p>Password Reset Sucessfull!</p>
            </div>
          ),
          onOk() {
            navigate("/");
          },
        });
      }
    );
  };

  return (
    <div
      className="bg_dtheme fontfamilynca  col-md-12 row "
      style={{ height: "100vh" }}
    >
      <div className="row ">
        <div className="col-md-6  signupimg" style={boyImageCom}></div>

        <div className="col-md-6  mb-5 signup">

            <div className="row text-center d-flex justify-content-center "> 
            <Form
              onFinishFailed={showerror}
              layout="vertical"
              autoComplete="off"
              // onFinish={(values) => {
              // }}
              onFinish={onsubmit}
              className="row g-3"
            >
              <div className="col-12 text-center mb-3">
                <div className="col-12 text-center mt-5">
                  <img
                    src={require("./../../assets/da_img/logo1.png")}
                    width={100}
                    height={100}
                    alt="DoAram_Logo"
                    className="rounded mx-auto d-block"
                  />
                </div>
                <small className="text-white font20 text-sm ">
                  Let's Do Aram
                </small>
                <br />
              </div>
              <div className="input-group mt-5">
                <span className="d-input-group-text">
                  <i
                    className="fa fa-envelope  themeCol-pink"
                    aria-hidden="true"
                  ></i>
                </span>
                <div className="form-floating  border-0">
                  <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                      { required: true, message: "email is required" },
                      {
                        type: "email",
                        message: "Enter valid email address",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="doaram@gmail.com" bordered={false} />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="input-group mt-4">
                <span className="d-input-group-text">
                  <i className="fa fa-key themeCol-pink" aria-hidden="true"></i>
                </span>
                <div className="form-floating border-0">
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{9,15}$/,
                        message:
                          "pattern:9-15 length,uppercase,lowecase,special character,number",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="*************"
                      bordered={false}
                    />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="input-group  mt-4">
                <span className="d-input-group-text">
                  <i className="fa fa-key  themeCol-pink" aria-hidden="true"></i>
                </span>
                <div className="form-floating border-0">
                  <Form.Item
                    name="confirmpassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Password Does not match");
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="*************"
                      bordered={false}
                    />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="col-12  mt-4">
                <p className="font16 text-danger b" id="signupmessage"></p>
                {/* <Link to="/SignUpSelector"> */}
                <div className="d-flex  justify-content-center">
                  <Button
                    htmlType="submit"
                    className="btnnca btn-gold col-md-5 signupbtn"
                  >
                    Reset password
                  </Button>
                </div>
                {/* </Link> */}
              </div>
            </Form>
        </div> 
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
