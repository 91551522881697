import React from "react";
import Construction from "../../assets/icons/construction.png";


function Notification(props) {
  return (
      <div className="col-md-12 row fontfamilynca ">
        <div className="content-header">
          <div className="container-fluid">
            <div
              className="card d-flex align-items-center"
              style={{ height: "80vh" }}
            >
              <img src={Construction} alt="Construction" className="alignimagecenter" />
            </div>
          </div>
        </div>
      </div>
  );
}

export default Notification;
