import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    corpUserCred: {},
    corpProfile: {},
    coordinator: {},
    contactInfo: {},
    userActivityAudit: {},
    userRoleAuthMapping: {},
    userCred: {},
  },
  reducers: {
    setData: (state, action) => {
      state.corpUserCred = action.payload.corpUserCred;
      state.corpProfile = action.payload.corpProfile;
      state.coordinator = action.payload.coordinator;
      state.contactInfo = action.payload.contactInfo;
      state.userActivityAudit = action.payload.userActivityAudit;
      state.userRoleAuthMapping = action.payload.userRoleAuthMapping;
      state.userCred = action.payload.userCred;
    },
  },
});

export const { setData } = dataSlice.actions;

export default dataSlice.reducer;
