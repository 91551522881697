import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Table, Button, Tooltip, Pagination, Tag } from "antd";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";
import CustomModal from "./ViewNgoModel";
import CauseModel from "./ViewCauseModel";
import DonatenowModel from "./Donatenow";
import ShowIntrest from "./IntrestDescription";
import UploadService from "../../service/file-upload.service";

function CorpNGOCause() {
  const [ngoid, setNgoid] = useState();
  const [ngopagemodel, setNgopagemodel] = useState(false);
  const [causepagemodel, setCausepagemodel] = useState(false);
  const [donatenowmodel, setDonatenowmodel] = useState(false);
  const [showInterestmodel, setShowInterestmodel] = useState(false);
  const [showInterestbtn, setShowInterestbtn] = useState();
  const [entityId, setEntityId] = useState();
  const [donatengoId, setdonatengoId] = useState();
  const [donatecauseId, setdonatecauseId] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [causeId, setCauseId] = useState();
  const [reRendertable, setRerendertable] = useState();

  let tablelength = dataSource.length;

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  let viewdata = null;
  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
  } else {
    viewdata = null;
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const NgoCauseformData = new FormData();
  useEffect(() => {
    NgoCauseformData.append("userCred", JSON.stringify(userCred));
    NgoCauseformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    NgoCauseformData.append("ngoId", viewdata.viewdata.ngoProfile.id);
    NgoCauseformData.append("page", JSON.stringify(page - 1));
    NgoCauseformData.append("size", JSON.stringify(size));

    UploadService.create("corp/causesWithInterest/get", NgoCauseformData).then(
      (response) => {
        if (response.data !== null) {
          setDataSource(response.data.data.causeInterestList);
        }
        setTotalElements(response.data.data.totalElements);
      }
    );
  }, [page, reRendertable]);

  const showCausgePage = (record) => {
    setCausepagemodel(true);
    setCauseId(record.cause.causeId);
    if (record.interest === null) {
      setShowInterestbtn(true);
    } else {
      setShowInterestbtn(false);
    }
  };

  const viewProfile = (record) => {
    setNgopagemodel(true);
    setNgoid(record.cause.entityId);
  };
  const truncateWithTooltip = (text, maxLength) => {
    if (text.length > maxLength) {
      return (
        <Tooltip title={text}>
          <span>{text.substr(0, maxLength)}...</span>
        </Tooltip>
      );
    }
    return text;
  };
  const columns = [
    {
      title: "Cause Name",
      dataIndex: ["cause", "title"],
      render: (text, record) => (
        <a onClick={() => showCausgePage(record)} style={{ cursor: "pointer" }}>
          {text}
        </a>
      ),
    },
    {
      title: " Cause Id",
      dataIndex: ["cause", "causeId"],
     
    },

    {
      title: "Category",
      dataIndex: ["cause", "category"],
     
      render: (text) => truncateWithTooltip(text, 30),
    },
    // {
    //   title: "Location",
    //   dataIndex: ["cause", "city"],
    //   render: (text, record) => {
    //     const city = record.cause.city;
    //     const state = record.cause.state;
    //     return `${city}, ${state}`;
    //   },
    //  
    // },
    {
      title: "Duration",
      dataIndex:["cause", "endDate"],
     
     
    },
    {
      title: "Amount",
      dataIndex: ["cause", "goalAmount"],
      render: (text) => <span>&#x20B9; {text}</span>,
     
    },
    {
      title: "Action",
      key: "operation",
     
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="View NGO Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para">
                <i className="fas fa fa-globe text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="View Cause">
            <a onClick={() => showCausgePage(record)}>
              <span className="btn btn-warning btn-sm para mx-1">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="Donate Now">
            <button
              className="btn btn-violet btn-sm mx-1 homebtnheight"
              onClick={() => donatenow(record)}
            >
              <span className="">Donate Now</span>
            </button>
          </Tooltip>
          {record.interest === null ? (
            <Tooltip title="Show interest">
              <Button
                className="btnnca btn-gold btn-sm mx-2 homebtnheight"
                onClick={() => showInterest(record)}
              >
                Show Interest
              </Button>
            </Tooltip>
          ) : (
            <Tag color="green" className="ms-2 p-1">
              Shown Interest
            </Tag>
          )}
        </div>
      ),
    },
  ];

  const showInterest = (record) => {
    setShowInterestmodel(true);
    setCauseId(record.cause.causeId);
    setEntityId(record.cause.entityId);
  };
  const donatenow = (record) => {
    setDonatenowmodel(true);
    setdonatengoId(record.cause.entityId);
    setdonatecauseId(record.cause.causeId);
  };

  return (
    <>
      <>
        {/* <div className="col m-3">
          <NavLink to="/Corp_NGO">
            <button className="btn btn-violet btn-sm ">
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </NavLink>
        </div> */}
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Approved Cause:</h6>
          </div>
          <section className="content">
            {tablelength > 0 && (
              <div className="row">
                <div className="mt-2">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    style={{
                      padding: "5px",
                    }}
                    onChange={handlePageChange}
                    bordered
                    pagination={false}
                    className="custom-table"
                    scroll={{
                      x: 1000,
                    }}
                  />
                  <Pagination
                    Current={page}
                    total={totalElements}
                    pageSize={size}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            )}
            {tablelength == 0 && (
              <div
                className="d-flex justify-content-center"
                style={{ height: "80vh" }}
              >
                <img src={Nodata} alt="Nodata" className="alignimagecenter" />
              </div>
            )}
          </section>
        </div>
      </>
      <CustomModal
        visible={ngopagemodel}
        onClose={() => setNgopagemodel(false)}
        data={ngoid}
      />

      <CauseModel
        visible={causepagemodel}
        onClose={() => setCausepagemodel(false)}
        data={causeId}
        interest={showInterestbtn}
      />
      <DonatenowModel
        visible={donatenowmodel}
        onClose={() => setDonatenowmodel(false)}
        donatecauseId={donatecauseId}
        donatengoId={donatengoId}
      />
      <ShowIntrest
        visible={showInterestmodel}
        onClose={() => setShowInterestmodel(false)}
        causeId={causeId}
        entityId={entityId}
      />
    </>
  );
}

export default CorpNGOCause;
