import { Button } from "antd";
import { Link } from "react-router-dom";
import Rocket from "../../assets/da_img/upgrade.png";
import $ from "jquery";
import { useState } from "react";

function NGO_Dashboard() {
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  let userRole = userCred.userRole.split("_")[1];
  return (
    <>
       
      <div className="col-md-12 row fontfamilynca">
        <div className="container">
          <div className="glasscard row m-3">
            <div className="col-md-6">
              <h4 className="mt-5">Account Activated</h4>
              <p className="mt-4 fw-web text-dark">
                Congratulations!You have Successfully activated your account
                with DoAram Under {userRole} Rating
              </p>
              {userRole !== "PLATINUM" ? (
                <>
                  <p className="mt-4">
                    You have option to upgrade and raise more funds,connect with
                    corporate,increase operation efficiency and more..
                  </p>

                  <div className="mt-4">
                    <Link to="/ngoUpgrade">
                      <Button
                        type="primary"
                        icon={<i className="fa fa-rocket"></i>}
                        size="medium"
                        className="btnnca btn-gold btn-sm mx-2 homebtnheight"
                      >
                        Upgrade
                      </Button>
                    </Link>
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-md-6  signupimg">
              <img src={Rocket} alt="upgrade" className="homeupgradeimg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NGO_Dashboard;
