import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, message, Modal } from "antd";
import UploadService from "../../service/file-upload.service";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

function Admin_ResetPassword() {
  const navigate = useNavigate();

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };

  const onsubmit = (values) => {
    let ResetuserCred = {
      userId: userCred.userId,
      userName: userCred.userName,
      password: values.password,
      termsConditions: "",
      systemUpdateCommunication: "",
      userType: "",
      userRole: "",
      associatedEntity: "",
      status: "Active",
      createdDate: null,
      comments: "",
      passwordSetTime: null,
      emailVerified: null,
      numLogin: userCred.numLogin,
      lastLoginTime: null,
      createdBy: null,
      updatedDate: null,
      updatedBy: null,
    };

    const AdminResetformData = new FormData();
    AdminResetformData.append("userCred", JSON.stringify(ResetuserCred));
    AdminResetformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );

    UploadService.create(
      "/admin/resetPasswordAfterLogin",
      AdminResetformData
    ).then((response) => {
      Modal.info({
        content: (
          <div>
            <p>Password Reset Sucessfull!</p>
          </div>
        ),
        onOk() {
          navigate("/Admin_Profile");
        },
      });
    });
  };

  return (
    <div
      className="bg_dtheme fontfamilynca  col-md-12 row "
      style={{ backgroundColor: "#2d1621", height: "100vh" }}
    >
     
      <div className="row ">
        <div className="col-md-6  signupimg" style={boyImageCom}></div>

        <div className="col-md-6  mb-5 signup">
          <div
            className="col-md-9 "
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Form
              onFinishFailed={showerror}
              layout="vertical"
              autoComplete="off"
              onFinish={onsubmit}
              className="row  g-3"
            >
              <div className="col-12 text-center">
                <div className="col-12 text-center mt-5">
                  <img
                    src={require("./../../assets/da_img/logo1.png")}
                    width={100}
                    height={100}
                    alt="DoAram_Logo"
                    className="rounded mx-auto d-block"
                  />
                </div>
                <small className="text-white font20 text-sm ">
                  Let's Do Aram
                </small>
                <br />
              </div>
              <div className="input-group mt-5">
                <span className="d-input-group-text">
                  <i
                    className="fa fa-envelope  themeCol-pink"
                    aria-hidden="true"
                  ></i>
                </span>
                <div className="form-floating  border-0">
                  <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                      { required: true, message: "email is required" },
                      {
                        type: "email",
                        message: "Enter valid email address",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="doaram@gmail.com" bordered={false} />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="input-group mt-4">
                <span className="d-input-group-text">
                  <i className="fa fa-key themeCol-pink" aria-hidden="true"></i>
                </span>
                <div className="form-floating border-0">
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: "password is required" },
                      { min: 9, message: "Minimum length 9" },
                      { max: 15, message: "Maximum lenth is 15" },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="*************"
                      bordered={false}
                    />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="input-group  mt-4">
                <span className="d-input-group-text">
                  <i className="fa fa-key  themeCol-pink" aria-hidden="true"></i>
                </span>
                <div className="form-floating border-0">
                  <Form.Item
                    name="confirmpassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Password Does not match");
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="*************"
                      bordered={false}
                    />
                  </Form.Item>
                </div>
                <span className="d-input-group-text border-0"></span>
              </div>

              <div className="col-12  mt-4">
                <p className="font16 text-danger b" id="signupmessage"></p>
                {/* <Link to="/SignUpSelector"> */}
                <div className="d-flex  justify-content-center">
                  <Button
                    htmlType="submit"
                    className="bg-yellowgra loginbtn"
                    style={{ height: "45px" }}
                  >
                    Reset password
                  </Button>
                </div>
                {/* </Link> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_ResetPassword;
