import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import header1 from "../../../imges/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

function Header() {
  return (
    <div className="header fixed-top">
      {/* <body> should not be used here */}
      <div>
        {/* <!-- Topbar Start --> */}
        <div className="container-fluid bg-goldweb px-0">
          <div className="row g-0 d-none d-lg-flex">
            <div className="col-lg-6 ps-5 text-start">
              <div className="h-100 d-inline-flex align-items-center text-white">
                <span className="font15">Follow Us:&nbsp;</span>
                <NavLink
                  to="https://x.com/DoAramofficial"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </NavLink>
                <NavLink
                  to="https://www.facebook.com/doaramofficial/"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
                <NavLink
                  to="https://www.youtube.com/@doaramdogood"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <i className="fab fa-youtube"></i>
                </NavLink>
                <NavLink
                  to="https://www.instagram.com/doaramofficial/"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <i className="fab fa-instagram"></i>
                </NavLink>
                <NavLink
                  to="https://www.linkedin.com/company/doaramoffical/"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
                <NavLink
                  to="https://www.tumblr.com/blog/doaramdogood"
                  className="btn btn-square btn-icon rounded-circle me-2 ft-12 px-0"
                >
                  <i className="fab fa-tumblr"></i>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
                <span className="fs-5 fw-bold me-2">
                  <i className="fa fa-envelope me-2"></i>Mail:
                </span>
                <span className="fs-5 fw-bold">info@doaram.com</span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Topbar End -->

        <!-- Navbar Start --> */}
        <nav className="navbar navbar-expand-lg bgc-white navbar-light w-100 mb-5 py-0 pe-5">
          <NavLink to="/" className="navbar-brand ps-5 m-2">
            <img src={header1} alt="header1" className="logo" />
          </NavLink>
          <button
            type="button"
            className="navbar-toggler me-0 bg-white"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarCollapse"
          >
            <div className="navbar-nav">
              <NavLink
                exact
                to="/"
                className="nav-item nav-link"
                activeClassName="active"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="nav-item nav-link"
                activeClassName="active"
              >
                About
              </NavLink>
              <NavLink
                to="/corporate"
                className="nav-item nav-link"
                activeClassName="active"
              >
                Corporates
              </NavLink>
              <div className="nav-item dropdown">
                <NavLink
                  to="/insight"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  activeClassName="active"
                >
                  NGO
                </NavLink>
                <div className="dropdown-menu bg-light m-0">
                  <NavLink
                    to="/insight"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Power of Insights
                  </NavLink>
                  <NavLink
                    to="/listedngo"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Listed NGO's
                  </NavLink>
                </div>
              </div>
              <NavLink
                to="/cause"
                className="nav-item nav-link"
                activeClassName="active"
              >
                Cause
              </NavLink>
              <NavLink
                to="/individuals"
                className="nav-item nav-link"
                activeClassName="active"
              >
                Individuals
              </NavLink>
              <div className="nav-item dropdown">
                <NavLink
                  to="/blog"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  activeClassName="active"
                >
                  Resources
                </NavLink>
                <div className="dropdown-menu bg-light m-0">
                  <NavLink
                    to="/blog"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Blog
                  </NavLink>
                  <NavLink
                    to="/faq"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    FAQ's
                  </NavLink>
                </div>
              </div>
              <NavLink
                to="/contact-us"
                className="nav-item nav-link"
                activeClassName="active"
              >
                Contact
              </NavLink>
            </div>
            <div className="px-4 d-flex">
              <div className="btn-group btn-group-sm">
                <button
                  className="btn dropdown-toggle btn-outline-warning btn-sm"
                  type="button"
                  id="defaultDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                >
                  Login
                </button>
                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                  <li>
                    <NavLink to="/ngosignin" className="dropdown-item ngoPanel">
                      NGO
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/corpsignin"
                      className="dropdown-item corpPanel"
                    >
                      Corporate
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/individualsignin"
                      className="dropdown-item corpPanel"
                    >
                      Individual
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="btn-group btn-group-sm">
                <button
                  className="btn dropdown-toggle btn-warning ms-2 btn-sm"
                  type="button"
                  id="defaultDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                >
                  Sign-up
                </button>
                <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                  <li>
                    <NavLink to="/signup" className="dropdown-item ngoPanel">
                      NGO
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/corpsignup"
                      className="dropdown-item corpPanel"
                    >
                      Corporate
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/individualsignup"
                      className="dropdown-item corpPanel"
                    >
                      Individual
                    </NavLink>
                  </li>
                </ul>
              </div>
              <button type="button" className="btn btn-violet ms-2 btn-sm">
                <NavLink to="/cause" style={{color:"white"}}> Donate Now</NavLink>
              </button>
            </div>
          </div>
        </nav>
        {/* <!-- Navbar End --> */}
      </div>
    </div>
  );
}

export default Header;
