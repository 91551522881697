import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";
import { Table, Tag, Tabs, Pagination } from "antd";
import Nodata from "../../assets/icons/Nodonation.png";

function Admin_Donation() {
  const [individualpage, setIndividualPage] = useState(1);
  const [individualsize] = useState(6);
  const [individualtotalElements, setIndividualTotalElements] = useState(0);
  const [individualdataSource, setIndividualDataSource] = useState([]);

  const [corppage, setCorpPage] = useState(1);
  const [corpsize] = useState(6);
  const [corptotalElements, setCorpTotalElements] = useState(0);
  const [corpdataSource, setCorpDataSource] = useState([]);

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  let tablelength = individualdataSource.length;

  const IndividualhandlePageChange = (individualpage) => {
    setIndividualPage(individualpage);
  };
  const CorphandlePageChange = (corppage) => {
    setCorpPage(corppage);
  };

  const generateColor = (email) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    const index = email.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const Donationcolumns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 10,
      render: (text, record) => {
        const firstName = record.email.charAt(0).toUpperCase();
        const color = generateColor(record.email);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "email",
      width: 50,
    },
    {
      title: "Phone",
      width: 20,
      dataIndex: "phone",
    },
    {
      title: "causeId",
      dataIndex: "causeId",
      width: 20,
    },
    // {
    //   title: "orderId",
    //   dataIndex: "orderId",
    //   width: 20,
    // },

    {
      title: "Date",
      dataIndex: "createdDate",
      width: 100,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Paid",
      width: 90,
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "CCAvenue",
      width: 90,
      dataIndex: "gatewayCreditedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "DoAram",
      width: 90,
      dataIndex: "doaramPlatformFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },

    {
      title: "NGO",
      width: 90,
      dataIndex: "finalAmountAfterFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "READY_FOR_FULFILLMENT") {
          return <Tag color="#C9CC3F">Waiting</Tag>;
        }
        if (text === "Pending") {
          return <Tag color="#F89880">Pending</Tag>;
        }
        if (text === "Failure") {
          return <Tag color="#c04000">Failure</Tag>;
        }
        if (text === "FULFILLED") {
          return <Tag color="#198754">Fulfilled</Tag>;
        }
        if (text === "Cancel") {
          return <Tag color="#FF0000">Canceled</Tag>;
        }
      },
    },
  ];

  const AdminIndividualDonationformData = new FormData();
  AdminIndividualDonationformData.append("userCred", JSON.stringify(userCred));
  AdminIndividualDonationformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminIndividualDonationformData.append("corpId", "");
  AdminIndividualDonationformData.append("ngoId", "");
  AdminIndividualDonationformData.append("causeId", "");
  AdminIndividualDonationformData.append("status", "");
  AdminIndividualDonationformData.append(
    "page",
    JSON.stringify(individualpage - 1)
  );
  AdminIndividualDonationformData.append(
    "size",
    JSON.stringify(individualsize)
  );

  const AdminCorpDonationformData = new FormData();
  AdminCorpDonationformData.append("userCred", JSON.stringify(userCred));
  AdminCorpDonationformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminCorpDonationformData.append("corpId", "");
  AdminCorpDonationformData.append("ngoId", "");
  AdminCorpDonationformData.append("causeId", "");
  AdminCorpDonationformData.append("status", "");
  AdminCorpDonationformData.append("page", corppage - 1);
  AdminCorpDonationformData.append("size", corpsize);

  useEffect(() => {
    UploadService.create(
      "admin/donations/get",
      AdminIndividualDonationformData
    ).then((response) => {
      console.log(response.data);
      setIndividualDataSource(
        response.data.data.donationUserDetailsPage.content
      );
      setIndividualTotalElements(
        response.data.data.donationUserDetailsPage.totalElements
      );
    });

    UploadService.create(
      "/admin/corp/donations/get",
      AdminCorpDonationformData
    ).then((response) => {
      if (response.data !== null) {
        console.log(response.data.data.corpDonationUserDetailsPage.content);
        setCorpDataSource(
          response.data.data.corpDonationUserDetailsPage.content
        );
      }
      setCorpTotalElements(response.data.data.totalElements);
    });
  }, [individualpage, corppage,]);

  return (
    <>
      <div className="col-sm-6 ">
        <h6 className="pt-2 tableheading ms-3">Cause Donation Details:</h6>
      </div>
      <Tabs
        defaultActiveKey="1"
        centered
        bordered={false}
        type="card"
        large
        items={[
          {
            label: "Individual Donations",
            key: "1",
            children: (
              <>
                <div className="col-md-12 row fontfamilynca m-2">
                  <section className="content ">
                    <div className="row">
                      <div className="mt-3 ">
                        <Table
                          columns={Donationcolumns}
                          className="custom-table"
                          dataSource={individualdataSource}
                          style={{ padding: "5px" }}
                          onChange={IndividualhandlePageChange}
                          bordered
                          scroll={{
                            x: 1200,
                          }}
                          pagination={false}
                        />
                        <Pagination
                          Current={individualpage}
                          pageSize={individualsize}
                          onChange={IndividualhandlePageChange}
                          total={individualtotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </>
            ),
          },
          {
            label: "Corporate Donations",
            key: "2",
            children: (
              <section className="content">
                {tablelength > 0 && (
                  <div className="row">
                    <div className="mt-2">
                      <Table
                        columns={Donationcolumns}
                        dataSource={corpdataSource}
                        style={{
                          padding: "5px",
                        }}
                        onChange={CorphandlePageChange}
                        bordered
                        pagination={false}
                        className="custom-table"
                        scroll={{
                          x: 1200,
                        }}
                      />
                      <Pagination
                        Current={corppage}
                        total={corptotalElements}
                        pageSize={corpsize}
                        onChange={CorphandlePageChange}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                )}
                {tablelength == 0 && (
                  <div
                    className="d-flex justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <img
                      src={Nodata}
                      alt="Nodata"
                      className="alignimagecenter"
                    />
                  </div>
                )}
              </section>
            ),
          },
        ]}
      />
    </>
  );
}

export default Admin_Donation;
