import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import logo from "../../assets/da_img/logo1.png";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";

function SignedinUpgrade() {
  const navigate = useNavigate();
  const [silvermodal, setSilvermodel] = useState(false);
  const [goldmodal, setGoldmodel] = useState(false);
  const [platinummodal, setPlatinummodel] = useState(false);
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [selectedpackage, setSelectedpackage] = useState();

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  let userRole = userCred.userRole;
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const Plandetails = (membership) => {

    const UpgradeplanformData = new FormData();
    UpgradeplanformData.append("userCred", JSON.stringify(userCred));
    UpgradeplanformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    UpgradeplanformData.append("membership", membership);
    UploadService.create(
      "ngo/membershipUpgrade/createOrder",
      UpgradeplanformData
    ).then((response) => {
      if (response.data !== null) {
        setEncRequest(response.data.data.encReq);
        setAccessCode(response.data.data.accessCode);
        setSilvermodel(true);
      }
    });
  };
  return (
    <div className="col-md-12 row fontfamilynca ">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-sm-12 pt-2">
              {/* <p className="d-flex justify-content-center">
                <img src={logo} alt="" className="logo" />
              </p> */}
              <h3 className="m-0 text-center">ANNUAL SUBSCRIPTION PLAN</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="pricingTable bronze">
                <div className="pricingTable-header">
                  <h3 className="title">BRONZE</h3>
                  <div className="price-value">
                    <span className="price-currency">
                      <i className="fa fa-inr"></i>
                    </span>
                    <span className="amount">1,999</span>
                    <span className="duraton">per year</span>
                  </div>
                </div>
                <ul className="pricing-content list-unstyle">
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Online
                    Verification
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Static Webpage
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>
                    Login
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Create & Manage Cause
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Telephonic Verification
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Corporate Listing
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Reporting
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Corporate Connect
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-person Verification
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Proposal to Closure management
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Concept Note/MoU/Proposal Templates
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Volunteering
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-Kind Donation
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Products
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Events
                  </li>
                </ul>
                {userRole === "NGO_SILVER" ||
                userRole === "NGO_GOLD" ||
                userRole === "NGO_PLATINUM" ? null : userRole ===
                  "NGO_BRONZE" ? (
                  "Active"
                ) : (
                  <div className="">
                    <button
                      className="btnnca btn-gold btn-sm p-1"
                      onClick={() => Plandetails("NGO_BRONZE")}
                    >
                      Upgrade Now
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="pricingTable silver">
                <div className="pricingTable-header">
                  <h3 className="title">SILVER</h3>
                  <div className="price-value">
                    <span className="price-currency">
                      <i className="fa fa-inr"></i>
                    </span>
                    <span className="amount"> 9,999</span>
                    <span className="duraton">per year</span>
                  </div>
                </div>
                <ul className="pricing-content list-unstyle">
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Online
                    Verification
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Static Webpage
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>
                    Login
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Create & Manage Cause
                  </li>

                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Telephonic Verification
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Corporate Listing
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Reporting
                  </li>

                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Corporate Connect
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-person Verification
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Proposal to Closure management
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Concept Note/MoU/Proposal Templates
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Volunteering
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-Kind Donation
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Products
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Events
                  </li>
                </ul>

                {userRole === "NGO_GOLD" ||
                userRole === "NGO_PLATINUM" ? null : userRole ===
                  "NGO_SILVER" ? (
                  "Active"
                ) : (
                  <div className="">
                    <button
                      className="btnnca btn-gold btn-sm p-1"
                      onClick={() => Plandetails("NGO_SILVER")}
                    >
                      Upgrade Now
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="pricingTable golden">
                <div className="pricingTable-header">
                  <h3 className="title">GOLD</h3>
                  <div className="price-value">
                    <span className="price-currency">
                      <i className="fa fa-inr"></i>
                    </span>
                    <span className="amount">19,999</span>
                    <span className="duraton">per year</span>
                  </div>
                </div>
                <ul className="pricing-content list-unstyle">
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Online
                    Verification
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Static Webpage
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>
                    Login
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Create & Manage Cause
                  </li>

                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Telephonic Verification
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Corporate Listing
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Reporting
                  </li>

                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Corporate Connect
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-person Verification
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Proposal to Closure management
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Concept Note/MoU/Proposal Templates
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Volunteering
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    In-Kind Donation
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Products
                  </li>
                  <li className="category disable">
                    <i className="fa fa-times text-danger mx-2"></i>
                    Events
                  </li>
                </ul>

                {userRole === "NGO_PLATINUM" ? null : userRole ===
                  "NGO_GOLD" ? (
                  "Active"
                ) : (
                  <div className="">
                    <button
                      className="btnnca btn-gold btn-sm p-1"
                      onClick={() => Plandetails("NGO_GOLD")}
                    >
                      Upgrade Now
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="pricingTable platinum">
                <div className="pricingTable-header">
                  <h3 className="title">PLATINUM</h3>
                  <div className="price-value">
                    <span className="price-currency">
                      <i className="fa fa-inr"></i>
                    </span>
                    <span className="amount">39,999</span>
                    <span className="duraton">per year</span>
                  </div>
                </div>
                <ul className="pricing-content list-unstyle">
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Online
                    Verification
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>Static Webpage
                  </li>
                  <li className="category">
                    <i className="fa fa-check text-success mx-2"></i>
                    Login
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Create & Manage Cause
                  </li>

                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Telephonic Verification
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Corporate Listing
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Reporting
                  </li>

                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Corporate Connect
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    In-person Verification
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Proposal to Closure management
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Concept Note/MoU/Proposal Templates
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Volunteering
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    In-Kind Donation
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Products
                  </li>
                  <li className="category ">
                    <i className="fa fa-check text-success mx-2"></i>
                    Events
                  </li>
                </ul>
                {userRole !== "NGO_PLATINUM" ? (
                  <div className="">
                    <button
                      className="btnnca btn-gold btn-sm p-1"
                      onClick={() => Plandetails("NGO_PLATINUM")}
                    >
                      Upgrade Now
                    </button>
                  </div>
                ) : (
                  "Active"
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Documents Required"
          centered
          open={silvermodal}
          footer={null}
          onCancel={() => setSilvermodel(false)}
        >
          <>
            <form
              action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              method="POST"
              name="redirect"
              className="row"
            >
              <input
                type="hidden"
                id="encRequest"
                name="encRequest"
                value={encRequest}
              />
              <input
                type="hidden"
                name="access_code"
                id="access_code"
                value={accessCode}
              />
              <div>
                <ul className="list-group custom-list">
                  <li className="list-group-item">Incorporation from ROC</li>
                  <li className="list-group-item">MOA / AOA</li>
                  <li className="list-group-item">TAN</li>
                  <li className="list-group-item">CSR Form 1</li>
                  <li className="list-group-item">FCRA Reg</li>
                  <li className="list-group-item">ITR of last three AYs</li>
                  <li className="list-group-item">Form 10B of last three AYs</li>
                  <li className="list-group-item">
                    FCRA Returns of last three FYs
                  </li>
                </ul>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btnnca btn-gold"
                  type="submit"
                  style={{ padding: "8px", width: "200px" }}
                >
                  <i className="fa fa-shopping-cart me-1"></i>
                  Buy Now
                </button>
              </div>
            </form>
          </>
        </Modal>
      </section>
    </div>
  );
}

export default SignedinUpgrade;
