import React from "react";
import Construction from "../../assets/icons/construction.png";

function Corp_NGOs() {
  return (
    <div className=" fontfamilynca">

      <div className="col-md-12 row">
      <div className="content-header">
        <div className="container-fluid">
          <div
            className="card d-flex align-items-center"
            style={{ height: "80vh" }}
          >
            <img
              src={Construction}
              alt="Construction"
              className="alignimagecenter img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Corp_NGOs;
