import React from "react";
import { Link } from "react-router-dom";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import blog1img from "../doaram/assets/blog/blog1.jpg";
import blog2img from "../doaram/assets/blog/blog2.jpg";
import blog3img from "../doaram/assets/blog/blog3.jpg";
import blog4img from "../doaram/assets/blog/blog4.jpg";

function BlogDetails3() {
  return (
    <>
      <Header />
      {/* <!-- Facts Start --> */}
      <div className="container mt-5 webbody pt-5">
        <div
          className="text-center mx-auto wow fadeInUp pt-5  mb-5"
          data-wow-delay="0.1s"
        >
          <h1 className="mb-5 pt-5">
            {" "}
            self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope
          </h1>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="mb-5">
              <div className="post-slide ">
                <div className="post-img">
                  <img src={blog3img} alt="blog3img" />
                </div>
                <div className="post-content row">
                  <div className="col-md-9">
                    <h3 className="post-title">
                      <Link to="">
                        self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope
                      </Link>
                    </h3>
                    <p>
                      A self-help group is an independent voluntary association
                      formed by women from the same socio-economic background
                      who come together to the purpose of solving their common
                      problems. The origin of SHG dates back to 1975 founded by
                      professor Mohammed Yunus, a Nobel laureate for pioneering
                      the concepts of microcredit and microfinance. SHGs are
                      formed by government organizations in order to edge the
                      poverty. SHGs are seen as an implementation for the goals
                      of empowering women, developing leadership amongst poor
                      and needy people. Members of SHGs make regular small
                      savings to make a corpus fund until there is enough money
                      to lend it to those who are in need or divide amongst
                      themselves.
                    </p>
                    <p>
                      Though SHGs can be found in other parts of the world it is
                      predominantly located in India. Many SHGs are linked with
                      the banks with the help of an NGO through linkage program
                      for the delivery of microcredit. In India NABARD (National
                      bank for agricultural and rural development) initiated the
                      concept of microcredit and microfinance in the year
                      1986-1987. Through its SHG bank linkage program, it
                      encourages other Indian banks to lend money to SHGs.
                    </p>
                    <p>
                      The very existence of SHGs is to make people of
                      below-poverty-line be hopeful and self-reliant. It enables
                      financial security as a primary focus and other common
                      interest of members in areas like awareness, motivation,
                      leadership, community development, training and
                      associating in social inter-mediation programmes for the
                      benefit of the entire community.
                    </p>
                    <p>
                      An educated facilitator or an animator takes the lead in
                      mobilizing to form SHG. They can be a retired government
                      teacher or a person from an NGO who helps the group
                      members to develop the habit of savings among the members.
                      The group savings are kept in a common bank account to
                      avail loans which are distributed among the member for
                      small business. The loan is then repaid out of the profit
                      earned.SHGs are essentially homogeneous in nature that
                      allows the members to interact freely hence a mixed group
                      is generally not prefered.
                    </p>
                    <p>
                      NGOs play an important role in promoting SHGs. Skill
                      training programs for SHGs are periodically conducted by
                      the NGO to promote leadership qualities and
                      income-generating activities. These types of training
                      bring SHGs close to NGOs for their formation and function.
                      NGO can play a decisive role as an effective delivery
                      mechanism in rural development thus by improving the
                      quality of life and socio-economic status of women. Most
                      of the members of SHGs agree that rural development
                      activities have shown a positive impact and has improved
                      their quality of life tremendously. NGOs facilitate as an
                      intermediate playing a significant role in initiating the
                      right enterprises and technical skills to SHGs. Women are
                      imparted with technical knowledge, skill training and
                      marketing techniques that help to establish a sustainable
                      business. An NGO serves as a key to the capacity building.
                      It is evident that NGO&rsquo;s capacity planning schemes
                      have brought desirable changes in the lives of many women
                      through training thus improving their abilities and
                      skills. Capacity planning empowers women to meet the
                      demanding challenges that are required for employment.
                    </p>
                    <p>
                      Like the coin has two sides the scope of SHGs has a darker
                      side to it. There are many structural issues that throw
                      the scope of SHGs in jeopardy. Lack of proper follow-ups
                      by the NGO is a major failure in the system. Lack of
                      motivation and involvement from the members of the group
                      and the absence of effective leadership makes the growth
                      of SHGs erratic.
                    </p>
                    <p>
                      NGOs serves as an important catalyst in bringing up
                      SHGs.In order to make things straight for the successful
                      functioning of SHGs, NGOs are recommended to play a
                      dedicated role with stability. They should adopt workable
                      strategies for longevity and sustainability of a SHGs in
                      the long run. There wouldn&rsquo;t be an economic boom
                      unless women become economically self-reliant and
                      developed. A study emphasises that the development of
                      women alone will banish the intensity of poverty.
                    </p>

                    <p>
                      {" "}
                      Share : &nbsp; &nbsp; &nbsp;
                      <div className="d-flex pt-2 ms-5">
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </p>

                    <div className="row mt-3">
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          style={{ resize: "none" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                      <button className="btn btn-secondary btn-sm  col-md-3">
                        Leave comment
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-3 mb-5"
                    style={{ borderleft: "1px solid #ddd" }}
                  >
                    <div className="row mb-3">
                      <div className="categories">
                        <h6>
                          <u>Latest Blogs</u>
                        </h6>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src={blog1img}
                                  alt="..."
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                Street play is one of...
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog2img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  Moral education is a...
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog4img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  Foot binding was...
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts End --> */}

      <Part />
      <Footer />
    </>
  );
}

export default BlogDetails3;
