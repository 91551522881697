import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UploadService from "../../service/file-upload.service";

import {
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import $ from "jquery";

function Admin_ViewCorp() {
  const navigate = useNavigate();
  const options = [
    { label: "BRONZE", value: "NGO_BRONZE" },
    { label: "SILVER", value: "NGO_SILVER" },
    { label: "GOLD", value: "NGO_GOLD" },
    { label: "PLATINUM", value: "NGO_PLATINUM" },
  ];

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [form] = Form.useForm();

  let viewdata = null;
  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
  } else {
    viewdata = null;
  }
  const data = useSelector((state) => state.data);

  const approveProfile = () => {
    const ApproveformData = new FormData();
    const corpAccountApproval = {
      id: viewdata.viewdata.id,
      corpId: viewdata.viewdata.corpId,
      userId: viewdata.viewdata.userId,
      adminId: userCred.userId,
      status: viewdata.viewdata.status,
      comments: viewdata.viewdata.comments,
      createdTime: viewdata.viewdata.createdTime,
      modifiedTime: viewdata.viewdata.modifiedTime,
      communication: viewdata.viewdata.communication,
    };

    ApproveformData.append("userCred", JSON.stringify(userCred));
    ApproveformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ApproveformData.append(
      "corpAccountApproval",
      JSON.stringify(corpAccountApproval)
    );

    UploadService.create(
      "/admin/corp/account/decision/approve",
      ApproveformData
    ).then((response) => {
      Modal.confirm({
        title: "Corprate Approved Sucessfully",
        onOk: () => {
          navigate("/Admin_Corp");
        },
      });
    });
  };

  const rejectProfile = () => {
    const RejectformData = new FormData();
    const corpAccountApproval = {
      id: viewdata.viewdata.id,
      corpId: viewdata.viewdata.corpId,
      userId: viewdata.viewdata.userId,
      adminId: userCred.userId,
      status: viewdata.viewdata.status,
      comments: viewdata.viewdata.comments,
      createdTime: viewdata.viewdata.createdTime,
      modifiedTime: viewdata.viewdata.modifiedTime,
      communication: viewdata.viewdata.communication,
    };

    RejectformData.append("userCred", JSON.stringify(userCred));
    RejectformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    RejectformData.append(
      "corpAccountApproval",
      JSON.stringify(corpAccountApproval)
    );

    UploadService.create(
      "/admin/corp/account/decision/reject",
      RejectformData
    ).then((response) => {
      Modal.confirm({
        title: "Cause Rejected Sucessfully",
        onOk: () => {
          navigate("/Admin_Corp");
        },
      });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data.corpProfile.name,
      regCinNumber: data.corpProfile.regCinNumber,
      gstNumber: data.corpProfile.gstNumber,
      pan: data.corpProfile.pan,
      headOfOrg: data.corpProfile.headOfOrg,
      categoryPref: data.corpProfile.categoryPref,
      membershipLevelPref: data.corpProfile.membershipLevelPref,
      about: data.corpProfile.about,
      addressLine1: data.contactInfo.addressLine1,
      addressLine2: "",
      city: data.contactInfo.city,
      state: data.contactInfo.state,
      pinCode: data.contactInfo.pinCode,
      email: data.contactInfo.email,
      phone: data.contactInfo.phone,
      website: data.contactInfo.website,
      facebook: data.contactInfo.facebook,
      instagram: data.contactInfo.instagram,
      linkedin: data.contactInfo.linkedin,
      twitter: data.contactInfo.twitter,
      otherlinks: data.contactInfo.otherlinks,
      coordinatorEmail: data.coordinator.coordinatorEmail,
      coordinatorName: data.coordinator.coordinatorName,
      coordinatorPhone: data.coordinator.coordinatorPhone,
      designation: data.coordinator.designation,
    });
  }, []);

  return (
    <>
      <body className="bg-light  glasscard m-4 coporatesignup">
        <Form
          onFinish={onsubmit}
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          name="validateOnly"
        >
          <div className="text-center">
            <div
              className="pt-2 b"
              style={{ fontSize: "16px", color: "black" }}
            >
              Company Details
            </div>
            <hr style={{ borderTop: "1px dashed" }} />
          </div>
          <div className="col-md-12 row ">
            <div
              className="col-md-6   mt-1"
              // style={{ borderRight: "1px solid #d9d9d9" }}
            >
              {/* <legend className="  mx-3">
              PROFILE DETAILS:
            </legend> */}

              <div className="m-0">
                <Form.Item
                  className=""
                  name="name"
                  label="Company Name :"
                  rules={[
                    {
                      required: true,
                      message: "Company Name is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Company Name" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="regCinNumber"
                  label="CIN Number / Registration Number :"
                  rules={[
                    {
                      required: true,
                      message: "Registration Number is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the Registration Number"
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="gstNumber"
                  label="GST Number :"
                  rules={[
                    {
                      required: true,
                      message: "GST Number is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the GST Number" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="pan"
                  label="PAN Number :"
                  rules={[
                    {
                      required: true,
                      message: "Pan Number is required",
                    },
                  ]}
                >
                  <Input
                    size="medium"
                    placeholder="Enter the Pan Number"
                    maxLength={10}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="headOfOrg"
                  label=" CEO / Head of Organization :"
                  rules={[
                    {
                      required: true,
                      message: "CEO Name is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="CEO Name of Company" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="categoryPref"
                  label="Category Preferences :"
                  rules={[
                    {
                      required: true,
                      message: "Category Preferences is Required",
                    },
                  ]}
                >
                  <Select mode="multiple" placeholder="--NGO Category--" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="email"
                  label="Company Email :"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Email" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="phone"
                  label="Company Phone:"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the Phone number" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  className=" "
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "State is required",
                    },
                  ]}
                >
                  <Select placeholder="--Select State--"></Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "City is required",
                    },
                  ]}
                >
                  <Select placeholder="--Select City--"></Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="pinCode"
                  label="PinCode :"
                  rules={[
                    {
                      required: true,
                      message: "PinCode is required",
                    },
                  ]}
                >
                  <Input size="medium" placeholder="Enter the PinCode" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className=" "
                  name="addressLine1"
                  label="Company Address :"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ height: 150, resize: "none" }}
                    placeholder="Address of Company (In 250 words)"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="col-md-6 mt-1">
              <div className="row ">
                {/* <legend className="  mx-3">
                CONTACT DETAILS:
              </legend> */}
                <div>
                  <Form.Item
                    className=""
                    name="membershipLevelPref"
                    label="NGO Preference"
                  >
                    <Checkbox.Group options={options} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="website"
                    label="Website"
                    values={"NGO"}
                    requiredMark="optional"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Website if you already have."
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="facebook"
                    label="Facebook"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<FacebookOutlined />}
                      placeholder="FaceBook ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="instagram"
                    label="Instagram"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<InstagramOutlined />}
                      placeholder="Instagram ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="linkedin"
                    label="Linkedin"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<LinkedinOutlined />}
                      placeholder="LinkedIn ID"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=""
                    name="twitter"
                    label="Twitter"
                    rules={[
                      {
                        type: "url",
                        message: "please enter correct url",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={<TwitterOutlined />}
                      placeholder="Twitter ID"
                      rules={[
                        {
                          type: "url",
                          message: "please enter correct url",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorEmail"
                    label="Coordinator Email :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorEmail is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorEmail"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorName"
                    label="Coordinator Name :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorName is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorName"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="coordinatorPhone"
                    label="Coordinator Phone :"
                    rules={[
                      {
                        required: true,
                        message: "coordinatorPhone is required",
                      },
                    ]}
                  >
                    <Input
                      size="medium"
                      placeholder="Enter the coordinatorPhone"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="designation"
                    label="Designation :"
                    rules={[
                      {
                        required: true,
                        message: "designation is required",
                      },
                    ]}
                  >
                    <Input size="medium" placeholder="Enter the designation" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className=" "
                    name="about"
                    label="About Company:"
                    rules={[
                      {
                        required: true,
                        message: "About is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ minHeight: 155, resize: "none" }}
                      placeholder="About the Company (In 350 words)"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-flex justify-content-center">
              <Button
                type="button"
                className="btnnca btn-pink btn-sm"
                onClick={approveProfile}
              >
                Approve Profile
              </Button>

              <Button
                type="button"
                className="btnnca btn-danger signupbtn ms-3"
                onClick={rejectProfile}
              >
                Reject Profile
              </Button>
            </div>
          </div>
        </Form>
      </body>
    </>
  );
}

export default Admin_ViewCorp;
