import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Pagination,
  Tag,
} from "antd";
import Nodata from "../../assets/icons/Noaccount.png";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { uploadProfile } from "../../../slices/axiosSlice";
import UploadService from "../../service/file-upload.service";
import { setData } from "../../../slices/corpdataSlice";

function Admin_Corp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reRendertable, setRerendertable] = useState();
  const [addinfoRecord, setAddinfoRecord] = useState();
  const [totalpages, setTotalPages] = useState(0);

  let tablelength = dataSource.length;

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const AdminCorpformData = new FormData();
  const ViewCorpformData = new FormData();
  const ProfileAddtionalinfoformData = new FormData();

  const pagination = {
    position: ["topRight"],
    pageSize: size,
    total: 100,
    showSizeChanger: false,
  };

  const generateColor = (userId) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    const index = userId.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  AdminCorpformData.append("userCred", JSON.stringify(userCred));
  AdminCorpformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminCorpformData.append("page", JSON.stringify(page - 1));
  AdminCorpformData.append("size", JSON.stringify(size));

  useEffect(() => {
    dispatch(
      uploadProfile({
        url: "admin/corp/decision/pending/accounts/get",
        formData: AdminCorpformData,
      })
    )
      .unwrap()
      .then((response) => {
        setTotalElements(response.data.corpAccountApprovalList.totalElements);
        setDataSource(response.data.corpAccountApprovalList.content);

        setTotalPages(response.data.corpAccountApprovalList.totalPages);
      })
      .catch((error) => {
        Modal.info({
          content: (
            <div>
              <p>Something went wrong!Contact Admin Team</p>
            </div>
          ),
          onOk() {},
        });
      });
  }, [page]);

  const columns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.userId.charAt(0).toUpperCase();
        const color = generateColor(record.userId);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "E-Mail",
      dataIndex: "userId",
      width: 90,
    },
    {
      title: "Id",
      dataIndex: "corpId",
      width: 90,
    },

    {
      title: "Created Date",
      dataIndex: "createdTime",
      width: 90,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "Waiting for Approval") {
          return <Tag color="red">WAITING</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 90,
      render: (record) => (
        <div>
          <Tooltip title="View Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para ">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="Additional Request">
            <a onClick={() => additinalInfo(record)}>
              <span className="btn btn-warning btn-sm para ms-2">
                <i className="fas fa-plus text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const viewProfile = (record) => {
    ViewCorpformData.append("userCred", JSON.stringify(userCred));
    ViewCorpformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ViewCorpformData.append("corpAccountApproval", JSON.stringify(record));

    UploadService.create("admin/corp/profile/get", ViewCorpformData).then(
      (response) => {
        dispatch(setData(response.data.data));
        navigate("/Admin_ViewCorp", {
          state: {
            viewdata: record,
          },
        });
      }
    );
  };

  const additinalInfo = (record) => {
    setIsModalOpen(true);
    setAddinfoRecord(record);
  };

  const onsubmit = (values) => {
    setIsModalOpen(false);
    ProfileAddtionalinfoformData.append("userCred", JSON.stringify(userCred));
    ProfileAddtionalinfoformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    let addInfo = {
      id: addinfoRecord.id,
      corpId: addinfoRecord.corpId,
      userId: addinfoRecord.userId,
      adminId: userCred.userId,
      status: addinfoRecord.status,
      comments: values.reason,
      createdTime: addinfoRecord.createdTime,
      modifiedTime: addinfoRecord.modifiedTime,
      communication: addinfoRecord.communication,
    };
    ProfileAddtionalinfoformData.append(
      "corpAccountApproval",
      JSON.stringify(addInfo)
    );
    UploadService.create(
      "/admin/corp/account/decision/needAddnlInfo",
      ProfileAddtionalinfoformData
    ).then((response) => {
      Modal.info({
        content: (
          <div>
            <p>{response.data.statusMessage}</p>
          </div>
        ),
      });
      setRerendertable(addinfoRecord);
    });
  };

  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Pending Account:</h6>
          </div>
          <section className="content">
            {tablelength > 0 && (
              <div className="row">
                <div className="mt-2">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    style={{
                      padding: "5px",
                    }}
                    onChange={handlePageChange}
                    bordered
                    pagination={false}
                    className="custom-table"
                    scroll={{
                      x: 1000,
                    }}
                  />
                  <Pagination
                    Current={page}
                    total={totalElements}
                    pageSize={size}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            )}
            {tablelength == 0 && (
              <div
                className="d-flex justify-content-center"
                style={{ height: "80vh" }}
              >
                <img src={Nodata} alt="Nodata" className="alignimagecenter" />
              </div>
            )}
          </section>
        </div>
      </>

      <Modal
        open={isModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Please provide your reason for the Additional Documents.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default Admin_Corp;
