function AccountVerify() {
  const movetohome = () => {
    window.location.href = "/";
  };
  return (
    <div className="col-md-12 row fontfamilynca">
      <div
        className="d-flex justify-content-center"
        style={{
          background: "url('../../imges/back2.png')",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="col-md-8 p-5 ml-5 mt-5">
          <div className="card ">
            <div
              className="card-header"
              style={{
                background: "linear-gradient(270deg, #e09400 0%, #fdc24f 100%)",
              }}
            >
              <div className="d-flex justify-content-center">
                <img
                  src={require("./../../assets/icons/email.png")}
                  alt="check-icon"
                />
              </div>
              <div className="text-center font12 text-white fw-bold">
                DoAram - E-mail Verification
              </div>
            </div>

            <div className="card-body">
              <p className="h6 text-dark pt-2">
                Thank you for signing up with DoAram. We have sent a link to the
                email address you have provided. Please click on the link to
                verify and activate your account.
              </p>

              <p className="pt-2 h6 text-dark  d-flex justify-content-center">
                For further clarification, reach us at
                &nbsp;<a href="mailto:info@doaram.com">info@doaram.com</a>
              </p>
            </div>
            <div className="card-footer">
              <div className="text-center font12 text-success fw-bold pt-3">
                <button
                  className="btn btn-success btn-sm"
                  type="button"
                  onClick={movetohome}
                >
                  <i className="fa fa-arrow-left"></i>&nbsp;Back To Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountVerify;
