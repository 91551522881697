import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, Dropdown } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import $ from "jquery";
const { Sider, Content, Header } = Layout;
const current = new Date();
const toDay = `${current.getDate()} / ${
  current.getMonth() + 1
} / ${current.getFullYear()}`;

const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

const SideMenucorp = ({ children }) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        {" "}
        <a className="dropdown-item">Logged-In Date: {toDay}</a>
      </Menu.Item>
      {/* <Menu.Item key="2">
        {" "}
        <a className="dropdown-item">
        <i className="fas fa-hourglass" aria-hidden="true"></i>&nbsp;
        Last Login Date: {(userCred.lastLoginTime.split(".")[0]).replace("T"," ")}</a>
      </Menu.Item> */}
      <Menu.Item key="3">
        {" "}
        <NavLink className="dropdown-item" to="/Corp_Profile">
          <i className="fas fa-user" aria-hidden="true"></i>&nbsp; Account
          Setting
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        {" "}
        <NavLink className="dropdown-item" to="/corpsignin">
          <i className="fas fa-sign-out-alt" aria-hidden="true"></i>&nbsp;
          Logout
        </NavLink>
      </Menu.Item>
    </Menu>
  );
  const [collapsed, setCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "1"
  );
  const location = useLocation();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleMenuClick = (e) => {
    setActiveTab(e.key);
    localStorage.setItem("activeTab", e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    switch (currentPath) {
      case "/corpDashboard":
        setActiveTab("1");
        break;
      case "/Corp_NGO":
        setActiveTab("2");
        break;
      case "/Corp_Cause":
        setActiveTab("3");
        break;
      case "/Corp_Donation":
        setActiveTab("4");
        break;
      case "/Corp_Profile":
        setActiveTab("5");
        break;
      default:
        setActiveTab("1"); // Default tab
        break;
    }
  }, [location.pathname]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={toggleSidebar}
        className="antsidebar text-white"
        style={{ lineHeight: "1.7rem", fontWeight: "300" }}
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/da_img/logo1.png")}
            alt="DoAram_Logo"
            className="img-fluid p-4 "
            style={{ maxHeight: "150px", maxWidth: "150px" }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="antsidebar  nav-linknca fontfamilynca"
          onClick={handleMenuClick}
          selectedKeys={[activeTab]}
        >
          <Menu.Item key="1" icon={<i className="nav-icon fa fa-home"></i>}>
            <Link to="/corpDashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item key="2" icon={<i className="nav-icon fa fa-building"></i>}>
            <Link to="/Corp_NGO">NGOs</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<i className="nav-icon fa fa-heart"></i>}>
            <Link to="/Corp_Cause">Causes</Link>
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<i className="nav-icon fa fa-credit-card"></i>}
          >
            <Link to="/Corp_Donation">My Donation</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<i className="nav-icon fa fa-user"></i>}>
            <Link to="/Corp_Profile">Profile</Link>
          </Menu.Item>
          {/* <Menu.Item key="6" icon={<i className="far fa-bell nav-icon"></i>}>
            <Link to="">Notifications</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<i className="fas fa-cog nav-icon"></i>}>
            <Link to="">Settings</Link>
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={<i className="nav-icon fas fa-question-circle"></i>}
          >
            <Link to="">Help</Link>
          </Menu.Item> */}
        </Menu>
      </Sider>

      <Layout>
        <Header
          style={{
            padding: 0,
            backgroundColor: "white",
          }}
          className="d-flex justify-content-between  align-items-center"
        >
          <div>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
              className="collapse-button"
            />
          </div>
          <div>
            <Dropdown overlay={menu}>
              <Button
                type="primary"
                icon={<i className="fa fa-user-circle"></i>}
                size="medium"
                className="btnnca btn-pink btn-sm homebtnheight me-3"
              >
                Profile <i className="fa fa-angle-down ms-1"></i>{" "}
                {/* Add your icon here */}
              </Button>
            </Dropdown>

            {/* <Link
              to="#"
              className="nav-linkheadernca"
              data-widget="fullscreen"
              role="button"
            >
              <i
                className="fas fa-expand-arrows-alt themeCol-pink me-3 my-2 collapse-button"
                style={{ fontSize: 17 }}
              ></i>
            </Link> */}
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default SideMenucorp;
