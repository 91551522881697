import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import { Table, Modal, Tooltip, Pagination, Tag } from "antd";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";

function NGO_Cause(props) {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [description, setDescription] = useState([]);
  const [showResume, setShowResume] = useState(false);
  const [showpause, setShowPasue] = useState(true);
  const [reRendertable, setRerendertable] = useState();
  const [totalpages, setTotalPages] = useState(0);
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userType = $.parseJSON(sessionStorage.getItem("da_userType"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const element = document.getElementById("arun");

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();

  let tablelength = dataSource.length;
  const formDataWithFiles = new FormData();
  formDataWithFiles.append("userCred", JSON.stringify(userCred));
  formDataWithFiles.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  formDataWithFiles.append("page", JSON.stringify(page - 1));
  formDataWithFiles.append("size", JSON.stringify(size));

  const handlePageChange = (page) => {
    setPage(page);
  };
  const dynamiccausesite = (causeid, causename) => {
    const causeSlug = causename.toLowerCase().replace(/\s+/g, "-");
    navigate(`/cause/${causeSlug}/${causeid}`);
  };

  const columns = [
    {
      title: "Title",

      dataIndex: "title",
    },
    {
      title: "Category",

      dataIndex: "category",
    },
    {
      title: "Website",
      dataIndex: "causeLink",

      render: (text, record) => {
        if (
          record.status !== "APPROVED" &&
          record.status !== "RESUMED" &&
          record.status !== "EDITED_VERSION_UPGRADED"
        ) {
          return (
            <span className="p-2 btn-sm">
              <i className="fas  fa-hourglass-half"></i>{" "}
            </span>
          );
        }
        return (
          <span
            className="btn btn-orange btn-sm para"
            onClick={() => {
              dynamiccausesite(record.causeId, record.title);
            }}
          >
            <i className="fas fa-external-link-alt"></i>{" "}
          </span>
        );
      },
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Donations",
      dataIndex: "goalAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "WAITING_FOR_APPROVAL") {
          return <Tag color="#FF0000">Waiting</Tag>;
        }
        if (text === "RESUMED") {
          return <Tag color="#cd7f32">Resumed</Tag>;
        }
        if (text === "PAUSED") {
          return <Tag color="#6a737b">Paused</Tag>;
        }
        if (text === "REJECTED") {
          return <Tag color="red">Rejected</Tag>;
        }
        if (text === "APPROVED") {
          return <Tag color="#008000	">Approved</Tag>;
        }
        if (text === "NEED_ADDNL_INFO") {
          return <Tag color="#FF7F50	">More info</Tag>;
        }
        if (text === "EDITED_VERSION_UPGRADED") {
          return <Tag color="#008eaa">Edited</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (text, record) => {
        if (
          record.status !== "APPROVED" &&
          record.status !== "RESUMED" &&
          record.status !== "PAUSED" &&
          record.status !== "EDITED_VERSION_UPGRADED"
        ) {
          return (
            <>
              <span className="btn btn-info btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
              <span className="btn btn-warning btn-sm para me-1">
                <i className="fa fa-ban text-white" id="arun"></i>
              </span>
              <span className="btn btn-danger btn-sm para  me-1">
                <i className="fas fa-ban text-white "></i>
              </span>
            </>
          );
        }
        return (
          <div>
            <Tooltip title="Edit cause">
              <a onClick={() => Editrecord(record)}>
                <span className="btn btn-info btn-sm para me-1">
                  <i className="fas fa-edit text-white"></i>
                </span>
              </a>
            </Tooltip>
            <Tooltip title="Pause and Resume cause">
              <a onClick={() => Pausecause(record)}>
                <span className="btn btn-warning btn-sm para me-1">
                  <i className="fa fa-step-forward text-white" id="arun"></i>
                </span>
              </a>
            </Tooltip>
            {/* {showResume && (
            <a onClick={() => Resumecause(record)}>
              <span className="btn btn-warning btn-sm para me-1 ">
                <i className="fas fa-play text-white"></i>
              </span>
            </a>
          )} */}
            <Tooltip title="Cancel cause">
              <a onClick={() => Deleterecord(record)}>
                <span className="btn btn-danger btn-sm para  me-1">
                  <i className="fas fa-times-circle text-white "></i>
                </span>
              </a>
            </Tooltip>
            {/* <Tooltip title="Complete cause">
            <a onClick={() => Completecause(record)}>
              <span className="btn btn-success  btn-sm para  me-1">
                <i className="fas fa-check-double  text-white"></i>
              </span>
            </a>
          </Tooltip> */}
          </div>
        );
      },
    },
  ];
  const DeleteformDataWithFiles = new FormData();
  const Deleterecord = (record) => {
    Modal.confirm({
      title: "Are you sure,You want to delete this Cause?",
      okText: "Delete",
      onOk: () => {
        DeleteformDataWithFiles.append("userCred", JSON.stringify(userCred));
        DeleteformDataWithFiles.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );
        DeleteformDataWithFiles.append("cause", JSON.stringify(record));

        UploadService.create("/ngo/cause/cancel", DeleteformDataWithFiles).then(
          (response) => {
            Modal.info({
              content: (
                <div>
                  <p>
                    {response.data.message.messageDetails[0].detailedMessage}
                  </p>
                </div>
              ),
              onOk() {},
            });
            setRerendertable(record);
          }
        );
      },
    });
  };

  const Editrecord = (record) => {
    navigate("/UpdateCause", { state: { editdata: record } });
  };
  const ResumecauseformData = new FormData();
  const PausecauseformData = new FormData();
  const Pausecause = (record) => {
    if (record.status !== "PAUSED") {
      Modal.confirm({
        title: "Are you sure,You want to Pause this Cause?",
        okText: "Pause",
        onOk: () => {
          PausecauseformData.append("userCred", JSON.stringify(userCred));
          PausecauseformData.append(
            "userRoleAuthMapping",
            JSON.stringify(userRoleAuthMapping)
          );
          PausecauseformData.append("cause", JSON.stringify(record));
          UploadService.create("/ngo/cause/pause", PausecauseformData).then(
            (response) => {
              Modal.info({
                content: (
                  <div>
                    <p>
                      {response.data.message.messageDetails[0].detailedMessage}
                    </p>
                  </div>
                ),
              });
              setRerendertable(record);
            }
          );
        },
      });
    } else if (record.status == "PAUSED") {
      Modal.confirm({
        title: "Are you sure,You want to Resume this Cause?",
        okText: "Resume",
        onOk: () => {
          ResumecauseformData.append("userCred", JSON.stringify(userCred));
          ResumecauseformData.append(
            "userRoleAuthMapping",
            JSON.stringify(userRoleAuthMapping)
          );
          ResumecauseformData.append("cause", JSON.stringify(record));
          UploadService.create("/ngo/cause/resume", ResumecauseformData).then(
            (response) => {
              // element.classList.remove("fas", "fa-check-double", "text-white");
              // element.classList.add("fas", "fa-play", "text-white");
              Modal.info({
                content: (
                  <div>
                    <p>
                      {response.data.message.messageDetails[0].detailedMessage}
                    </p>
                  </div>
                ),
              });
              setRerendertable(record);
            }
          );
        },
      });
    }
  };
  const CompletecauseformData = new FormData();

  const Completecause = (record) => {
    Modal.confirm({
      title:
        "Are you sure,You want to Complte this Cause? This leads to close the cause completely",
      okText: "Complete",
      onOk: () => {
        CompletecauseformData.append("userCred", JSON.stringify(userCred));
        CompletecauseformData.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );
        CompletecauseformData.append("cause", JSON.stringify(record));
        UploadService.create("/ngo/cause/complete", CompletecauseformData).then(
          (response) => {
            Modal.info({
              content: (
                <div>
                  <p>
                    {response.data.message.messageDetails[0].detailedMessage}
                  </p>
                </div>
              ),
            });
            setRerendertable(record);
          }
        );
      },
    });
  };

  useEffect(() => {
    if (userCred !== null) {
      
      UploadService.create("/ngo/cause/get", formDataWithFiles).then(
        (response) => {
          setTotalElements(response.data.data.causePage.totalElements);


          setDataSource(response.data.data.causePage.content);
          setTotalPages(response.data.data.causePage.totalPages);
        }
      );
    }
  }, [reRendertable, page]);
  return (
    <>
      
      <div className="col-md-12 row fontfamilynca">
        <div className="content-header">
          <div className="d-flex justify-content-between p-2">
            <div>
              <h6 className="m-0 tableheading"> My Causes</h6>
            </div>
            <div>
              <NavLink to="/CauseDetails" className="nav-link">
                <button className="btn btn-warning btn-sm " type="button">
                  <i className="fa fa-plus-circle"></i> Create Cause
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <section className="content mx-2">
          {tablelength > 0 && (
            <div className="row">
              <div className="mt-3 ">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  onChange={handlePageChange}
                  className="custom-table table table-hover table-stripped table-bordered fontfamilynca"
                  bordered
                  scroll={{
                    x: 1000,
                  }}
                />
                <Pagination
                  Current={page}
                  total={totalElements}
                  pageSize={size}
                  onChange={handlePageChange}
                  showSizeChanger={false}

                />
              </div>
            </div>
          )}
          {tablelength == 0 && (
            <div
              className="d-flex justify-content-center"
              style={{ height: "80vh" }}
            >
              <img src={Nodata} alt="Nodata" className="alignimagecenter" />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default NGO_Cause;
