import React, { useEffect, useState } from "react";
import UploadService from "../../service/file-upload.service";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Tabs,
  message,
  Modal,
  Tooltip,
} from "antd";

import { json, useNavigate } from "react-router-dom";
import {
  CopyOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import Loading from "../../assets/loading-circle.gif";

function NGO_Profile(props) {
  const navigate = useNavigate();

  const getImageFormdata = new FormData();
  const [fileName, setfileName] = useState();
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [ngoId, setNgoId] = useState();
  const [ngoname, setNgoName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copytxt, setCopytxt] = useState("copy");
  const [copied, setCopied] = useState(false);
  const url = `https://www.doaram.com/ngo/${ngoname}/${ngoId}`;
  const [profileData, setProfiledata] = useState();
  const [contactInfoData, setContactInfodata] = useState();
  const [ngoCoordinatorData, setngoCoordinatordata] = useState();
  const [bankDetailsData, setbankDetailsdata] = useState();

  const [form] = Form.useForm();
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const profileformData = new FormData();
  profileformData.append("userCred", JSON.stringify(userCred));
  profileformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );

  const onsubmit = (values) => {
    setBlur(true);
    setLoading(true);
    const formDataWithFiles = new FormData();
    const ngoProfile = {
      id: userCred.associatedEntity,
      name: profileData.name,
      category: values.category.toString(),
      about: values.about,
      history: values.history,
      vision: values.vision,
      mission: values.mission,
      geoFocus: profileData.geoFocus,
      staticWebPageLink: "",
      membership: profileData.membership,
      certIncorpRoc: profileData.certIncorpRoc,
      moaAoa: profileData.moaAoa,
      regTrustDeed: profileData.regTrustDeed,
      pan: profileData.pan,
      tan: profileData.tan,
      csrForm1: profileData.csrForm1,
      cert12a: profileData.cert12a,
      cert80g: profileData.cert80g,
      certFcra: profileData.certFcra,
      logo: profileData.logo,
      micrositeImages: profileData.micrositeImages,
      itrForm: profileData.itrForm,
      form10B: profileData.form10B,
      fcraReturns: profileData.fcraReturns,
      createdBy: profileData.createdBy,
      createdDate: profileData.createdDate,
      updatedBy: profileData.updatedBy,
      updatedDate: profileData.updatedDate,
    };

    const contactInfo = {
      entityId: userCred.associatedEntity,
      addressLine1: contactInfoData.addressLine1,
      addressLine2: "",
      city: contactInfoData.city,
      state: contactInfoData.state,
      country: contactInfoData.country,
      pinCode: contactInfoData.pinCode,
      email: contactInfoData.email,
      phone: contactInfoData.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "",
      createdBy: contactInfoData.createdBy,
      createdDate: contactInfoData.createdDate,
      updatedBy: contactInfoData.updatedBy,
      updatedDate: contactInfoData.updatedDate,
    };

    const ngoCoordinator = {
      entityId: userCred.associatedEntity,
      coordinatorEmail: ngoCoordinatorData.coordinatorEmail,
      coordinatorName: ngoCoordinatorData.coordinatorName,
      coordinatorPhone: ngoCoordinatorData.coordinatorPhone,
      createdBy: ngoCoordinatorData.createdBy,
      createdDate: ngoCoordinatorData.createdDate,
      updatedBy: ngoCoordinatorData.updatedBy,
      updatedDate: ngoCoordinatorData.updatedDate,
    };

    const bankDetail = {
      entityId: userCred.associatedEntity,
      bankName: bankDetailsData.bankName,
      branchName: bankDetailsData.branchName,
      accountName: bankDetailsData.accountName,
      accountNumber: bankDetailsData.accountNumber,
      ifscCode: bankDetailsData.ifscCode,
      upiCode: bankDetailsData.upiCode,
      createdBy: bankDetailsData.createdBy,
      createdDate: bankDetailsData.createdDate,
      updatedBy: bankDetailsData.updatedBy,
      updatedDate: bankDetailsData.updatedDate,
    };

    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    formDataWithFiles.append("ngoProfile", JSON.stringify(ngoProfile));
    formDataWithFiles.append("contactInfo", JSON.stringify(contactInfo));
    formDataWithFiles.append("ngoCoordinator", JSON.stringify(ngoCoordinator));
    formDataWithFiles.append("bankDetails", JSON.stringify(bankDetail));

    UploadService.create("/ngo/updateProfile", formDataWithFiles).then(
      (response) => {
        setBlur(false);
        setLoading(false);
        let logSts = response.data.message.messageType;
        let logStsDesc = response.data.message.messageDetails[2];
        if (logSts == "Success") {
          window.toastr.warning(
            "Profile updation request received successfully"
          );
        } else {
          window.toastr.danger(logStsDesc);
          setBlur(true);
          setLoading(true);
        }
      }
    );
  };

  const OPTIONS = [
    "Elderly",
    "Animals",
    "Children",
    "Cultural Heritage",
    "Education",
    "Employment",
    "Environment",
    "Disaster Relief Works",
    "Others",
    "Women Empowerment",
    "Community Mobilization",
    "Rural Development",
    "Sustainable Livelihood",
    "Differently Abled",
    "Advocacy",
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  const callImageAPI = (filename) => {
    getImageFormdata.append("userCred", JSON.stringify(userCred));
    getImageFormdata.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    getImageFormdata.append("ngoProfile", JSON.stringify(profileData));
    setfileName(filename);
    getImageFormdata.append("fileName", filename);

    UploadService.filedownload("/ngo/profile/getImage", getImageFormdata)

      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }

  const [logoFilename, setlogoFilename] = useState("");
  const [cert12aFilename, setcert12aFilename] = useState("");

  const [microSiteFilename1, setmicroSiteFilename1] = useState("");
  const [microSiteFilename2, setmicroSiteFilename2] = useState("");
  const [microSiteFilename3, setmicroSiteFilename3] = useState("");

  const [certIncorpRocFilename, setcertIncorpRocFilename] = useState("");
  const [pancardFilename, setpancardFilename] = useState("");
  const [regTrustDeedFilename, setregTrustDeedFilename] = useState("");
  const [moaAoaFilename, setmoaAoaFilename] = useState("");
  const [tanFilename, settanFilename] = useState("");
  const [csrForm1Filename, setcsrForm1Filename] = useState("");
  const [cert80gFilename, setcert80gFilename] = useState("");
  const [certFcraFilename, setcertFcraFilename] = useState("");

  const [itrFormFilename1, setitrFormFilename1] = useState("");
  const [itrFormFilename2, setitrFormFilename2] = useState("");
  const [itrFormFilename3, setitrFormFilename3] = useState("");

  const [fcraReturnsFilename1, setfcraReturnsFilename1] = useState("");
  const [fcraReturnsFilename2, setfcraReturnsFilename2] = useState("");
  const [fcraReturnsFilename3, setfcraReturnsFilename3] = useState("");

  const [form10BFilename1, setform10BFilename1] = useState("");
  const [form10BFilename2, setform10BFilename2] = useState("");
  const [form10BFilename3, setform10BFilename3] = useState("");

  const islogoEmpty = logoFilename.trim().length === 0;
  const iscert12aEmpty = cert12aFilename.trim().length === 0;

  const ismicroSiteEmpty1 = microSiteFilename1.trim().length === 0;
  const ismicroSiteEmpty2 = microSiteFilename2.trim().length === 0;
  const ismicroSiteEmpty3 = microSiteFilename3.trim().length === 0;

  const iscertIncorpRocEmpty = certIncorpRocFilename.trim().length === 0;
  const ispancardEmpty = pancardFilename.trim().length === 0;
  const isregTrustDeedEmpty = regTrustDeedFilename.trim().length === 0;
  const ismoaAoaEmpty = moaAoaFilename.trim().length === 0;
  const istanEmpty = tanFilename.trim().length === 0;
  const iscsrForm1Empty = csrForm1Filename.trim().length === 0;
  const iscert80gEmpty = cert80gFilename.trim().length === 0;
  const iscertFcraEmpty = certFcraFilename.trim().length === 0;

  const isitrFormEmpty1 = itrFormFilename1.trim().length === 0;
  const isitrFormEmpty2 = itrFormFilename2.trim().length === 0;
  const isitrFormEmpty3 = itrFormFilename3.trim().length === 0;

  const isform10BEmpty1 = form10BFilename1.trim().length === 0;
  const isform10BEmpty2 = form10BFilename2.trim().length === 0;
  const isform10BEmpty3 = form10BFilename3.trim().length === 0;

  const isfcraReturnsEmpty1 = fcraReturnsFilename1.trim().length === 0;
  const isfcraReturnsEmpty2 = fcraReturnsFilename2.trim().length === 0;
  const isfcraReturnsEmpty3 = fcraReturnsFilename3.trim().length === 0;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
        setCopytxt("Copied");
        setTimeout(() => {
          setCopied(false);
          setCopytxt("Copy");
        }, 6000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  useEffect(() => {
   
    UploadService.create("/ngo/getProfile", profileformData).then(
      (response) => {
        setNgoId(response.data.data.ngoProfile.id);
        setNgoName(
          response.data.data.ngoProfile.name.toLowerCase().replace(/\s+/g, "-")
        );
        form.setFieldsValue({
          name: response.data.data.ngoProfile.name,
          category: response.data.data.ngoProfile.category,
          about: response.data.data.ngoProfile.about,
          history: response.data.data.ngoProfile.history,
          vision: response.data.data.ngoProfile.vision,
          mission: response.data.data.ngoProfile.mission,
          geoFocus: response.data.data.ngoProfile.geoFocus,
          staticWebPageLink: response.data.data.ngoProfile.staticWebPageLink,
          certIncorpRoc: response.data.data.ngoProfile.certIncorpRoc,
          moaAoa: response.data.data.ngoProfile.moaAoa,
          regTrustDeed: response.data.data.ngoProfile.regTrustDeed,
          pan: response.data.data.ngoProfile.pan,
          tan: response.data.data.ngoProfile.tan,
          csrForm1: response.data.data.ngoProfile.csrForm1,
          cert12a: response.data.data.ngoProfile.cert12a,
          cert80g: response.data.data.ngoProfile.cert80g,
          certFcra: response.data.data.ngoProfile.certFcra,
          itrForm: response.data.data.ngoProfile.itrForm,
          form10B: response.data.data.ngoProfile.form10B,
          fcraReturns: response.data.data.ngoProfile.fcraReturns,
          logo: response.data.data.ngoProfile.logo,
          micrositeImages: response.data.data.ngoProfile.micrositeImages,

          addressLine1: response.data.data.contactInfo.addressLine1,
          city: response.data.data.contactInfo.city,
          state: response.data.data.contactInfo.state,
          country: response.data.data.contactInfo.country,
          pinCode: response.data.data.contactInfo.pinCode,
          email: response.data.data.contactInfo.email,
          phone: response.data.data.contactInfo.phone,
          website: response.data.data.contactInfo.website,
          facebook: response.data.data.contactInfo.facebook,
          instagram: response.data.data.contactInfo.instagram,
          linkedin: response.data.data.contactInfo.linkedin,
          twitter: response.data.data.contactInfo.twitter,
          otherlinks: response.data.data.contactInfo.otherlinks,

          coordinatorName: response.data.data.coordinator.coordinatorName,
          coordinatorEmail: response.data.data.coordinator.coordinatorEmail,
          coordinatorPhone: response.data.data.coordinator.coordinatorPhone,

          bankName: response.data.data.bankDetails.bankName,
          branchName: response.data.data.bankDetails.branchName,
          accountName: response.data.data.bankDetails.accountName,
          accountNumber: response.data.data.bankDetails.accountNumber,
          ifscCode: response.data.data.bankDetails.ifscCode,
          upiCode: response.data.data.bankDetails.upiCode,
        });

        setProfiledata(response.data.data.ngoProfile);
        setContactInfodata(response.data.data.contactInfo);
        setngoCoordinatordata(response.data.data.coordinator);
        setbankDetailsdata(response.data.data.bankDetails);

        setcertIncorpRocFilename(response.data.data.ngoProfile.certIncorpRoc);
        setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
        setpancardFilename(response.data.data.ngoProfile.pan);
        setlogoFilename(response.data.data.ngoProfile.logo);
        setcert12aFilename(response.data.data.ngoProfile.cert12a);

        const micrositeimageNames =
          response.data.data.ngoProfile.micrositeImages;
        const micrositeindividualimage = micrositeimageNames.split(",");
        if (micrositeindividualimage[0]) {
          setmicroSiteFilename1(micrositeindividualimage[0]);
        }
        if (micrositeindividualimage[1]) {
          setmicroSiteFilename2(micrositeindividualimage[1]);
        }
        if (micrositeindividualimage[2]) {
          setmicroSiteFilename3(micrositeindividualimage[2]);
        }
        setregTrustDeedFilename(response.data.data.ngoProfile.regTrustDeed);
        setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
        setcertFcraFilename(response.data.data.ngoProfile.certFcra);

        const itrFormimageNames = response.data.data.ngoProfile.itrForm;
        const itrformindividualimage = itrFormimageNames.split(",");
        if (itrformindividualimage[0]) {
          setitrFormFilename1(itrformindividualimage[0]);
        }
        if (itrformindividualimage[1]) {
          setitrFormFilename2(itrformindividualimage[1]);
        }
        if (itrformindividualimage[2]) {
          setitrFormFilename3(itrformindividualimage[2]);
        }
        settanFilename(response.data.data.ngoProfile.tan);
        setcert80gFilename(response.data.data.ngoProfile.cert80g);
        setcsrForm1Filename(response.data.data.ngoProfile.csrForm1);

        const fcraNames = response.data.data.ngoProfile.fcraReturns;
        const fcraindividualimage = fcraNames.split(",");
        if (fcraindividualimage[0]) {
          setfcraReturnsFilename1(fcraindividualimage[0]);
        }
        if (fcraindividualimage[1]) {
          setfcraReturnsFilename2(fcraindividualimage[1]);
        }
        if (fcraindividualimage[2]) {
          setfcraReturnsFilename3(fcraindividualimage[2]);
        }
        const form10BNames = response.data.data.ngoProfile.form10B;
        const form10Bindividualimage = form10BNames.split(",");
        if (form10Bindividualimage[0]) {
          setform10BFilename1(form10Bindividualimage[0]);
        }
        if (form10Bindividualimage[1]) {
          setform10BFilename2(form10Bindividualimage[1]);
        }
        if (form10Bindividualimage[2]) {
          setform10BFilename3(form10Bindividualimage[2]);
        }
      }
    );
  }, []);
  const handleOk = () => {
    navigate("/");
  };

  return (
    <>
    {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
      <div className="row">
        <div className="col-md-8 mt-1">
          <h5 className="m-0 tableheading mt-3 m">&nbsp;Your Profile:</h5>
        </div>
      </div>
      <div className="col-md-12 row fontfamilynca ">
        <div className="col-md-12" style={{ marginLeft: "0px" }}>
          <div className="">
            <Form
              autoComplete="off"
              labelCol={{ span: 8 }}
              form={form}
              onFinish={onsubmit}
            >
              <div className="d-flex justify-content-end mt-3">
                <Button
                  htmlType="submit"
                  className="btnnca btn-pink btn-sm"
                  style={{ height: "30px" }}
                >
                  <i className="fa fa-pencil-square-o"></i> Update My Profile
                </Button>
              </div>
              <div className="col-md-12 glasscard m-3 p-3">
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ borderRight: "1px solid #f3f3f3" }}
                  >
                    <fieldset>
                      <div className=" p-0">
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          {/* Name of NGO*/}
                          <legend className="para b themeCol-orange mx-3">
                            NGO Information:{" "}
                          </legend>
                          <div className="mt-4">
                            <Form.Item
                              className="para b themeCol-orange"
                              name="name"
                              label="NGO Name"
                            >
                              <Input
                                size="medium"
                                placeholder="Name of  the NGO"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="mt-4">
                            <Form.Item
                              className="para b themeCol-orange"
                              label="Static Link"
                            >
                              <Input
                                size="large"
                                value={url}
                                readOnly
                                addonAfter={
                                  <Tooltip title={copytxt}>
                                    <Button
                                      type="link"
                                      onClick={copyToClipboard}
                                      icon={
                                        <CopyOutlined
                                          style={{ color: "black" }}
                                        />
                                      }
                                    />
                                  </Tooltip>
                                }
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="category"
                              label="Category"
                              rules={[
                                {
                                  required: true,
                                  message: "Category is required",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                placeholder="--NGO Category--"
                                value={selectedItems}
                                onChange={setSelectedItems}
                                style={{
                                  width: "100%",
                                }}
                                options={filteredOptions.map((item) => ({
                                  value: item,
                                  label: item,
                                }))}
                              ></Select>
                            </Form.Item>
                          </div>
                          <div className="mt-4">
                            <Form.Item
                              className="para b themeCol-orange"
                              name="addressLine1"
                              label="Address"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: " Address is required",
                                  // },
                                ]
                              }
                            >
                              <Input.TextArea
                                disabled
                                style={{ height: 100, resize: "none" }}
                                placeholder="Registered Address"
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange da_country"
                              name="country"
                              label="Country"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "Country is required",
                                  // },
                                ]
                              }
                            >
                              <Select
                                countryid="101"
                                placeholder="-Select Country-"
                                disabled
                              ></Select>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange da_state"
                              name="state"
                              label="State"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "State is required",
                                  // },
                                ]
                              }
                            >
                              <Select
                                placeholder="-Select State-"
                                disabled
                              ></Select>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange da_cities"
                              name="city"
                              label="City"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "City is required",
                                  // },
                                ]
                              }
                            >
                              <Select
                                onChange={() => window.PopulateCountry()}
                                placeholder="-Select City-"
                                disabled
                              ></Select>
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="pinCode"
                              label="PinCode"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "PinCode is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="PinCode of NGO"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="email"
                              label="Email"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "Email is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Email of NGO"
                                disabled
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="phone"
                              label="Phone"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "contact number is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                addonBefore="IN(+91)"
                                size="medium"
                                disabled
                                placeholder="Contact number of NGO"
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="website"
                              label="Website"
                            >
                              <Input size="medium" placeholder="Website URL" />
                            </Form.Item>
                          </div>

                          <div className="upload">
                            <Form.Item className="" name="logo" label="Logo">
                              {islogoEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(logoFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          <div className="upload">
                            <Form.Item
                              className=""
                              name="micrositeImages"
                              label=" Images for your website"
                            >
                              {ismicroSiteEmpty1 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename1)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {ismicroSiteEmpty2 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm mx-3"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm mx-3"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename2)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {ismicroSiteEmpty3 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename3)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className=""
                              name="facebook"
                              label="Facebook"
                            >
                              <Input
                                addonBefore={<FacebookOutlined />}
                                placeholder="FaceBook ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="instagram"
                              label="Instagram"
                            >
                              <Input
                                addonBefore={<InstagramOutlined />}
                                placeholder="Instagram ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="linkedin"
                              label="Linkedin"
                            >
                              <Input
                                addonBefore={<LinkedinOutlined />}
                                placeholder="LinkedIn ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="twitter"
                              label="Twitter"
                            >
                              <Input
                                addonBefore={<TwitterOutlined />}
                                placeholder="Twitter ID"
                              />
                            </Form.Item>
                          </div>
                          {/* <div>
                            <Form.Item
                              className=""
                              name="otherlinks"
                              label="Other links"
                            >
                              <Input
                                addonBefore={<LinkOutlined />}
                                placeholder="Other Links(Optional)"
                              />
                            </Form.Item>
                          </div> */}

                          <div className="row mb-3">
                            {/* *************************ngoCoordinator********************** */}
                            <legend className="para b themeCol-orange mx-4">
                              NGO Coordinator Information:{" "}
                            </legend>

                            <div className="mt-4">
                              <Form.Item
                                name="coordinatorName"
                                label="Name"
                                rules={
                                  [
                                    // {
                                    //   required: true,
                                    //   message: "Coordinator name is required",
                                    // },
                                  ]
                                }
                              >
                                <Input
                                  size="medium"
                                  placeholder="NGO Coordinator Name"
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="coordinatorPhone"
                                label="Mobile"
                                rules={
                                  [
                                    // {
                                    //   required: true,
                                    //   message:
                                    //     "Coordinator phone number is required",
                                    // },
                                  ]
                                }
                              >
                                <Input
                                  size="medium"
                                  placeholder="NGO Coordinator Mobile Numner"
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="coordinatorEmail"
                                label="Email"
                                rules={
                                  [
                                    // {
                                    //   required: true,
                                    //   message: "Coordinator email is required",
                                    // },
                                    // {
                                    //   type: "email",
                                    //   message: "Enter valid email",
                                    // },
                                  ]
                                }
                              >
                                <Input
                                  size="medium"
                                  placeholder="NGO Coordinator Email"
                                  disabled
                                />
                              </Form.Item>
                            </div>

                            {/* **********genenral info*********** */}
                            <legend className="para b themeCol-orange mx-4">
                              General NGO Information:{" "}
                            </legend>
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="about"
                                label="About"
                                //colon={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "About is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  showCount
                                  maxLength={250}
                                  style={{ height: 150, resize: "none" }}
                                  placeholder="About the NGO (In 250 words)"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="history"
                                label="History"
                                //colon={false}
                              >
                                <Input.TextArea
                                  showCount
                                  maxLength={250}
                                  style={{ height: 150, resize: "none" }}
                                  placeholder=" Brief History of the NGO (In 250 words)"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="vision"
                                label="  Vision"
                                rules={[
                                  {
                                    required: true,
                                    message: " Vision is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  showCount
                                  maxLength={150}
                                  style={{ height: 130, resize: "none" }}
                                  placeholder="  Vision of the NGO (In 150 words)"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item name="mission" label="  Mission">
                                <Input.TextArea
                                  showCount
                                  maxLength={100}
                                  style={{ height: 130, resize: "none" }}
                                  placeholder="  Mission of the NGO (In 100 words)"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="geoFocus"
                                label="Geography"
                              >
                                <Input
                                  showSearch
                                  disabled
                                  placeholder="Geographic Focus of the activity"
                                  optionFilterProp="children"
                                  options={[]}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                      <div className=" p-0">
                        {/* *****************Certificate***************** */}
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          <legend className="para b themeCol-orange mx-2">
                            Copy of below Certificates to be uploaded:{" "}
                          </legend>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="certIncorpRoc"
                              label="Incorporation from ROC"
                            >
                              {iscertIncorpRocEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(certIncorpRocFilename)
                                  }
                                >
                                  Preview
                                </Button>
                              )}{" "}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="moaAoa"
                              label="MOA / AOA"
                            >
                              {ismoaAoaEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(moaAoaFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="regTrustDeed"
                              label="  Registered Trust Deed"
                            >
                              {isregTrustDeedEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(regTrustDeedFilename)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          <div className="upload">
                            <Form.Item className="" name="pan" label="PAN">
                              {ispancardEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(pancardFilename)}
                                >
                                  Preview
                                </Button>
                              )}{" "}
                            </Form.Item>
                          </div>

                          <div className="upload">
                            <Form.Item className="" name="tan" label="TAN">
                              {istanEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(tanFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="cert12a"
                              label=" 12A Certificate"
                            >
                              {iscert12aEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(cert12aFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="cert80g"
                              label=" 80G Certificate"
                            >
                              {iscert80gEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(cert80gFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="csrForm1"
                              label=" CSR Form 1"
                            >
                              {iscsrForm1Empty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(csrForm1Filename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="certFcra"
                              label=" FCRA Reg"
                            >
                              {iscertFcraEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(certFcraFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          <legend className="para b themeCol-orange">
                            Only for available Years. Upload all 3 years
                            together.
                          </legend>

                          <div className="upload">
                            <Form.Item
                              className=""
                              name="itrForm"
                              label="  ITR of last three AYs"
                            >
                              {isitrFormEmpty1 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(itrFormFilename1)}
                                >
                                  Preview
                                </Button>
                              )}
                              {isitrFormEmpty2 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm mx-3"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm mx-3"
                                  onClick={() => callImageAPI(itrFormFilename2)}
                                >
                                  Preview
                                </Button>
                              )}
                              {isitrFormEmpty3 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(itrFormFilename3)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="form10B"
                              label=" Form 10B of last three AYs"
                            >
                              {isform10BEmpty1 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(form10BFilename1)}
                                >
                                  Preview
                                </Button>
                              )}
                              {isform10BEmpty2 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm mx-3"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm mx-3"
                                  onClick={() => callImageAPI(form10BFilename2)}
                                >
                                  Preview
                                </Button>
                              )}
                              {isform10BEmpty3 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(form10BFilename3)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item
                              className=""
                              name="fcraReturns"
                              label=" FCRA Returns of last three FYs"
                            >
                              {isfcraReturnsEmpty1 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(fcraReturnsFilename1)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {isfcraReturnsEmpty2 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm mx-3"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm mx-3"
                                  onClick={() =>
                                    callImageAPI(fcraReturnsFilename2)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {isfcraReturnsEmpty3 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(fcraReturnsFilename3)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          {/* ************Bank Details************ */}
                          <legend className="para b themeCol-orange">
                            Banking Information:{" "}
                          </legend>
                          <div className="mt-4">
                            <Form.Item
                              name="bankName"
                              label=" Bank Name"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: " Bank name is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your Bank Name"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountName"
                              label="Account Name"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: " Account  name is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your Account Name"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="branchName"
                              label="Branch Name"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: " Branch name is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your Branch Name"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountNumber"
                              label=" Account Number"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "  Account Number is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your  Account Number"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="ifscCode"
                              label="IFSC Code"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "IFSC Code is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your IFSC Code"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="upiCode"
                              label="UPI Code"
                              rules={
                                [
                                  // {
                                  //   required: true,
                                  //   message: "UPI Code is required",
                                  // },
                                ]
                              }
                            >
                              <Input
                                size="medium"
                                placeholder="Your UPI Code"
                                disabled
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      </div>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          Profile updated successfully and sent for admin approval.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default NGO_Profile;
