import http from "../../http-upload-common";
import https from "../../downloadfile-common";
import htps from "../../http-status-common";

class FileUploadService {
  create(url, formData) {
    return http.post(url, formData); //localhost:8000/api/mt_user(data)
  }

  update(url, formData) {
    return http.put(url, formData); //`/mt_user/${id}`
  }

  filedownload(url, formData) {
    return https.post(url, formData);
  }
  paymentstatus(url, formData) {
    return htps.post(url, formData);
  }
}
export default new FileUploadService();
