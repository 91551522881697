import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table, Modal, Button, Tooltip, Pagination } from "antd";
import Nodata from "../../assets/icons/Noaccount.png";
import $ from "jquery";
import UploadService from "../../service/file-upload.service";

function Admin_CorpList() {
  let viewdata = null;
  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
  } else {
    viewdata = null;
  }

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(6);
  const [chatpage, setChatPage] = useState(1);
  const [chatsize, setChatSize] = useState(20);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [chatDataSource, setChatDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tablelength = dataSource.length;

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const AdminCorpListformData = new FormData();
  const AdminCorpChatformData = new FormData();

  const handlePageChange = (page) => {
    setPage(page);
  };
  const chatcolumn = [
    {
      title: "Date",
      dataIndex: "interestRegDate",
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Message",
      dataIndex: "actionDesc",
    },
  ];
  const columns = [
    {
      title: "Corp ID",
      dataIndex: "corpId",
      width: 90,
    },
    {
      title: "NGO ID",
      dataIndex: "ngoId",
      width: 90,
    },
    {
      title: "Cause ID",
      dataIndex: "causeId",
      width: 90,
    },
    {
      title: "Comments",
      dataIndex: "corpComments",
      width: 120,
      render: (text) => (
        <Tooltip title={text}>
          {text.length > 20 ? `${text.substring(0, 20)}...` : text}
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "interestRegDate",
      width: 90,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
    },
    {
      title: "Action",
      key: "operation",
      width: 140,
      render: (record) => (
        <div>
          <Button onClick={() => viewchatHistory(record)}>Chat History</Button>
        </div>
      ),
    },
  ];
  const viewchatHistory = (record) => {
    AdminCorpChatformData.append("userCred", JSON.stringify(userCred));
    AdminCorpChatformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    AdminCorpChatformData.append(
      "corpRegisterInterest",
      JSON.stringify(record)
    );
    AdminCorpChatformData.append("page", JSON.stringify(chatpage - 1));
    AdminCorpChatformData.append("size", JSON.stringify(chatsize));
    UploadService.create(
      "admin/corp/interest/actions/get",
      AdminCorpChatformData
    ).then((response) => {
      setChatDataSource(response.data.data.corpRegisterInterestAction.content);
      setIsModalOpen(true);
    });
  };

  useEffect(() => {
    AdminCorpListformData.append("userCred", JSON.stringify(userCred));
    AdminCorpListformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    AdminCorpListformData.append(
      "corpRegisterInterest",
      JSON.stringify(viewdata.viewdata)
    );
    AdminCorpListformData.append("page", JSON.stringify(page - 1));
    AdminCorpListformData.append("size", JSON.stringify(size));

    UploadService.create(
      "admin/corp/interest/active/get",
      AdminCorpListformData
    ).then((response) => {

      setDataSource(response.data.data.corpRegisterInterest.content);
      setTotalElements((response.data.data.corpRegisterInterest.totalElements))

    });
  }, [page]);

  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Intrested NGO List:</h6>
          </div>
          <section className="content">
            {tablelength > 0 && (
              <div className="row">
                <div className="mt-2">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    style={{
                      padding: "5px",
                    }}
                    onChange={handlePageChange}
                    bordered
                    pagination={false}
                    className="custom-table"
                    scroll={{
                      x: 1000,
                    }}
                  />
                  <Pagination
                    Current={page}
                    total={totalElements}
                    pageSize={size}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            )}
            {tablelength == 0 && (
              <div
                className="d-flex justify-content-center"
                style={{ height: "80vh" }}
              >
                <img src={Nodata} alt="Nodata" className="alignimagecenter" />
              </div>
            )}
          </section>
        </div>
      </>

      <Modal
        open={isModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Table
          columns={chatcolumn}
          dataSource={chatDataSource}
          style={{
            padding: "5px",
          }}
          onChange={handlePageChange}
          bordered
          pagination={false}
          className="custom-table mt-3"
          scroll={{
            x: 100,
          }}
        />
      </Modal>
    </>
  );
}

export default Admin_CorpList;
