import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";

import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Modal,
  Tooltip,
  message,
} from "antd";
import {
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import $ from "jquery";
function Admin_ViewProfile(props) {
  const navigate = useNavigate();
  const [contentType, setcontentType] = useState();
  const [fileName, setfileName] = useState();

  let viewdata = null;
  let selectedprofile = null;
  let orderdetails = null;

  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
    selectedprofile = state;
    orderdetails = state.orderdetails;
  } else {
    viewdata = null;
    selectedprofile = null;
    orderdetails = null;
  }
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const ApproveprofileformData = new FormData();

  const approveProfile = () => {
    Modal.confirm({
      title: "Are you sure,You want to Approve this Profile?",
      okText: "Approve",
      onOk: () => {
        ApproveprofileformData.append("userCred", JSON.stringify(userCred));
        ApproveprofileformData.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );
        let approveProfile = {
          id: orderdetails.id,
          ngoId: orderdetails.ngoId,
          userId: orderdetails.userId,
          orderId: orderdetails.orderId,
          adminId: userCred.userId,
          status: orderdetails.status,
          comments: "",
          createdTime: orderdetails.createdTime,
          modifiedTime: orderdetails.modifiedTime,
          communication: orderdetails.communication,
          currentMembershipLevel: orderdetails.currentMembershipLevel,
          requestedMembershipLevel: orderdetails.requestedMembershipLevel,
          paymentStatus: orderdetails.paymentStatus,
        };
        ApproveprofileformData.append(
          "ngoAccountUpgradeApproval",
          JSON.stringify(approveProfile)
        );

        UploadService.create(
          "/admin/ngo/account/decision/approve",
          ApproveprofileformData
        ).then((response) => {
          Modal.confirm({
            title: "Account approved Sucessfully",
            onOk: () => {
              navigate("/Admin_NGO");
            },
          });
        });
      },
    });
  };
  const RejectprofileformData = new FormData();

  const rejectProfile = () => {
    Modal.confirm({
      title: "Are you sure,You want to Reject this Profile?",
      okText: "Reject",
      onOk: () => {
        RejectprofileformData.append("userCred", JSON.stringify(userCred));
        RejectprofileformData.append(
          "userRoleAuthMapping",
          JSON.stringify(userRoleAuthMapping)
        );
        let rejectProfile = {
          id: orderdetails.id,
          ngoId: orderdetails.ngoId,
          userId: orderdetails.userId,
          orderId: orderdetails.orderId,
          adminId: userCred.userId,
          status: orderdetails.status,
          comments: "",
          createdTime: orderdetails.createdTime,
          modifiedTime: orderdetails.modifiedTime,
          communication: orderdetails.communication,
          currentMembershipLevel: orderdetails.currentMembershipLevel,
          requestedMembershipLevel: orderdetails.requestedMembershipLevel,
          paymentStatus: orderdetails.paymentStatus,
        };
        RejectprofileformData.append(
          "ngoAccountUpgradeApproval",
          JSON.stringify(rejectProfile)
        );

        UploadService.create(
          "admin/ngo/account/decision/reject",
          RejectprofileformData
        ).then((response) => {
          Modal.confirm({
            title: "Account Rejected Sucessfully",
            onOk: () => {
              navigate("/Admin_NGO");
            },
          });
        });
      },
    });
  };

  const callImageAPI = (filename) => {
    setfileName(filename);
    const profileimageformData = new FormData();
    profileimageformData.append("userCred", JSON.stringify(userCred));
    profileimageformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    profileimageformData.append(
      "ngoProfile",
      JSON.stringify(viewdata.viewdata.ngoProfile)
    );
    profileimageformData.append("imageName", filename);
    UploadService.filedownload(
      "/admin/ngo/profile/image/get",
      profileimageformData
    )
      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    } else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }
  const [logoFilename, setlogoFilename] = useState("");
  const [cert12aFilename, setcert12aFilename] = useState("");

  const [microSiteFilename1, setmicroSiteFilename1] = useState("");
  const [microSiteFilename2, setmicroSiteFilename2] = useState("");
  const [microSiteFilename3, setmicroSiteFilename3] = useState("");

  const [certIncorpRocFilename, setcertIncorpRocFilename] = useState("");
  const [pancardFilename, setpancardFilename] = useState("");
  const [regTrustDeedFilename, setregTrustDeedFilename] = useState("");
  const [moaAoaFilename, setmoaAoaFilename] = useState("");
  const [tanFilename, settanFilename] = useState("");
  const [csrForm1Filename, setcsrForm1Filename] = useState("");
  const [cert80gFilename, setcert80gFilename] = useState("");
  const [certFcraFilename, setcertFcraFilename] = useState("");

  const [itrFormFilename1, setitrFormFilename1] = useState("");
  const [itrFormFilename2, setitrFormFilename2] = useState("");
  const [itrFormFilename3, setitrFormFilename3] = useState("");

  const [fcraReturnsFilename1, setfcraReturnsFilename1] = useState("");
  const [fcraReturnsFilename2, setfcraReturnsFilename2] = useState("");
  const [fcraReturnsFilename3, setfcraReturnsFilename3] = useState("");

  const [form10BFilename1, setform10BFilename1] = useState("");
  const [form10BFilename2, setform10BFilename2] = useState("");
  const [form10BFilename3, setform10BFilename3] = useState("");

  const islogoEmpty = logoFilename.trim().length === 0;
  const iscert12aEmpty = cert12aFilename.trim().length === 0;

  const ismicroSiteEmpty1 = microSiteFilename1.trim().length === 0;
  const ismicroSiteEmpty2 = microSiteFilename2.trim().length === 0;
  const ismicroSiteEmpty3 = microSiteFilename3.trim().length === 0;

  const iscertIncorpRocEmpty = certIncorpRocFilename.trim().length === 0;
  const ispancardEmpty = pancardFilename.trim().length === 0;
  const isregTrustDeedEmpty = regTrustDeedFilename.trim().length === 0;
  const ismoaAoaEmpty = moaAoaFilename.trim().length === 0;
  const istanEmpty = tanFilename.trim().length === 0;
  const iscsrForm1Empty = csrForm1Filename.trim().length === 0;
  const iscert80gEmpty = cert80gFilename.trim().length === 0;
  const iscertFcraEmpty = certFcraFilename.trim().length === 0;

  const isitrFormEmpty1 = itrFormFilename1.trim().length === 0;
  const isitrFormEmpty2 = itrFormFilename2.trim().length === 0;
  const isitrFormEmpty3 = itrFormFilename3.trim().length === 0;

  const isform10BEmpty1 = form10BFilename1.trim().length === 0;
  const isform10BEmpty2 = form10BFilename2.trim().length === 0;
  const isform10BEmpty3 = form10BFilename3.trim().length === 0;

  const isfcraReturnsEmpty1 = fcraReturnsFilename1.trim().length === 0;
  const isfcraReturnsEmpty2 = fcraReturnsFilename2.trim().length === 0;
  const isfcraReturnsEmpty3 = fcraReturnsFilename3.trim().length === 0;

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: viewdata.viewdata.ngoProfile.name,
      category: viewdata.viewdata.ngoProfile.category,
      about: viewdata.viewdata.ngoProfile.about,
      history: viewdata.viewdata.ngoProfile.history,
      vision: viewdata.viewdata.ngoProfile.vision,
      mission: viewdata.viewdata.ngoProfile.mission,
      geoFocus: viewdata.viewdata.ngoProfile.geoFocus,
      staticWebPageLink: "",
      certIncorpRoc: "",
      moaAoa: "",
      regTrustDeed: "",
      pan: "",
      tan: "",
      csrForm1: "",
      cert12a: "",
      cert80g: "",
      certFcra: "",
      itrForm: "",
      form10B: "",
      fcraReturns: "",
      logo: "",
      micrositeImages: "",

      addressLine1: viewdata.viewdata.contactInfo.addressLine1,
      city: viewdata.viewdata.contactInfo.city,
      state: viewdata.viewdata.contactInfo.state,
      country: viewdata.viewdata.contactInfo.country,
      pinCode: viewdata.viewdata.contactInfo.pinCode,
      email: viewdata.viewdata.contactInfo.email,
      phone: viewdata.viewdata.contactInfo.phone,
      website: viewdata.viewdata.contactInfo.website,
      facebook: viewdata.viewdata.contactInfo.facebook,
      instagram: viewdata.viewdata.contactInfo.instagram,
      linkedin: viewdata.viewdata.contactInfo.linkedin,
      twitter: viewdata.viewdata.contactInfo.twitter,
      otherlinks: viewdata.viewdata.contactInfo.otherlinks,

      coordinatorName: viewdata.viewdata.coordinator.coordinatorName,
      coordinatorEmail: viewdata.viewdata.coordinator.coordinatorEmail,
      coordinatorPhone: viewdata.viewdata.coordinator.coordinatorPhone,

      bankName: viewdata.viewdata.bankDetails.bankName,
      branchName: viewdata.viewdata.bankDetails.branchName,
      accountName: viewdata.viewdata.bankDetails.accountName,
      accountNumber: viewdata.viewdata.bankDetails.accountNumber,
      ifscCode: viewdata.viewdata.bankDetails.ifscCode,
      upiCode: viewdata.viewdata.bankDetails.upiCode,
    });

    setcertIncorpRocFilename(viewdata.viewdata.ngoProfile.certIncorpRoc);
    setmoaAoaFilename(viewdata.viewdata.ngoProfile.moaAoa);
    setpancardFilename(viewdata.viewdata.ngoProfile.pan);
    setlogoFilename(viewdata.viewdata.ngoProfile.logo);
    setcert12aFilename(viewdata.viewdata.ngoProfile.cert12a);

    const micrositeimageNames = viewdata.viewdata.ngoProfile.micrositeImages;
    const micrositeindividualimage = micrositeimageNames.split(",");
    if (micrositeindividualimage[0]) {
      setmicroSiteFilename1(micrositeindividualimage[0]);
    }
    if (micrositeindividualimage[1]) {
      setmicroSiteFilename2(micrositeindividualimage[1]);
    }
    if (micrositeindividualimage[2]) {
      setmicroSiteFilename3(micrositeindividualimage[2]);
    }

    setregTrustDeedFilename(viewdata.viewdata.ngoProfile.regTrustDeed);
    setmoaAoaFilename(viewdata.viewdata.ngoProfile.moaAoa);
    setcertFcraFilename(viewdata.viewdata.ngoProfile.certFcra);

    const itrFormimageNames = viewdata.viewdata.ngoProfile.itrForm;
    const itrformindividualimage = itrFormimageNames.split(",");
    if (itrformindividualimage[0]) {
      setitrFormFilename1(itrformindividualimage[0]);
    }
    if (itrformindividualimage[1]) {
      setitrFormFilename2(itrformindividualimage[1]);
    }
    if (itrformindividualimage[2]) {
      setitrFormFilename3(itrformindividualimage[2]);
    }

    settanFilename(viewdata.viewdata.ngoProfile.tan);
    setcert80gFilename(viewdata.viewdata.ngoProfile.cert80g);
    setcsrForm1Filename(viewdata.viewdata.ngoProfile.csrForm1);

    const fcraNames = viewdata.viewdata.ngoProfile.fcraReturns;
    const fcraindividualimage = fcraNames.split(",");
    if (fcraindividualimage[0]) {
      setfcraReturnsFilename1(fcraindividualimage[0]);
    }
    if (fcraindividualimage[1]) {
      setfcraReturnsFilename2(fcraindividualimage[1]);
    }
    if (fcraindividualimage[2]) {
      setfcraReturnsFilename3(fcraindividualimage[2]);
    }

    const form10BNames = viewdata.viewdata.ngoProfile.form10B;
    const form10Bindividualimage = form10BNames.split(",");
    if (form10Bindividualimage[0]) {
      setform10BFilename1(form10Bindividualimage[0]);
    }
    if (form10Bindividualimage[1]) {
      setform10BFilename2(form10Bindividualimage[1]);
    }
    if (form10Bindividualimage[2]) {
      setform10BFilename3(form10Bindividualimage[2]);
    }
  });

  return (
    <>
      
      <div className="col-md-12 row fontfamilynca">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 row mb-2">
                <div className="col">
                  <NavLink to="/Admin_NGO">
                    <button className="btnnca btn-info btn-sm">
                      <i className="fa fa-arrow-left"></i> Back To Profile
                    </button>
                  </NavLink>
                </div>
              </div>
              {/* <div className="col-sm-6">
                  <h1 className="m-0">Profile Details</h1>
                </div> */}
            </div>
          </div>
        </div>

        <Form
          //layout="vertical"
          // onFinish={onsubmit}
          // onFinishFailed={showerror}
          //readOnly={true}={}
          form={form}
          scrollToFirstError={true}
          name="validateOnly"
          labelCol={{ span: 9 }}
          //autoComplete="off"
        >
          <div className="ms-3 me-3">
            <div className="col-md-12 glasscard p-3">
              <div className="row">
                <div
                  className="col-md-6"
                  style={{ borderRight: "1px solid #f3f3f3" }}
                >
                  <fieldset>
                    <div className=" p-0">
                      <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                        {/* Name of NGO*/}
                        <legend className="para b themeCol-orange mx-3">
                          NGO Information:{" "}
                        </legend>
                        <div className="row mb-3">
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="name"
                              label="NGO Name"
                              values={"NGO"}
                              //colon={false}
                            >
                              <Input
                                size="medium"
                                placeholder="Name of  the NGO"
                                readOnly={true}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="phone"
                              label="Contact No"
                              values={"NGO"}
                              //colon={false}
                            >
                              <Input
                                size="medium"
                                type="number"
                                placeholder="Contact Number"
                                readOnly={true}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="addressLine1"
                              label="Registered Address"
                              //colon={false}
                            >
                              <Input.TextArea
                                //showCount
                                style={{ height: 100, resize: "none" }}
                                placeholder="Registered Address"
                                readOnly={true}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="city"
                              label="City"
                            >
                              <Select
                                placeholder="-Select City-"
                                readOnly={true}
                              ></Select>
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="state"
                              label="State"
                            >
                              <Select
                                placeholder="-Select State-"
                                readOnly={true}
                              ></Select>
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="country"
                              label="Country"
                            >
                              <Select
                                placeholder="-Select Country-"
                                readOnly={true}
                              ></Select>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="category"
                              label="Category"
                            >
                              <Select
                                readOnly={true}
                                mode="multiple"
                                placeholder="--NGO Category--"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="website"
                              label="Website"
                              values={"NGO"}
                            >
                              <Input
                                readOnly={true}
                                size="medium"
                                placeholder="Website if you already have."
                              />
                            </Form.Item>
                          </div>
                          <div className="upload">
                            <Form.Item className="" name="logo" label="Logo">
                              {islogoEmpty ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() => callImageAPI(logoFilename)}
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>

                          <div className="upload">
                            <Form.Item
                              className=""
                              name="micrositeImages"
                              label=" Images for your website"
                            >
                              {ismicroSiteEmpty1 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename1)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {ismicroSiteEmpty2 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm mx-3"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm mx-3"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename2)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                              {ismicroSiteEmpty3 ? (
                                <Button
                                  type="button"
                                  className="btnnca btn-pink btn-sm"
                                >
                                  No File
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btnnca btn-gold btn-sm"
                                  onClick={() =>
                                    callImageAPI(microSiteFilename3)
                                  }
                                >
                                  Preview
                                </Button>
                              )}
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="facebook"
                              label="Facebook"
                            >
                              <Input
                                readOnly={true}
                                addonBefore={<FacebookOutlined />}
                                placeholder="FaceBook ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="instagram"
                              label="Instagram"
                            >
                              <Input
                                readOnly={true}
                                addonBefore={<InstagramOutlined />}
                                placeholder="Instagram ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="linkedin"
                              label="Linkedin"
                            >
                              <Input
                                readOnly={true}
                                addonBefore={<LinkedinOutlined />}
                                placeholder="LinkedIn ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="twitter"
                              label="Twitter"
                            >
                              <Input
                                readOnly={true}
                                addonBefore={<TwitterOutlined />}
                                placeholder="Twitter ID"
                              />
                            </Form.Item>
                          </div>
                          {/* Dont use this for now
                             <div>
                              <Form.Item
                                className=""
                                name={["contactInfo", "otherlinks"]}
                                label="Other links"
                              >
                                <Input
                                  addonBefore={<LinkOutlined />}
                                  placeholder="Other Links(Optional)"
                                />
                              </Form.Item>
                            </div> */}
                          {/* *************************ngoCoordinator********************** */}
                          <legend className="para b themeCol-orange mx-4">
                            NGO Coordinator Information:{" "}
                          </legend>
                          <div>
                            <Form.Item name="coordinatorName" label="Name">
                              <Input
                                readOnly={true}
                                size="medium"
                                placeholder="NGO CoordinatoselectedItemsr Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="coordinatorPhone" label="Mobile">
                              <Input
                                readOnly={true}
                                size="medium"
                                type="number"
                                placeholder="NGO Coordinator Mobile Numner"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="coordinatorEmail" label="Email">
                              <Input
                                readOnly={true}
                                size="medium"
                                placeholder="NGO Coordinator Email"
                              />
                            </Form.Item>
                          </div>
                          {/* **********genenral info*********** */}
                          <legend className="para b themeCol-orange mx-4">
                            General NGO Information:{" "}
                          </legend>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="about"
                              label="About"
                              //colon={false}
                            >
                              <Input.TextArea
                                readOnly={true}
                                showCount
                                maxLength={250}
                                style={{ height: 150, resize: "none" }}
                                placeholder="About the NGO (In 250 words)"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="history"
                              label="Brief History"
                              //colon={false}
                            >
                              <Input.TextArea
                                readOnly={true}
                                showCount
                                maxLength={250}
                                style={{ height: 150, resize: "none" }}
                                placeholder=" Brief History of the NGO (In 250 words)"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="vision" label="  Vision">
                              <Input.TextArea
                                readOnly={true}
                                showCount
                                maxLength={150}
                                style={{ height: 130, resize: "none" }}
                                placeholder="  Vision of the NGO (In 150 words)"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="mission" label="  Mission">
                              <Input.TextArea
                                readOnly={true}
                                showCount
                                maxLength={100}
                                style={{ height: 130, resize: "none" }}
                                placeholder="  Mission of the NGO (In 100 words)"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className="para b themeCol-orange"
                              name="geoFocus"
                              label="Geography"
                            >
                              <Input
                                readOnly={true}
                                size="medium"
                                placeholder="Geographic Focus of the activity"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-md-6">
                  <fieldset>
                    <div className=" p-0">
                      {/* *****************Certificate***************** */}
                      <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                        <legend className="para b themeCol-orange mx-2">
                          Copy of below Certificates to be uploaded:{" "}
                        </legend>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="certIncorpRoc"
                            label="Incorporation from ROC"
                          >
                            {iscertIncorpRocEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() =>
                                  callImageAPI(certIncorpRocFilename)
                                }
                              >
                                Preview
                              </Button>
                            )}{" "}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="moaAoa"
                            label="MOA / AOA"
                          >
                            {ismoaAoaEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(moaAoaFilename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="regTrustDeed"
                            label="  Registered Trust Deed"
                          >
                            {isregTrustDeedEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() =>
                                  callImageAPI(regTrustDeedFilename)
                                }
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>

                        <div className="upload">
                          <Form.Item className="" name="pan" label="PAN">
                            {ispancardEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(pancardFilename)}
                              >
                                Preview
                              </Button>
                            )}{" "}
                          </Form.Item>
                        </div>

                        <div className="upload">
                          <Form.Item className="" name="tan" label="TAN">
                            {istanEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(tanFilename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="cert12a"
                            label=" 12A Certificate"
                          >
                            {iscert12aEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(cert12aFilename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="cert80g"
                            label=" 80G Certificate"
                          >
                            {iscert80gEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(cert80gFilename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="csrForm1"
                            label=" CSR Form 1"
                          >
                            {iscsrForm1Empty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(csrForm1Filename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="certFcra"
                            label=" FCRA Reg"
                          >
                            {iscertFcraEmpty ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(certFcraFilename)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <legend className="para b themeCol-orange">
                          Only for available Years. Upload all 3 years together.
                        </legend>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="itrForm"
                            label="  ITR of last three AYs"
                          >
                            {isitrFormEmpty1 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(itrFormFilename1)}
                              >
                                Preview
                              </Button>
                            )}
                            {isitrFormEmpty2 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm mx-3"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm mx-3"
                                onClick={() => callImageAPI(itrFormFilename2)}
                              >
                                Preview
                              </Button>
                            )}
                            {isitrFormEmpty3 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(itrFormFilename3)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="form10B"
                            label=" Form 10B of last three AYs"
                          >
                            {isform10BEmpty1 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(form10BFilename1)}
                              >
                                Preview
                              </Button>
                            )}
                            {isform10BEmpty2 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm mx-3"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm mx-3"
                                onClick={() => callImageAPI(form10BFilename2)}
                              >
                                Preview
                              </Button>
                            )}
                            {isform10BEmpty3 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() => callImageAPI(form10BFilename3)}
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        <div className="upload">
                          <Form.Item
                            className=""
                            name="fcraReturns"
                            label=" FCRA Returns of last three FYs"
                          >
                            {isfcraReturnsEmpty1 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() =>
                                  callImageAPI(fcraReturnsFilename1)
                                }
                              >
                                Preview
                              </Button>
                            )}
                            {isfcraReturnsEmpty2 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm mx-3"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm mx-3"
                                onClick={() =>
                                  callImageAPI(fcraReturnsFilename2)
                                }
                              >
                                Preview
                              </Button>
                            )}
                            {isfcraReturnsEmpty3 ? (
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                              >
                                No File
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="btnnca btn-gold btn-sm"
                                onClick={() =>
                                  callImageAPI(fcraReturnsFilename3)
                                }
                              >
                                Preview
                              </Button>
                            )}
                          </Form.Item>
                        </div>
                        {/* ************Bank Details************ */}
                        <legend className="para b themeCol-orange">
                          Banking Information:{" "}
                        </legend>
                        <div>
                          <Form.Item name="bankName" label=" Bank Name">
                            <Input
                              size="medium"
                              placeholder="Your Bank Name"
                              readOnly={true}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item name="accountName" label="Account Name">
                            <Input
                              readOnly={true}
                              size="medium"
                              placeholder="Your Account Name"
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item name="branchName" label="Branch Name">
                            <Input
                              readOnly={true}
                              size="medium"
                              placeholder="Your Branch Name"
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            name="accountNumber"
                            label=" Account Number"
                          >
                            <Input
                              readOnly={true}
                              size="medium"
                              type="number"
                              placeholder="Your  Account Number"
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item name="ifscCode" label="IFSC Code">
                            <Input
                              size="medium"
                              placeholder="Your IFSC Code"
                              readOnly={true}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item name="upiCode" label=" UPI Code">
                            <Input
                              size="medium"
                              placeholder="Your  UPI Code"
                              readOnly={true}
                            />
                          </Form.Item>
                        </div>

                        <div className="row">
                          <div className="row">
                            <div className="d-flex justify-content-center">
                              <Button
                                type="button"
                                className="btnnca btn-pink btn-sm"
                                onClick={approveProfile}
                              >
                                Approve Profile
                              </Button>

                              <Button
                                type="button"
                                className="btnnca btn-danger signupbtn ms-3"
                                onClick={rejectProfile}
                              >
                                Reject Profile
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Admin_ViewProfile;
