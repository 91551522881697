import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import UploadService from "../../service/file-upload.service";
import { State, City } from "country-state-city";
import moment from "moment";

import {
  Form,
  Table,
  Input,
  Button,
  message,
  Pagination,
  Select,
  Upload,
  Modal,
  Tabs,
  DatePicker,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
const { Option } = Select;

const category = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function CauseAdditionalinfo() {
  const location = useLocation();

  const [userCred, setUserCred] = useState();
  const [userRoleAuthMapping, setUserRoleAuthMapping] = useState();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalElements, setTotalElements] = useState();

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handlePageChange = (page) => {
    setPage(page);
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedCause, setSelectedCause] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filteredOptions = category.filter((o) => !selectedItems.includes(o));

 

  const handleOk = () => {
    navigate("/");
  };
 
  const onsubmit = (values) => {
    const CauseaddinfoformData = new FormData();
    let images = [];
    let supportiveDocument = [];
    if (values.galleryimage0 != undefined) {
      images.push(values.galleryimage0.file.name);
    }
    if (values.galleryimage1 != undefined) {
      images.push(values.galleryimage1.file.name);
    }
    if (values.galleryimage2 != undefined) {
      images.push(values.galleryimage2.file.name);
    }
    if (values.Supportivedocument1 != undefined) {
      supportiveDocument.push(values.Supportivedocument1.file.name);
    }
    if (values.Supportivedocument2 != undefined) {
      supportiveDocument.push(values.Supportivedocument2.file.name);
    }

    let cause = {
      entityId: selectedCause.entityId,
      causeId: selectedCause.causeId,
      version: selectedCause.version,
      title: values.title,
      description: values.description,
      category: values.category.toString(),
      goalType: values.goalType,
      goalAmount: Number(values.goalAmount),
      nonFundGoalDesc: "",
      receivedAmount: 0,
      nonFundGoalStatus: "",
      startDate: values.startDate,
      endDate: values.endDate,
      city: values.city,
      state: values.state,
      country: "India",
      status: "",
      causeLink: "",
      images: images.toString(),
      supportiveDocs: supportiveDocument.toString(),
      comments: "",
      createdBy: "",
      updatedBy: "",
      createdDate: "",
      updatedDate: "",
    };


    CauseaddinfoformData.append("cause", JSON.stringify(cause));
    CauseaddinfoformData.append("userCred", JSON.stringify(userCred));
    CauseaddinfoformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );

    for (let i = 0; i <= 2; i++) {
      const key = `galleryimage${i}`;
      const galleryFile = values[key]?.file?.originFileObj;

      if (galleryFile) {
        CauseaddinfoformData.append("images", galleryFile);
      }
    }

    for (let i = 1; i <= 2; i++) {
      const key = `Supportivedocument${i}`;
      const supportiveDocumentFile = values[key]?.file?.originFileObj;

      if (supportiveDocumentFile) {
        CauseaddinfoformData.append("supportiveDocs", supportiveDocumentFile);
      }
    }

    UploadService.create(
      "/ngo/cause/update/additionalInfo",
      CauseaddinfoformData
    ).then((response) => {
      let logSts = response.data.message.messageType;

      if (logSts == "Success") {
        setIsModalOpen(true);
      } else {
        window.toastr.warning(
          response.data.message.messageDetails[0].responseMessage
        );
      }
    });
  };

  const callImageAPI = (filename) => {
    const addcauseimageformData = new FormData();
    addcauseimageformData.append("userCred", JSON.stringify(userCred));
    addcauseimageformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    addcauseimageformData.append("cause", JSON.stringify(selectedCause));
    addcauseimageformData.append("fileName", filename);

    UploadService.filedownload("ngo/cause/getCauseImage", addcauseimageformData)
      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }
  const [image1name, setimage1name] = useState("");
  const [image2name, setimage2name] = useState("");
  const [image3name, setimage3name] = useState("");
  const [supportiveDoc1name, setsupportiveDoc1name] = useState("");
  const [supportiveDoc2name, setsupportiveDoc2name] = useState("");

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get("token");
    const eToken = queryParams.get("eToken");
    const causeId = queryParams.get("causeId");
    const userAuthTracker = {
      id: 1,
      userId: "",
      email: "",
      uniqueToken: token,
      emailToken: eToken,
      authType: "CauseCreationNeedAdditionalInfo",
      authMode: "EMAIL_LINK",
      timeNow: null,
      expiryTime: 0,
      modifiedTime: null,
      status: "",
      count: 0,
    };

    const CauseAdditionalinfoformData = new FormData();
    CauseAdditionalinfoformData.append(
      "userAuthTracker",
      JSON.stringify(userAuthTracker)
    );
    CauseAdditionalinfoformData.append("causeId", causeId);

   
    UploadService.create(
      "ngo/cause/verifyEmailTokens/get/cause",
      CauseAdditionalinfoformData
    ).then((response) => {
      setUserCred(response.data.data.userCred);
      setUserRoleAuthMapping(response.data.data.userRoleAuthMapping);
      setSelectedCause(response.data.data.cause);
      const startDate = dayjs(new Date(response.data.data.cause.startDate));
      const endDate = dayjs(new Date(response.data.data.cause.endDate));
      form.setFieldsValue({
        category: response.data.data.cause.category,
        title: response.data.data.cause.title,
        description: response.data.data.cause.description,
        goalType: response.data.data.cause.goalType,
        goalAmount: response.data.data.cause.goalAmount,
        city: response.data.data.cause.city,
        state: response.data.data.cause.state,
        country: response.data.data.cause.country,
        startDate: startDate,
        endDate: endDate,
      });

      const imageNames = response.data.data.cause.images;
      const individualimage = imageNames.split(",");
      setimage1name(individualimage[0]);
      setimage2name(individualimage[1]);
      setimage3name(individualimage[2]);

      const supporitivedocsNames = response.data.data.cause.supportiveDocs;
      const individualsupporitivedocs = supporitivedocsNames.split(",");
      setsupportiveDoc1name(individualsupporitivedocs[0]);
      setsupportiveDoc2name(individualsupporitivedocs[1]);
    });
  }, [location.search]);

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    const startDate = form.getFieldValue("startDate");
    return (
      current &&
      (current < moment(startDate).startOf("day") ||
        current < moment().startOf("day"))
    );
  };

  const validateEndDate = (_, value) => {
    const startDate = form.getFieldValue("startDate");
    if (value && startDate && value.isBefore(startDate)) {
      return Promise.reject(new Error("End date must be after start date"));
    }
    return Promise.resolve();
  };
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" }); // Clear selected city when state changes
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  return (
    <>
     
      <div className="col-md-12 row fontfamilynca">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-8 row"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-8">
                <h5 className="m-3 tableheading">&nbsp; Addinfo-Cause </h5>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="glasscard">
              <Form
                autoComplete="off"
                form={form}
                onFinish={onsubmit}
                labelCol={{ span: 4 }}
                wrapperCol={{
                  span: 17,
                }}
              >
                <legend className="para b themeCol-orange mx-3 mt-2">
                  Cause Details:{" "}
                </legend>
                <div className="mt-4">
                  <Form.Item
                    className="para b themeCol-orange"
                    name="category"
                    label="Category"
                  >
                    <Select
                      mode="multiple"
                      placeholder="--NGO Category--"
                      value={selectedItems}
                      onChange={setSelectedItems}
                      options={filteredOptions.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className="para b themeCol-orange"
                    name="title"
                    label="Cause Title"
                  >
                    <Input size="medium" placeholder="Enter Cause Title" />
                  </Form.Item>
                </div>

                <div className="upload">
                  <Form.Item
                    className=""
                    name="galleryimage0"
                    label="Campaign Image"
                  >
                    <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          block
                          icon={<UploadOutlined />}
                          className="me-3"
                        >
                          Upload
                        </Button>
                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm"
                          onClick={() => callImageAPI("image1name")}
                        >
                          Preview
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
                <legend className="para b themeCol-orange mx-3 mt-2">
                  Goal Details:{" "}
                </legend>
                <div>
                  <Form.Item
                    className="para b themeCol-orange"
                    name="goalType"
                    label="Goal Type"
                  >
                    <Select
                      placeholder="-Select Goal-"
                      options={[
                        {
                          value: "Funds",
                          label: "Funds",
                        },
                        {
                          value: "Transport",
                          label: "Transport",
                        },
                        {
                          value: "Food",
                          label: "Food",
                        },
                        {
                          value: "Facility",
                          label: "Facility",
                        },
                        {
                          value: "Clothes",
                          label: "Clothes",
                        },
                        {
                          value: "Others",
                          label: "Others",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    name="goalAmount"
                    label="Goal Amount(₹)"
                    rules={[
                      {
                        required: true,
                        message: "Amout is required",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Amount In Rupees"
                      style={{ width: "300px" }}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item className="" name="startDate" label="Start Date">
                    <DatePicker disabledDate={disabledStartDate} />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    className=""
                    name="endDate"
                    label="End Date"
                    rules={[
                      {
                        required: true,
                        message: "End Duration required",
                      },
                      {
                        validator: validateEndDate,
                      },
                    ]}
                  >
                    <DatePicker disabledDate={disabledEndDate} />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    className="para b themeCol-orange"
                    name="description"
                    label="Cause Description"
                    rules={[
                      {
                        required: true,
                        message: "enter cause description",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={250}
                      style={{ height: 200, resize: "none" }}
                      placeholder="Enter Cause  Description(250 words)"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    className="para b themeCol-orange"
                    name="state"
                    label="State"
                  >
                    <Select
                      placeholder="Select State"
                      onChange={handleStateChange}
                      value={selectedState}
                      showSearch
                    >
                      {states.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    className="para b themeCol-orange"
                    name="city"
                    label="City"
                  >
                    <Select
                      placeholder="Select City"
                      onChange={handleCityChange}
                      showSearch
                      value={selectedCity}

                      // disabled={!selectedState}
                    >
                      {cities.map((city) => (
                        <Option key={city.value} value={city.value}>
                          {city.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <legend className="para b themeCol-orange mx-3">
                  Gallery:{" "}
                </legend>
                <div className="upload">
                  <Form.Item className="" name="galleryimage1" label="Image 1">
                    <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="me-3"
                          block
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>
                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm"
                          onClick={() => callImageAPI(image2name)}
                        >
                          Preview
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>

                <div className="upload">
                  <Form.Item className="" name="galleryimage2" label="Image 2">
                    <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="me-3"
                          block
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </Button>

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm"
                          onClick={() => callImageAPI(image3name)}
                        >
                          Preview
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>

                <legend className="para b themeCol-orange mx-3">
                  Supportive Document:{" "}
                </legend>
                <div className="upload">
                  <Form.Item
                    className="mb-2"
                    name="Supportivedocument1"
                    label="Supportive Document 1"
                  >
                    <Upload maxCount={1} accept=".jpeg,.png,.jpg,.pdf">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button block icon={<UploadOutlined />}>
                          Upload
                        </Button>

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm ms-4 mb-2"
                          onClick={() => callImageAPI(supportiveDoc1name)}
                        >
                          Preview
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>

                <div className="upload">
                  <Form.Item
                    className="mb-2"
                    name="Supportivedocument2"
                    label="Supportive Document 2"
                  >
                    <Upload maxCount={1} accept=".jpeg,.png,.jpg,.pdf">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button block icon={<UploadOutlined />}>
                          Upload
                        </Button>

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm ms-4 "
                          onClick={() => callImageAPI(supportiveDoc2name)}
                        >
                          Preview
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>

                <div className="d-flex justify-content-center">
                  <Button
                    htmlType="submit"
                    className="btnnca btn-gold signupbtn"
                  >
                    Submit for Review
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          Cause Updated successfully and sent for admin approval.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default CauseAdditionalinfo;
