import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import corp1 from "../../../imges/corp/29.png"
import corp2 from "../../../imges/corp/30.png"
import corp3 from "../../../imges/corp/31.png"
import corp4 from "../../../imges/corp/32.png"
import corp5 from "../../../imges/corp/33.png"
import corp6 from "../../../imges/corp/34.png"
import corp7 from "../../../imges/corp/35.png"
import corp8 from "../../../imges/corp/36.png"
import corp9 from "../../../imges/corp/37.png"
import corp10 from "../../../imges/corp/38.png"
import corp11 from "../../../imges/corp/39.png"
import corp12 from "../../../imges/corp/40.png"
import corp13 from "../../../imges/corp/41.png"
import corp14 from "../../../imges/corp/42.png"
import corp15 from "../../../imges/corp/43.png"
import corp16 from "../../../imges/corp/44.png"
import corp17 from "../../../imges/corp/45.png"
import corp18 from "../../../imges/corp/46.png"
import corp19 from "../../../imges/corp/47.png"

function Joinus() {
    const options = {
        items: 5,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        animateOut: "slideOutup",
        nav: false,
        dots: true,
        margin: 8,
        responsive:{
          0:{
              items:2
          },
          600:{
              items:5}
          }
      };
  return (
    <>
      <div className="container-xxl py-3 bg-white">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp txthome"
            data-wow-delay="0.1s"
          >
            <h1>Join Us in Doing Aram for a Better World With</h1>
          </div>
          <div className="container-fluid">
            <OwlCarousel items={5} className="owl-theme" {...options}>
              <div className="item bg-white">
                <img src={corp1} alt="corp1" />
              </div>
              <div className="item bg-white">
                <img src={corp2} alt="corp2" />
              </div>
              <div className="item bg-white">
                <img src={corp3} alt="corp3" />
              </div>
              <div className="item bg-white">
                <img src={corp4} alt="corp4" />
              </div>
              <div className="item bg-white">
                <img src={corp5} alt="corp5" />
              </div>
              <div className="item bg-white">
                <img src={corp6} alt="corp6" />
              </div>
              <div className="item bg-white">
                <img src={corp7} alt="corp7" />
              </div>
              <div className="item bg-white">
                <img src={corp8} alt="corp8" />
              </div>
              <div className="item bg-white">
                <img src={corp9} alt="corp9" />
              </div>
              <div className="item bg-white">
                <img src={corp10} alt="corp10" />
              </div>

              <div className="item bg-white">
                <img src={corp11} alt="corp11" />
              </div>
              <div className="item bg-white">
                <img src={corp12} alt="corp12" />
              </div>
              <div className="item bg-white">
                <img src={corp13} alt="corp13" />
              </div>
              <div className="item bg-white">
                <img src={corp14} alt="corp14" />
              </div>
              <div className="item bg-white">
                <img src={corp15} alt="corp15" />
              </div>

              <div className="item bg-white">
                <img src={corp16} alt="corp16" />
              </div>
              <div className="item bg-white">
                <img src={corp17} alt="corp17" />
              </div>
              <div className="item bg-white">
                <img src={corp18} alt="corp18" />
              </div>
              <div className="item bg-white">
                <img src={corp19} alt="corp19" />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default Joinus;
