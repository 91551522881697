import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";

const Donatenow = ({
  visible,
  onClose,
  donatengoId,
  donatecauseId,
  footerContent,
}) => {
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [paymentmodal, setPaymentmodal] = useState(false);
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const validateAmount = (_, value) => {
    const amount = parseFloat(value);

    if (!value) {
      return Promise.reject("Amount is required");
    } else if (isNaN(amount) || amount < 10000) {
      return Promise.reject("Amount should be greater than Rs. 10000");
    } else {
      return Promise.resolve();
    }
  };
  const onsubmit = (values) => {
    const CorpData = {
      id: 1,
      entityId: userCred.associatedEntity,
      email: userCred.userId,
      phone: null,
      ngoId: donatengoId,
      causeId: donatecauseId,
      donatedAmount: values.amount,
      gatewayCreditedAmount: 0,
      finalAmountAfterFees: 0,
      doaramPlatformFees: 0,
      orderId: "",
      paymentMode: "",
      status: "",
      pan: "",
      cert80g: "",
      createdDate: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
    };
    console.log(CorpData);
    const CorpformData = new FormData();
    CorpformData.append("userCred", JSON.stringify(userCred));
    CorpformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpformData.append("corpDonationUserDetails", JSON.stringify(CorpData));
    UploadService.create("corp/payments/cause/createOrder", CorpformData).then(
      (response) => {
        setEncRequest(response.data.data.encReq);
        setAccessCode(response.data.data.accessCode);
        onClose();
        setPaymentmodal(true);
      }
    );
  };
  return (
    <>
      <Modal open={visible} closeIcon={false} centered={true} footer={false}>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={require("../../../imges/individual/donate.png")}
              alt="sucess-icon"
            />
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="modalfont">Donate Now</h1>
          </div>
          <Form
            scrollToFirstError={true}
            name="validateOnly"
            onFinish={onsubmit}
          >
            <div>
              <Form.Item
                className="para b themeCol-orange mt-3"
                label="Amount :"
                name="amount"
                // rules={[{ validator: validateAmount }]}
              >
                <Input placeholder="Amount" />
              </Form.Item>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button key="ok" type="primary" onClick={onClose}>
                Cancel
              </Button>
              <Button htmlType="submit" className=" ms-3">
                Pay Now
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Proceed for payment"
        centered
        open={paymentmodal}
        footer={null}
        onCancel={() => setPaymentmodal(false)}
      >
        <>
          <form
            action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
            method="POST"
            name="redirect"
            className="row"
          >
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={encRequest}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={accessCode}
            />
            <div className="mt-3 ">
              <p>
                {" "}
                With each donation, you're not just giving, but you're becoming
                a part of a story that changes lives.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="btnnca btn-gold"
                type="submit"
                style={{ padding: "8px", width: "200px" }}
              >
                <i className="fa fa-heart me-1"></i>
                Donate Now
              </button>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
};

export default Donatenow;
