import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import UploadService from "../../service/file-upload.service";
import { State, City } from "country-state-city";
import moment from "moment";
import Loading from "../../assets/loading-circle.gif";

import {
  Form,
  Table,
  Input,
  Button,
  message,
  Pagination,
  Select,
  Modal,
  Tabs,
  DatePicker,
} from "antd";
import $ from "jquery";
const { Option } = Select;

const category = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function NGO_UpdateCause() {
  const { TabPane } = Tabs;
  let editdata = null;
  let { state } = useLocation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalElements, setTotalElements] = useState();
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  if (state != null) {
    editdata = state;
  } else {
    editdata = null;
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedCause, setSelectedCause] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filteredOptions = category.filter((o) => !selectedItems.includes(o));
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const columns = [
    {
      title: "Version",
      dataIndex: "version",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "GoalType",
      dataIndex: "goalType",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "GoalAmount",
      dataIndex: "goalAmount",
    },
    {
      title: "UpdatedDate",
      dataIndex: "updatedDate",
    },
  ];

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const handleOk = () => {
    navigate("/NGO_Cause");
  };

  const onsubmit = (values) => {
    setBlur(true);
    setLoading(true);
    const formDataWithFiles = new FormData();
    // let images = [];
    // let supportiveDocument = [];
    // if (values.galleryimage0 != undefined) {
    //   images.push(values.galleryimage0.file.name);
    // }
    // if (values.galleryimage1 != undefined) {
    //   images.push(values.galleryimage1.file.name);
    // }
    // if (values.galleryimage2 != undefined) {
    //   images.push(values.galleryimage2.file.name);
    // }
    // if (values.Supportivedocument1 != undefined) {
    //   supportiveDocument.push(values.Supportivedocument1.file.name);
    // }
    // if (values.Supportivedocument2 != undefined) {
    //   supportiveDocument.push(values.Supportivedocument2.file.name);
    // }

    let cause = {
      entityId: editdata.editdata.entityId,
      causeId: editdata.editdata.causeId,
      version: editdata.editdata.version,
      title: editdata.editdata.title,
      description: values.description,
      category: editdata.editdata.category,
      goalType: editdata.editdata.goalType,
      goalAmount: editdata.editdata.goalAmount,
      nonFundGoalDesc: "",
      receivedAmount: 0,
      nonFundGoalStatus: "",
      startDate: editdata.editdata.startDate,
      endDate: values.endDate,
      city: editdata.editdata.city,
      state: editdata.editdata.state,
      country: editdata.editdata.country,
      status: "",
      causeLink: "",
      images: editdata.editdata.images,
      supportiveDocs: editdata.editdata.supportiveDocs,
      comments: "",
      createdBy: "",
      updatedBy: "",
      createdDate: "",
      updatedDate: "",
    };


    formDataWithFiles.append("cause", JSON.stringify(cause));
    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    formDataWithFiles.append("images", null);
    formDataWithFiles.append("supportiveDocs", null);

    // for (let i = 0; i <= 2; i++) {
    //   const key = `galleryimage${i}`;
    //   const galleryFile = values[key]?.file?.originFileObj;

    //   if (galleryFile) {
    //     formDataWithFiles.append("images", galleryFile);
    //   }
    // }

    // for (let i = 1; i <= 2; i++) {
    //   const key = `Supportivedocument${i}`;
    //   const supportiveDocumentFile = values[key]?.file?.originFileObj;

    //   if (supportiveDocumentFile) {
    //     formDataWithFiles.append("supportiveDocs", supportiveDocumentFile);
    //   }
    // }

    UploadService.create("/ngo/cause/update", formDataWithFiles).then(
      (response) => {
        setBlur(false);
        setLoading(false);
        let logSts = response.data.message.messageType;

        if (logSts == "Success") {
          setIsModalOpen(true);
        } else {
          setBlur(false);
          setLoading(false);          
          window.toastr.warning(
            response.data.message.messageDetails[0].responseMessage
          );
        }
      }
    );
  };
  const callImageAPI = (filename) => {

    const causeimageformData = new FormData();
    causeimageformData.append("userCred", JSON.stringify(userCred));
    causeimageformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    causeimageformData.append("cause", JSON.stringify(editdata.editdata));
    causeimageformData.append("fileName", filename);
    UploadService.filedownload("ngo/cause/getCauseImage", causeimageformData)
      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }
  const [image1name, setimage1name] = useState("");
  const [image2name, setimage2name] = useState("");
  const [image3name, setimage3name] = useState("");
  const [supportiveDoc1name, setsupportiveDoc1name] = useState("");
  const [supportiveDoc2name, setsupportiveDoc2name] = useState("");

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
    if (editdata != null) {
      setShowHistory(true);
      const startDate = dayjs(new Date(editdata.editdata.startDate));
      const endDate = dayjs(new Date(editdata.editdata.endDate));
      form.setFieldsValue({
        category: editdata.editdata.category,
        title: editdata.editdata.title,
        description: editdata.editdata.description,
        goalType: editdata.editdata.goalType,
        goalAmount: editdata.editdata.goalAmount,
        city: editdata.editdata.city,
        state: editdata.editdata.state,
        country: editdata.editdata.country,
        startDate: startDate,
        endDate: endDate,
      });

      const imageNames = editdata.editdata.images;
      const individualimage = imageNames.split(",");
      setimage1name(individualimage[0]);
      setimage2name(individualimage[1]);
      setimage3name(individualimage[2]);

      const supporitivedocsNames = editdata.editdata.supportiveDocs;
      const individualsupporitivedocs = supporitivedocsNames.split(",");
      setsupportiveDoc1name(individualsupporitivedocs[0]);
      setsupportiveDoc2name(individualsupporitivedocs[1]);

      setSelectedCause(editdata.editdata.title);
      const historyformData = new FormData();
      historyformData.append("cause", JSON.stringify(editdata.editdata));
      historyformData.append("userCred", JSON.stringify(userCred));
      historyformData.append(
        "userRoleAuthMapping",
        JSON.stringify(userRoleAuthMapping)
      );
      UploadService.create("/ngo/cause/history/get", historyformData).then(
        (response) => {
          setTotalElements(response.data.data.causeHistoryList.length);
          let logSts = response.data.message.messageType;
          setDataSource(response.data.data.causeHistoryList);

          if (logSts == "Success") {
            // window.toastr.warning("cause Created Succesfully");
          } else {
            window.toastr.warning(
              response.data.message.messageDetails[0].responseMessage
            );
          }
        }
      );
    } else {
      setShowHistory(false);
    }
  }, [page]);

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    const startDate = form.getFieldValue("startDate");
    return (
      current &&
      (current < moment(startDate).startOf("day") ||
        current < moment().startOf("day"))
    );
  };

  const validateEndDate = (_, value) => {
    const startDate = form.getFieldValue("startDate");
    if (value && startDate && value.isBefore(startDate)) {
      return Promise.reject(new Error("End date must be after start date"));
    }
    return Promise.resolve();
  };
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" }); // Clear selected city when state changes
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    // Do something with the selected city, if needed
  };
  return (
    <>
       {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
      <div className="col-md-12 row fontfamilynca ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-8 row">
                <div className="col">
                  <NavLink to="/NGO_Cause">
                    <button className="btnnca btn-pink btn-sm mt-3">
                      <i className="fa fa-arrow-left"></i> Back To Cause
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-8">
                <h5 className="m-0 tableheading">&nbsp;Cause -{selectedCause}</h5>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <Tabs defaultActiveKey="1" type="card" large>
              <TabPane tab="Cause Details" key="1">
                <div className="glasscard">
                  <Form
                    autoComplete="off"
                    form={form}
                    onFinish={onsubmit}
                    labelCol={{ span: 4 }}
                    wrapperCol={{
                      span: 17,
                    }}
                  >
                    <legend className="para b themeCol-orange mx-3 mt-2">
                      Cause Details:{" "}
                    </legend>
                    <div className="mt-4">
                      <Form.Item
                        className="para b themeCol-orange"
                        name="category"
                        label="Category"
                      >
                        <Select
                          mode="multiple"
                          placeholder="--NGO Category--"
                          value={selectedItems}
                          onChange={setSelectedItems}
                          disabled
                          style={{
                            width: "100%",
                            pointerEvents: "none",
                          }}
                          options={filteredOptions.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="title"
                        label="Cause Title"
                      >
                        <Input
                          size="medium"
                          disabled
                          placeholder="Enter Cause Title"
                        />
                      </Form.Item>
                    </div>
                    <div className="upload">
                      <Form.Item
                        className=""
                        name="galleryimage0"
                        label="Campaign Image"
                      >
                        {/* <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                          <Button block icon={<UploadOutlined />}>
                            Update new file
                          </Button>
                        </Upload> */}

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm mt-2"
                          onClick={() => callImageAPI(image1name)}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                    </div>
                    <legend className="para b themeCol-orange mx-3 mt-2">
                      Goal Details:{" "}
                    </legend>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="goalType"
                        label="Goal Type"
                      >
                        <Select
                          placeholder="-Select Goal-"
                          disabled
                          options={[
                            {
                              value: "Funds",
                              label: "Funds",
                            },
                            {
                              value: "Transport",
                              label: "Transport",
                            },
                            {
                              value: "Food",
                              label: "Food",
                            },
                            {
                              value: "Facility",
                              label: "Facility",
                            },
                            {
                              value: "Clothes",
                              label: "Clothes",
                            },
                            {
                              value: "Others",
                              label: "Others",
                            },
                          ]}
                          style={{
                            pointerEvents: "none",
                          }}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        name="goalAmount"
                        label="Goal Amount(₹)"
                        rules={[
                          {
                            required: true,
                            message: "Amout is required",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          disabled
                          placeholder="Amount In Rupees"
                          style={{ width: "300px" }}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className=""
                        name="startDate"
                        label="Start Date"
                      >
                        <DatePicker
                          disabled
                          disabledDate={disabledStartDate}
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className=""
                        name="endDate"
                        label="End Date"
                        rules={[
                          {
                            required: true,
                            message: "End Duration required",
                          },
                          {
                            validator: validateEndDate,
                          },
                        ]}
                      >
                        <DatePicker disabledDate={disabledEndDate} />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="description"
                        label="Cause Description"
                        rules={[
                          {
                            required: true,
                            message: "enter cause description",
                          },
                        ]}
                      >
                        <Input.TextArea
                          showCount
                          disabled
                          maxLength={250}
                          style={{ height: 200, resize: "none" }}
                          placeholder="Enter Cause  Description(250 words)"
                        />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="state"
                        label="State"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <Select
                          placeholder="Select State"
                          onChange={handleStateChange}
                          value={selectedState}
                          disabled
                        >
                          {states.map((state) => (
                            <Option key={state} value={state}>
                              {state}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="para b themeCol-orange"
                        name="city"
                        label="City"
                      >
                        <Select
                          placeholder="Select City"
                          onChange={handleCityChange}
                          disabled
                          value={selectedCity}
                          style={{
                            pointerEvents: "none",
                          }}
                          // disabled={!selectedState}
                        >
                          {cities.map((city) => (
                            <Option key={city.value} value={city.value}>
                              {city.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <legend className="para b themeCol-orange mx-3">
                      Gallery:{" "}
                    </legend>
                    <div className="upload">
                      <Form.Item
                        className=""
                        name="galleryimage1"
                        label=" Website Image 1"
                      >
                        {/* <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                          <Button block icon={<UploadOutlined />}>
                            Update new file
                          </Button>
                        </Upload> */}

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm mt-2"
                          onClick={() => callImageAPI(image2name)}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                    </div>

                    <div className="upload">
                      <Form.Item
                        className=""
                        name="galleryimage2"
                        label=" Website Image 2"
                      >
                        {/* <Upload maxCount={1} accept=".jpeg,.png,.jpg">
                          <Button block icon={<UploadOutlined />}>
                            Update new file
                          </Button>
                        </Upload> */}

                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm mt-2"
                          onClick={() => callImageAPI(image3name)}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                    </div>

                    <legend className="para b themeCol-orange mx-3">
                      Supportive Document:{" "}
                    </legend>
                    <div className="upload">
                      <Form.Item
                        className="mb-2"
                        name="Supportivedocument1"
                        label="Supportive Document 1"
                      >
                        {/* <Upload maxCount={1} accept=".jpeg,.png,.jpg,.pdf">
                          <Button block icon={<UploadOutlined />}>
                            Update new file
                          </Button>
                        </Upload> */}
                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm ms-4 mb-3"
                          onClick={() => callImageAPI(supportiveDoc1name)}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                    </div>

                    <div className="upload">
                      <Form.Item
                        className="mb-2"
                        name="Supportivedocument2"
                        label="Supportive Document 2"
                      >
                        {/* <Upload maxCount={1} accept=".jpeg,.png,.jpg,.pdf">
                          <Button block icon={<UploadOutlined />}>
                            Update new file
                          </Button>
                        </Upload> */}
                        <Button
                          type="button"
                          className="btnnca btn-gold btn-sm ms-4 mb-3"
                          onClick={() => callImageAPI(supportiveDoc2name)}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button
                        htmlType="submit"
                        className="btnnca btn-gold signupbtn"
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                </div>
              </TabPane>
              {showHistory && (
                <TabPane tab="Cause History" key="2">
                  <div className=" row col-md-12">
                    <div className="col">
                      <div className="glasscard p-1 mb-0">
                        <div className="row">
                          <div className="mt-3 table-responsive">
                            <Table
                              columns={columns}
                              dataSource={dataSource}
                              style={{ padding: "5px" }}
                              onChange={handlePageChange}
                              bordered
                              scroll={{
                                x: 1000,
                              }}
                              pagination={false}
                            />
                            <Pagination
                              Current={page}
                              total={totalElements}
                              pageSize={size}
                              onChange={handlePageChange}
                              showSizeChanger={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </section>
      </div>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          Cause Updated successfully.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default NGO_UpdateCause;
