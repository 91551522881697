import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import UploadService from "../../service/file-upload.service";
import {
  Table,
  Modal,
  Tooltip,
  Pagination,
  Upload,
  Tabs,
  Button,
  Form,
  message,
} from "antd";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";

function NGO80GCertificate() {
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const [form] = Form.useForm();
  const [corpdataSource, setCorpDataSource] = useState([]);
  const [corppage, setCorpage] = useState(1);
  const [corpsize] = useState(8);
  const [corptotalElements, setCorpTotalElements] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorpModalOpen, setIsCorpModalOpen] = useState(false);
  const [corpdetails, setCorpdetails] = useState();

  const allowedFileTypeswithpdf = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];
  let corptablelength = corpdataSource.length;

  const [individiualdataSource, setIndividiualDataSource] = useState([]);
  const [individiualpage, setIndividiualage] = useState(1);
  const [individiualsize, setIndividiualSize] = useState(8);
  const [individualdetails, setIndividualdetails] = useState();
  const [individiualtotalElements, setIndividiualTotalElements] = useState();
  let individiualtablelength = individiualdataSource.length;

  const IndividualhandlePageChange = (individiualpage) => {
    setIndividiualage(individiualpage);
  };
  const CorphandlePageChange = (corppage) => {
    setCorpage(corppage);
  };

  const checkFileTypewithpdf = (file) => {
    const isFileTypeAllowed = allowedFileTypeswithpdf.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUploadwithpdf = (file) => {
    const isFileTypeAllowed = checkFileTypewithpdf(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };
  const Individualcolumns = [
    {
      title: "Name",
      dataIndex: "individualName",
      width: 30,
    },
    {
      title: "PAN",
      dataIndex: "pan",
      width: 50,
    },
    {
      title: "causeId",
      dataIndex: "causeId",
      width: 20,
    },
    {
      title: "orderId",
      dataIndex: "orderId",
      width: 20,
    },

    {
      title: "Date",
      dataIndex: "createdDate",
      width: 120,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "AmountPaid",
      width: 90,
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    // {
    //   title: "CCAvenue",
    //   width: 90,
    //   dataIndex: "gatewayCreditedAmount",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },
    // {
    //   title: "DoAram",
    //   width: 90,
    //   dataIndex: "doaramPlatformFees",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },

    // {
    //   title: "NGO",
    //   width: 90,
    //   dataIndex: "finalAmountAfterFees",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },
    {
      title: "PaymentMode",
      dataIndex: "paymentMode",
      width: 30,
    },
    {
      title: "Action",
      width: 90,
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Upload 80G">
            <span
              className="btn btn-info btn-sm para ms-1 "
              onClick={() => Individualupload80g(record)}
            >
              <i className="fas  fa-upload text-white"></i>
            </span>
          </Tooltip>
          {record.cert80g ? (
            <Tooltip title="Download 80G">
              <span
                className="btn btn-violet btn-sm para ms-1"
                onClick={() => Individualdownload80g(record)}
              >
                <i className="fas fa-download text-white"></i>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="No File">
              <span className="btn btn-danger btn-sm para  ms-1">
                <i className="fas fa-ban text-white "></i>
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const Individualupload80g = (record) => {
    setIsModalOpen(true);
    setIndividualdetails(record);
  };

  const Individualdownload80g = (record) => {
    const IndidownloadformData = new FormData();
    IndidownloadformData.append("userCred", JSON.stringify(userCred));
    IndidownloadformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    IndidownloadformData.append("donationUserDetails", JSON.stringify(record));

    UploadService.filedownload(
      "/ngo/cause/individual/cert80g/getImage",
      IndidownloadformData
    )
      .then((response) => {
        downloadBlob(response.data, record.cert80g);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const Corpcolumns = [
    {
      title: "Name",
      dataIndex: "corpName",
      width: 30,
    },
    {
      title: "PAN",
      dataIndex: "pan",
      width: 50,
    },
    // {
    //   title: "Phone",
    //   width: 20,
    //   dataIndex: "phone",
    // },
    {
      title: "causeId",
      dataIndex: "causeId",
      width: 20,
    },
    {
      title: "orderId",
      dataIndex: "orderId",
      width: 20,
    },

    {
      title: "Date",
      dataIndex: "createdDate",
      width: 120,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Amount Paid",
      width: 90,
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    // {
    //   title: "CCAvenue",
    //   width: 90,
    //   dataIndex: "gatewayCreditedAmount",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },
    // {
    //   title: "DoAram",
    //   width: 90,
    //   dataIndex: "doaramPlatformFees",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },

    // {
    //   title: "NGO",
    //   width: 90,
    //   dataIndex: "finalAmountAfterFees",
    //   render: (text) => <span>&#x20B9; {text}</span>,
    // },
    {
      title: "PaymentMode",
      dataIndex: "paymentMode",
      width: 30,
    },
    {
      title: "Action",
      width: 90,
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Upload 80G">
            <span
              className="btn btn-info btn-sm para ms-1 "
              onClick={() => Corpupload80g(record)}
            >
              <i className="fas  fa-upload text-white"></i>
            </span>
          </Tooltip>
          <Tooltip title="Download 80G">
            <span
              className="btn btn-violet btn-sm para ms-1"
              onClick={() => Corpdownload80g(record)}
            >
              <i className="fas fa-download text-white"></i>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];
  const Corpupload80g = (record) => {
    setIsCorpModalOpen(true);
    setCorpdetails(record);
  };

  const Corpdownload80g = (record) => {
    const CorpdownloadformData = new FormData();
    CorpdownloadformData.append("userCred", JSON.stringify(userCred));
    CorpdownloadformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpdownloadformData.append(
      "corpDonationUserDetails",
      JSON.stringify(record)
    );

    UploadService.filedownload(
      "/ngo/cause/corp/cert80g/getImage",
      CorpdownloadformData
    )
      .then((response) => {
        downloadBlob(response.data, record.cert80g);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  const onsubmit = (values) => {
    const IndiuploadformData = new FormData();
    IndiuploadformData.append("userCred", JSON.stringify(userCred));
    IndiuploadformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    IndiuploadformData.append(
      "donationUserDetails",
      JSON.stringify(individualdetails)
    );
    IndiuploadformData.append("cert80g ", values.cert80g.file.originFileObj);

    UploadService.create(
      "/ngo/cause/individual/80g/send",
      IndiuploadformData
    ).then((response) => {
      console.log(response.data.message.messageType);
      if (response.data.message.messageType === "Success") {
        setIsModalOpen(false);
        message.info("File Uploaded Sucessfully!");
      }
    });
  };

  const oncorpsubmit = (values) => {
    const CorpuploadformData = new FormData();
    CorpuploadformData.append("userCred", JSON.stringify(userCred));
    CorpuploadformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpuploadformData.append(
      "corpDonationUserDetails",
      JSON.stringify(corpdetails)
    );
    CorpuploadformData.append("cert80g ", values.cert80g.file.originFileObj);

    UploadService.create("/ngo/cause/corp/80g/send", CorpuploadformData).then(
      (response) => {
        console.log(response.data.message.messageType);
        if (response.data.message.messageType === "Success") {
          setIsCorpModalOpen(false);
          message.info("File Uploaded Sucessfully!");
        }
      }
    );
  };
  useEffect(() => {
    const corpformDataWithFiles = new FormData();
    corpformDataWithFiles.append("userCred", JSON.stringify(userCred));
    corpformDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    corpformDataWithFiles.append("status", "FULFILLED");
    corpformDataWithFiles.append("page", JSON.stringify(corppage - 1));
    corpformDataWithFiles.append("size", JSON.stringify(corpsize));
    UploadService.create(
      "/ngo/cause/corp/donations/80G/get",
      corpformDataWithFiles
    ).then((response) => {
      console.log(response.data);
      setCorpTotalElements(
        response.data.data.donationUserDetailsPage.totalElements
      );
      setCorpDataSource(response.data.data.donationUserDetailsPage.content);
    });

    const IndividualformDataWithFiles = new FormData();
    IndividualformDataWithFiles.append("userCred", JSON.stringify(userCred));
    IndividualformDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    IndividualformDataWithFiles.append(
      "page",
      JSON.stringify(individiualpage - 1)
    );
    IndividualformDataWithFiles.append("status", "FULFILLED");
    IndividualformDataWithFiles.append("size", JSON.stringify(individiualsize));
    UploadService.create(
      "ngo/cause/individual/donations/80G/get",
      IndividualformDataWithFiles
    ).then((response) => {
      setIndividiualTotalElements(
        response.data.data.donationUserDetailsPage.totalElements
      );
      setIndividiualDataSource(
        response.data.data.donationUserDetailsPage.content
      );
    });
  }, []);

  return (
    <>
      <div className="col-md-12 row fontfamilynca p-2">
        <div className="col-sm-6">
          <h6 className="pt-2 tableheading">
            Fullfilled Cause Donation Details:
          </h6>
        </div>
        <Tabs
          defaultActiveKey="1"
          centered
          bordered={false}
          type="card"
          large
          items={[
            {
              label: "Individual80G",
              key: "1",
              children: (
                <section className="content ">
                  {individiualtablelength > 0 && (
                    <div className="row">
                      <div className="mt-3">
                        <Table
                          columns={Individualcolumns}
                          className="custom-table"
                          dataSource={individiualdataSource}
                          style={{ padding: "5px" }}
                          onChange={IndividualhandlePageChange}
                          bordered
                          scroll={{
                            x: 1200,
                          }}
                          pagination={false}
                        />
                        <Pagination
                          Current={individiualpage}
                          pageSize={individiualsize}
                          onChange={IndividualhandlePageChange}
                          total={individiualtotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  )}
                  {individiualtablelength == 0 && (
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <img
                        src={Nodata}
                        alt="Nodata"
                        className="alignimagecenter"
                      />
                    </div>
                  )}
                </section>
              ),
            },
            {
              label: "Corporate80G",
              key: "2",
              children: (
                <section className="content">
                  {corptablelength > 0 && (
                    <div className="row">
                      <div className="mt-2">
                        <Table
                          columns={Corpcolumns}
                          dataSource={corpdataSource}
                          style={{
                            padding: "5px",
                          }}
                          onChange={CorphandlePageChange}
                          bordered
                          pagination={false}
                          className="custom-table"
                          scroll={{
                            x: 1200,
                          }}
                        />
                        <Pagination
                          Current={corppage}
                          total={corptotalElements}
                          pageSize={corpsize}
                          onChange={CorphandlePageChange}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  )}
                  {corptablelength == 0 && (
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <img
                        src={Nodata}
                        alt="Nodata"
                        className="alignimagecenter"
                      />
                    </div>
                  )}
                </section>
              ),
            },
          ]}
        />
      </div>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        centered={true}
        footer={false}
      >
        <div className="d-flex justify-content-center my-3">
          Upload the 80G Certificate.
        </div>
        <Form autoComplete="off" form={form} onFinish={onsubmit}>
          <div className="upload">
            <Form.Item
              className=""
              name="cert80g"
              label="80g Certificate"
              rules={[
                {
                  required: true,
                  message: "80g Certificate Image is required",
                },
              ]}
            >
              <Upload
                maxCount={1}
                accept=".jpeg,.png,.jpg"
                beforeUpload={customBeforeUploadwithpdf}
              >
                <Button block icon={<UploadOutlined />}>
                  Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              className="btnnca btn-gold signupbtn mt-2"
            >
              Submit
            </Button>
            <Button
              className="btnnca btn-violet signupbtn mt-2 ms-3"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={isCorpModalOpen}
        closeIcon={false}
        centered={true}
        footer={false}
      >
        <div className="d-flex justify-content-center my-3">
          Upload 80G Certificate.
        </div>
        <Form autoComplete="off" form={form} onFinish={oncorpsubmit}>
          <div className="upload">
            <Form.Item
              className=""
              name="cert80g"
              label="80g Certificate"
              rules={[
                {
                  required: true,
                  message: "80g Certificate Image is required",
                },
              ]}
            >
              <Upload
                maxCount={1}
                accept=".jpeg,.png,.jpg"
                beforeUpload={customBeforeUploadwithpdf}
              >
                <Button block icon={<UploadOutlined />}>
                  Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              className="btnnca btn-gold signupbtn mt-2"
            >
              Submit
            </Button>
            <Button
              className="btnnca btn-violet signupbtn mt-2 ms-3"
              onClick={() => {
                setIsCorpModalOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default NGO80GCertificate;
