import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";
import Nodata from "../../assets/icons/Nomembership.png";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Tag,
  Pagination,
} from "antd";

function Admin_NGOMembership() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalElements, setTotalElements] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [reRendertable, setRerendertable] = useState();
  const [addinfoRecord, setAddinfoRecord] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tablelength = dataSource.length;

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const AdminMembershipformData = new FormData();
  AdminMembershipformData.append("userCred", JSON.stringify(userCred));
  AdminMembershipformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminMembershipformData.append("page", JSON.stringify(page - 1));
  AdminMembershipformData.append("size", JSON.stringify(size));

  useEffect(() => {


    UploadService.create(
      "/admin/approval/pending/ngo/membership/get",
      AdminMembershipformData
    ).then((response) => {
      if (response.data !== null) {
        setDataSource(response.data.data.ngoAccountUpgradeApproval.content);
        setTotalElements(
          response.data.data.ngoAccountUpgradeApproval.totalElements
        );
      }
    });
  }, []);
  const generateColor = (userId) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    // Use a simple hash of userId to determine the index of the color
    const index = userId.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const membershipcolumns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.userId.charAt(0).toUpperCase();
        const color = generateColor(record.userId);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "Email",
      width: 90,
      dataIndex: "userId",
    },
    {
      title: "Id",
      width: 90,
      dataIndex: "orderId",
    },

    {
      title: "Current",
      width: 90,
      dataIndex: "currentMembershipLevel",
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Requested",
      width: 90,
      dataIndex: "requestedMembershipLevel",
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Payment",
      width: 90,
      dataIndex: "paymentStatus",
      render: (text) => {
        if (text === "Success") {
          return <Tag color="#198754">Success</Tag>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "Waiting for Approval") {
          return <Tag color="red">WAITING</Tag>;
        }
        return text;
      },
    },

    {
      title: "Action",
      key: "operation",
      width: 90,
      render: (record) => (
        <div>
          <Tooltip title="View Profile">
            <a onClick={() => viewmembershipProfile(record)}>
              <span className="btn  btn-info btn-sm para ">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="Additional Request">
            <a onClick={() => addinfomembership(record)}>
              <span className="btn btn-warning btn-sm para ms-2">
                <i className="fas fa-plus text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const pagination = {
    position: ["topRight"],
    pageSize: "5",
    total: 100,
    showSizeChanger: false,
  };
  const membershipProfileformData = new FormData();

  const viewmembershipProfile = (record) => {
    navigate("/MembershipProfile", {
      state: {
        viewdata: record,
      },
    });
  };
  const addinfomembership = (record) => {
    setIsModalOpen(true);
    setAddinfoRecord(record);
  };
  const ProfileAddtionalinfoformData = new FormData();
  const onsubmit = (values) => {
    setIsModalOpen(false);
    ProfileAddtionalinfoformData.append("userCred", JSON.stringify(userCred));
    ProfileAddtionalinfoformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    let addInfo = {
      id: 7,
      ngoId: addinfoRecord.ngoId,
      userId: addinfoRecord.userId,
      adminId: addinfoRecord.adminId,
      orderId: addinfoRecord.orderId,
      status: addinfoRecord.status,
      comments: values.reason,
      createdTime: addinfoRecord.createdTime,
      modifiedTime: addinfoRecord.modifiedTime,
      communication: addinfoRecord.communication,
      currentMembershipLevel: addinfoRecord.currentMembershipLevel,
      requestedMembershipLevel: addinfoRecord.requestedMembershipLevel, //sent the requested membership level to the level they are asking for
      paymentStatus: addinfoRecord.paymentStatus,
    };
    ProfileAddtionalinfoformData.append(
      "ngoAccountUpgradeApproval",
      JSON.stringify(addInfo)
    );

    UploadService.create(
      "admin/ngo/account/upgrade/needadditionalinfo",
      ProfileAddtionalinfoformData
    ).then((response) => {
      Modal.info({
        content: (
          <div>
            <p>{response.data.statusMessage}</p>
          </div>
        ),
      });
      setRerendertable(addinfoRecord);
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <div className="col-md-12 row fontfamilynca   pt-2 px-4">
      <div className="col-sm-6">
        <h6 className="pt-2 tableheading">Pending Membership Upgrade:</h6>
      </div>
      <section className="content">
        {tablelength > 0 && (
          <div className="row">
            <div className="mt-3">
              <Table
                columns={membershipcolumns}
                dataSource={dataSource}
                style={{ padding: "5px" }}
                onChange={handlePageChange}
                className="custom-table"
                bordered
                scroll={{
                  x: 1200
                }}
                pagination={false}
              />
              <Pagination
                Current={page}
                pageSize={size}
                onChange={handlePageChange}
                total={totalElements}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
        {tablelength == 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ height: "80vh" }}
          >
            <img src={Nodata} alt="Nodata" className="alignimagecenter" />
          </div>
        )}
      </section>
      <Modal
        open={isModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Please provide your reason for the Additional Documents.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
export default Admin_NGOMembership;
