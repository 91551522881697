import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Noaccount.png";
import { useDispatch } from "react-redux";
import { setData } from "../../../slices/corpdataSlice";
import $ from "jquery";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Pagination,
  Tag,
  message,
} from "antd";

function Admin_CorpIntrest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(false);

  const [issucessModalOpen, setIssucessModalOpen] = useState(false);
  const [corpData, setCorpData] = useState();
  let tablelength = dataSource.length;
  const [reRendertable, setRerendertable] = useState();

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const AdminCorpformData = new FormData();
  AdminCorpformData.append("userCred", JSON.stringify(userCred));
  AdminCorpformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminCorpformData.append("sort", "OldToNew");
  AdminCorpformData.append("page", JSON.stringify(page - 1));
  AdminCorpformData.append("size", JSON.stringify(size));

  const columns = [
    {
      title: "Corp ID",
      dataIndex: "corpId",
      width: 90,
    },
    {
      title: "NGO ID",
      dataIndex: "ngoId",
      width: 90,
    },
    {
      title: "Cause ID",
      dataIndex: "causeId",
      width: 90,
    },
    {
      title: "Comments",
      dataIndex: "corpComments",
      width: 120,
      render: (text) => (
        <Tooltip title={text}>
          {text.length > 20 ? `${text.substring(0, 20)}...` : text}
        </Tooltip>
      ),
    },
    // {
    //   title: "Date",
    //   dataIndex: "interestRegDate",
    //   width: 90,
    //   render: (text) => {
    //     const datePart = text.substr(0, 10);
    //     return datePart;
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
    },
    {
      title: "Action",
      key: "operation",
      width: 140,
      render: (record) => (
        <div>
          <Tooltip title="Comment">
            <a onClick={() => comment(record)}>
              <span className="btn  btn-warning btn-sm para ">
                <i className="fa fa-comment text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a onClick={() => deleteInterest(record)}>
              <span className="btn  btn-danger btn-sm para ms-1">
                <i className="fa fa-trash text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="View Profile">
            <a onClick={() => viewcorpProfile(record)}>
              <span className="btn  btn-info btn-sm para ms-1">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="Intrested NGO">
            <a onClick={() => corpintrested(record)}>
              <span className="btn btn-violet btn-sm para ms-1">
                <i className="fa fa-list-alt text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const comment = (record) => {
    setIsModalOpen(true);
    setCorpData(record);
  };
  const deleteInterest = (record) => {
    setDeleteIsModalOpen(true);
    setCorpData(record);
  };
  const corpintrested = (record) => {
    navigate("/Admin_CorpInterstedList", {
      state: {
        viewdata: record,
      },
    });
  };
  const viewcorpProfile = (record) => {
    const ViewCorpformData = new FormData();
    ViewCorpformData.append("userCred", JSON.stringify(userCred));
    ViewCorpformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ViewCorpformData.append("corpRegisterInterest", JSON.stringify(record));
    UploadService.create(
      "admin/corp/registerInterest/profile/get",
      ViewCorpformData
    ).then((response) => {
      dispatch(setData(response.data.data));
      navigate("/Admin_CorpIntrestedProfile");
    });
  };

  const onsubmit = (values) => {
    const AdminCorpcommentformData = new FormData();
    AdminCorpcommentformData.append("userCred", JSON.stringify(userCred));
    AdminCorpcommentformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    AdminCorpcommentformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpData)
    );
    AdminCorpcommentformData.append("comments", values.reason);
    UploadService.create(
      "admin/corp/create/interest/action/comments",
      AdminCorpcommentformData
    ).then((response) => {
      setIsModalOpen(false);
      setRerendertable(values);
      message.success("Message sent to Corporate Sucessfully!");
    });
  };
  const deletesubmit = (values) => {
    const AdminCorpdeleteformData = new FormData();
    AdminCorpdeleteformData.append("userCred", JSON.stringify(userCred));
    AdminCorpdeleteformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    AdminCorpdeleteformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpData)
    );
    AdminCorpdeleteformData.append("comments", values.reason);
    UploadService.create(
      "admin/corp/close/interest/action",
      AdminCorpdeleteformData
    ).then((response) => {
      setDeleteIsModalOpen(false);
      setRerendertable(values);
      message.success("Intrest Closed Sucessfully!");
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    UploadService.create(
      "admin/corp/get/allActive/interest",
      AdminCorpformData
    ).then((response) => {
      setDataSource(response.data.data.corpRegisterInterest.content);
      setTotalElements(response.data.data.corpRegisterInterest.totalElements);
    });
  }, [page,reRendertable]);
  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Corprate Interested:</h6>
          </div>
          <section className="content">
            <div className="row">
              <div className="mt-2">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  style={{
                    padding: "5px",
                  }}
                  bordered
                  pagination={false}
                  className="custom-table"
                  scroll={{
                    x: 1000,
                  }}
                />
                <Pagination
                  Current={page}
                  total={totalElements}
                  pageSize={size}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </section>
        </div>
      </>
      <Modal
        open={isModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Comment is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isDeleteModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          scrollToFirstError={true}
          name="validateOnly"
          onFinish={deletesubmit}
        >
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Delete Comment is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default Admin_CorpIntrest;
