import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, Dropdown } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import $ from "jquery";
const { Sider, Content, Header } = Layout;
const current = new Date();
const toDay = `${current.getDate()} / ${
  current.getMonth() + 1
} / ${current.getFullYear()}`;

const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

const SideMenuadmin = ({ children }) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        {" "}
        <a className="dropdown-item">
          <i className="fas fa-clock" aria-hidden="true"></i>&nbsp; Logged-In
          Date: {toDay}
        </a>
      </Menu.Item>
      {/* <Menu.Item key="2">
        {" "}
        <a className="dropdown-item">
          <i className="fas fa-hourglass" aria-hidden="true"></i>&nbsp; Last
          Login Date: {userCred.lastLoginTime.split(".")[0].replace("T", " ")}
        </a>
      </Menu.Item> */}
      <Menu.Item key="3">
        {" "}
        <NavLink className="dropdown-item" to="/Admin_Profile">
          <i className="fas fa-user" aria-hidden="true"></i>&nbsp; Account
          Setting
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        {" "}
        <NavLink className="dropdown-item" to="/adminsignin">
          <i className="fas fa-sign-out-alt" aria-hidden="true"></i>&nbsp;
          Logout
        </NavLink>
      </Menu.Item>
    </Menu>
  );
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "1"
  );
  const handleMenuClick = (e) => {
    setActiveTab(e.key);
    localStorage.setItem("activeTab", e.key);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    switch (currentPath) {
      case "/adminDashboard":
        setActiveTab("1");
        break;
      case "/Admin_Cause":
        setActiveTab("2");
        break;
      case "/Admin_NGO":
        setActiveTab("3");
        break;
      case "/NGOMembership":
        setActiveTab("4");
        break;
      case "/paymentstatus":
        setActiveTab("5");
        break;
      case "/Admin_Corp":
        setActiveTab("6");
        break;
      case "/Admin_CorpInterest":
        setActiveTab("7");
        break;
      case "/admincauseDonation":
        setActiveTab("8");
        break;
      case "/Admin_CorpDonation":
        setActiveTab("9");
        break;
      case "/Admin_Profile":
        setActiveTab("10");
        break;
      default:
        setActiveTab("1"); // Default tab
        break;
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={toggleSidebar}
        className="antsidebar text-white"
        style={{ lineHeight: "1.7rem", fontWeight: "300" }}
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/da_img/logo1.png")}
            alt="DoAram_Logo"
            className="img-fluid p-4 "
            style={{ maxHeight: "150px", maxWidth: "150px" }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="antsidebar  nav-linknca fontfamilynca"
          onClick={handleMenuClick}
          selectedKeys={[activeTab]}
        >
          <Menu.Item key="1" icon={<i className="nav-icon fa fa-home"></i>}>
            <Link to="/adminDashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="submenu1"
            icon={<i className="nav-icon fa fa-heartbeat"></i>}
            title="NGO"
            className="custom-submenu"
          >
            <Menu.Item key="2">
              <Link to="/Admin_Cause">Causes</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/Admin_NGO">NGO Account</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/NGOMembership">NGO Membership</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/paymentstatus">PaymentStatus</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="submenu2"
            icon={<i className="nav-icon fa fa-building"></i>}
            title="Corporate"
            className="custom-submenu"
          >
            <Menu.Item key="6">
              <Link to="/Admin_Corp">Corporate Account</Link>
            </Menu.Item>

            <Menu.Item key="7">
              <Link to="/Admin_CorpInterest"> Interested Corporate</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="submenu3"
            icon={<i className="nav-icon fa fa-cart-plus"></i>}
            title=" Cause Donation"
            className="custom-submenu"
          >
            <Menu.Item key="8">
              <Link to="/admincauseDonation">Donation</Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="/Admin_CorpDonation">Fullfillment</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="10" icon={<i className="nav-icon fa fa-user"></i>}>
            <Link to="/Admin_Profile">Profile</Link>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header
          style={{
            padding: 0,
            backgroundColor: "white",
          }}
          className="d-flex justify-content-between  align-items-center"
        >
          <div>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
              className="collapse-button"
            />
          </div>
          <div>
            <Dropdown overlay={menu}>
              <Button
                type="primary"
                icon={<i className="fa fa-user-circle"></i>}
                size="medium"
                className="btnnca btn-pink btn-sm homebtnheight me-3"
              >
                Administrator<i className="fa fa-angle-down ms-1"></i>{" "}
                {/* Add your icon here */}
              </Button>
            </Dropdown>

            {/* <Link
              to="#"
              className="nav-linkheadernca"
              data-widget="fullscreen"
              role="button"
            >
              <i
                className="fas fa-expand-arrows-alt themeCol-pink me-3 my-2 collapse-butto"
                style={{ fontSize: 17 }}
              ></i>
            </Link> */}
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default SideMenuadmin;
