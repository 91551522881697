import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Nocause.png";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Pagination,
  Tag,
} from "antd";
import $ from "jquery";

function Admin_Cause(props) {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();

  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pagination = {
    position: ["topRight"],
    pageSize: size,
    total: 100,
    showSizeChanger: false,
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  let tablelength = dataSource.length;
  const [reRendertable, setRerendertable] = useState();
  const [addinfoRecord, setAddinfoRecord] = useState();

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const AdminCauseformData = new FormData();
  AdminCauseformData.append("userCred", JSON.stringify(userCred));
  AdminCauseformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  AdminCauseformData.append("page", JSON.stringify(page - 1));
  AdminCauseformData.append("size", JSON.stringify(size));

  useEffect(() => {
   

    UploadService.create(
      "/admin/decision/pending/ngo/causes/get",
      AdminCauseformData
    ).then((response) => {
      setTotalElements(response.data.data.ngoCauseApproval.totalElements);
      if (response.data !== null) {
        setDataSource(response.data.data.ngoCauseApproval.content);
        //setTotalPages(response.data.data.causePage.totalPages);
      }
    });
  }, [reRendertable, page]);
  const generateColor = (userId) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    // Use a simple hash of userId to determine the index of the color
    const index = userId.charCodeAt(0) % colors.length;
    return colors[index];
  };
  const columns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.userId.charAt(0).toUpperCase();
        const color = generateColor(record.userId);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "Email",
      width: 90,
      dataIndex: "userId",
    },
    {
      title: "Id",
      width: 90,
      dataIndex: "causeId",
    },
    {
      title: "CreatedDate",
      dataIndex: "createdTime",
      width: 90,
      render: (text) => {
        const datePart = text.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart;
      },
    },
    {
      title: "ModifiedDate",
      dataIndex: "modifiedTime",
      width: 90,
      render: (text) => {
        const datePart = text.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "WAITING_FOR_APPROVAL") {
          return <Tag color="red">WAITING</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      // fixed: "right",
      width: 90,
      render: (record) => (
        <div>
          <Tooltip title="View Cause">
            <a onClick={() => viewCause(record)}>
              <span className="btn btn-info btn-sm para me-1">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="Add Additional Request">
            <a onClick={() => additinalInfo(record)}>
              <span className="btn btn-warning btn-sm para ms-1">
                <i className="fas fa-plus text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CauseAddtionalinfoformData = new FormData();
  const additinalInfo = (record) => {
    setIsModalOpen(true);
    setAddinfoRecord(record);
  };
  const onsubmit = (values) => {
    setIsModalOpen(false);
    CauseAddtionalinfoformData.append("userCred", JSON.stringify(userCred));
    CauseAddtionalinfoformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    let addInfo = {
      id: addinfoRecord.id,
      entityId: addinfoRecord.entityId,
      causeId: addinfoRecord.causeId,
      userId: addinfoRecord.userId,
      entityId: addinfoRecord.entityId,
      version: addinfoRecord.version,
      adminId: userCred.userId,
      status: "Need Additional Information",
      comments: values.reason,
      createdTime: addinfoRecord.createdTime,
      modifiedTime: addinfoRecord.modifiedTime,
      communication: addinfoRecord.communication,
    };
    CauseAddtionalinfoformData.append(
      "ngoCauseApproval",
      JSON.stringify(addInfo)
    );

    UploadService.create(
      "/admin/ngo/cause/decision/needadditionalinfo",
      CauseAddtionalinfoformData
    ).then((response) => {
      Modal.info({
        content: (
          <div>
            <p>{response.data.statusMessage}</p>
          </div>
        ),
      });
      setRerendertable(addinfoRecord);
    });
  };

  const viewCauseformData = new FormData();
  const viewCause = (record) => {
    viewCauseformData.append("userCred", JSON.stringify(userCred));
    viewCauseformData.append(
      " userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    let addInfo = {
      id: record.id,
      entityId: record.entityId,
      causeId: record.causeId,
      userId: record.userId,
      entityId: record.entityId,
      version: record.version,
      adminId: userCred.userId,
      status: record.status,
      comments: "",
      createdTime: record.createdTime,
      modifiedTime: record.modifiedTime,
      communication: record.communication,
    };
    viewCauseformData.append("ngoCauseApproval", JSON.stringify(addInfo));

    UploadService.create(
      "/admin/ngo/cause/profile/get",
      viewCauseformData
    ).then((response) => {
      navigate("/AdminViewCause", {
        state: {
          viewdata: response.data.data.cause,
          selectedcause: addInfo,
        },
      });
    });
  };

  return (
    <>
      <div className="col-md-12 row fontfamilynca   pt-2 px-4">
        <div className="col-sm-6">
          <h5 className="tableheading ">Pending Cause:</h5>
        </div>
        <section className="content">
          {tablelength > 0 && (
            <div className="row">
              <div className="mt-3">
                <Table
                  columns={columns}
                  style={{ padding: "5px" }}
                  dataSource={dataSource}
                  onChange={handlePageChange}
                  bordered
                  scroll={{
                    x: 1000,
                  }}
                  className="custom-table"
                  pagination={false}
                    
                />
                <Pagination
                  Current={page}
                  total={totalElements}
                  pageSize={size}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </div>
            </div>
          )}
          {tablelength == 0 && (
            <div
              className="d-flex justify-content-center"
              style={{ height: "80vh" }}
            >
              <img src={Nodata} alt="Nodata" className="alignimagecenter" />
            </div>
          )}
        </section>
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Please provide your reason for the Additional Documents.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default Admin_Cause;
