import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Tooltip, Pagination, Tag, Tabs } from "antd";
import Nodata from "../../assets/icons/Noaccount.png";
import $ from "jquery";
import CustomModal from "./ViewNgoModel";
import UploadService from "../../service/file-upload.service";

function Admin_Corp() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [tablelength, setTablelength] = useState();
  const [ngopagemodel, setNgopagemodel] = useState(false);
  const [ngoid, setNgoid] = useState();
  const [otherpage, setOtherPage] = useState(1);
  const [othersize, setOtherSize] = useState(8);
  const [othertotalElements, setOtherTotalElements] = useState();
  const [otherdataSource, setOtherDataSource] = useState([]);
  const [othertablelength, setOtherTablelength] = useState();
  const [activeTab, setActiveTab] = useState("1");

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleOtherPageChange = (page) => {
    setOtherPage(page);
  };
  const listngoformData = new FormData();
  listngoformData.append("userCred", JSON.stringify(userCred));
  listngoformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  listngoformData.append("prefFlag", "PREF_MEMBER_AND_CATEGORY");
  listngoformData.append("page", page - 1);
  listngoformData.append("size", size);

  const otherlistngoformData = new FormData();
  otherlistngoformData.append("userCred", JSON.stringify(userCred));
  otherlistngoformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  otherlistngoformData.append("prefFlag", "PREF_MEMBERSHIP_OTHER_CATEGORY");
  otherlistngoformData.append("page", otherpage - 1);
  otherlistngoformData.append("size", othersize);

  useEffect(() => {
    UploadService.create("corp/ngo/preference/get", listngoformData).then(
      (response) => {
        if (response.data !== null) {
          setDataSource(response.data.data.ngoDetails);
        }
        setTotalElements(response.data.data.totalElements);
        setTablelength(response.data.data.totalElements);
      }
    );
    UploadService.create("corp/ngo/preference/get", otherlistngoformData).then(
      (response) => {
        if (response.data !== null) {
          setOtherDataSource(response.data.data.ngoDetails);
        }
        setOtherTotalElements(response.data.data.totalElements);
        setOtherTablelength(response.data.data.totalElements);
      }
    );
  }, [page, otherpage, activeTab]);



  const columns = [
    {
      title: "NGO Name",
      dataIndex: ["ngoProfile", "name"],
      width: 90,
      render: (text, record) => (
        <a onClick={() => viewProfile(record)} style={{ cursor: "pointer" }}>
          {text}
        </a>
      ),
    },
    {
      title: "NGO Category",
      dataIndex: ["ngoProfile", "category"],
      width: 90,
    },
    {
      title: "City",
      dataIndex: ["contactInfo", "city"],
      width: 90,
    },
    {
      title: "State",
      dataIndex: ["contactInfo", "state"],
      width: 90,
    },
    {
      title: "Membership",
      dataIndex: ["ngoProfile", "membership"],
      width: 90,
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 80,
      render: (record) => (
        <div>
          <Tooltip title="View NGO Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para ">
                <i className="fas fa fa-globe text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="View Cause">
            <a onClick={() => showinterest(record)}>
              <span className="btn btn-warning btn-sm para ms-2">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const viewProfile = (record) => {
    // const ngoSlug = record.ngoProfile.name.toLowerCase().replace(/\s+/g, "-");
    // const url = `/corp/ngo/${ngoSlug}/${record.ngoProfile.id}`;
    // window.open(url, "_blank");
    setNgopagemodel(true);
    setNgoid(record.ngoProfile.id);

  };

  const showinterest = (record) => {
    navigate("/Corp_NGOCause", {
      state: {
        viewdata: record,
      },
    });
  };

  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Approved NGO'S:</h6>
          </div>
          <Tabs
            defaultActiveKey={activeTab}
            centered
            type="card"
            large
            items={[
              {
                label: " Preferred NGO's",
                key: "1",
                children: (
                  <section className="content">
                    {tablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={dataSource}
                            style={{
                              padding: "5px",
                            }}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1000,
                            }}
                          />
                          <Pagination
                            Current={page}
                            total={totalElements}
                            pageSize={size}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                ),
              },
              {
                label: " Other NGO's",
                key: "2",
                children: (
                  <section className="content">
                    {othertablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={otherdataSource}
                            style={{
                              padding: "5px",
                            }}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1000,
                            }}
                          />
                          <Pagination
                            Current={otherpage}
                            total={othertotalElements}
                            pageSize={othersize}
                            onChange={handleOtherPageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {othertablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}{" "}
                  </section>
                ),
              },
            ]}
          />
        </div>
      </>
      <CustomModal
        visible={ngopagemodel}
        onClose={() => setNgopagemodel(false)}
        data={ngoid}
      />
    </>
  );
}

export default Admin_Corp;
