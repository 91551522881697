import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message, Select } from "antd";
import UploadService from "../../service/file-upload.service";
import { State, City } from "country-state-city";
import Loading from "../../assets/loading-circle.gif";
import $ from "jquery";

import {
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

function IndividualProfile() {
  const { Option } = Select;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [profileFilename, setprofileFilename] = useState("");
  const [profileData, setProfiledata] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const isprofilempty = profileFilename.trim().length === 0;

  const callImageAPI = (filename) => {
    const getImageFormdata = new FormData();
    getImageFormdata.append("userCred", JSON.stringify(userCred));
    getImageFormdata.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    getImageFormdata.append("individualProfile", JSON.stringify(profileData));
    getImageFormdata.append("fileName", filename);

    UploadService.filedownload("/individual/profile/getImage", getImageFormdata)
      .then((response) => {
        console.log(response.data);
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    } else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }
  const onsubmit = (values) => {
    console.log(values);
    const IndividualformData = new FormData();
    const IndividualProfile = {
      id: userCred.associatedEntity,
      name: values.name,
      profilePicture:
        values.profilePicture !== undefined
          ? values.profilePicture.file.name
          : "",
      description: values.description,
      designation: values.designation,
      comments: "",
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null,
    };
    const ContactInfo = {
      entityId: userCred.associatedEntity,
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: "India",
      pinCode: values.pinCode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const bankDetails = {
      entityId: userCred.associatedEntity,
      bankName: values.bankName,
      branchName: values.branchName,
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      upiCode: values.upiCode,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    IndividualformData.append("userCred", JSON.stringify(userCred));
    IndividualformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    IndividualformData.append(
      "individualProfile",
      JSON.stringify(IndividualProfile)
    );
    IndividualformData.append("bankDetails", JSON.stringify(bankDetails));
    IndividualformData.append("contactInfo", JSON.stringify(ContactInfo));

    if (values.profilePicture !== undefined) {
      IndividualformData.append(
        "profilePicture",
        values.profilePicture.file.originFileObj
      );
    }

    UploadService.create("individual/updateProfile", IndividualformData)
      .then((response) => {
        setLoading(false);
        setBlur(false);
        window.toastr.warning("Profile updated successfully");
      })
      .catch((error) => {
        console.error("Error during API call:", error.message);
        window.toastr.warning("An error occurred. Please try again later.");
        setLoading(false);
        setBlur(false);
      });
  };
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  useEffect(() => {
    const individualprofileformData = new FormData();
    individualprofileformData.append("userCred", JSON.stringify(userCred));
    individualprofileformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
    UploadService.create(
      "/individual/getProfile",
      individualprofileformData
    ).then((response) => {
      console.log(response.data);
      form.setFieldsValue({
        addressLine1: response.data.data.contactInfo.addressLine1,
        city: response.data.data.contactInfo.city,
        country: response.data.data.contactInfo.country,
        email: response.data.data.contactInfo.email,
        facebook: response.data.data.contactInfo.facebook,
        instagram: response.data.data.contactInfo.instagram,
        linkedin: response.data.data.contactInfo.linkedin,
        phone: response.data.data.contactInfo.phone,
        pinCode: response.data.data.contactInfo.pinCode,
        state: response.data.data.contactInfo.state,
        twitter: response.data.data.contactInfo.twitter,
        website: response.data.data.contactInfo.website,
        accountName: response.data.data.bankDetails.accountName,
        accountNumber: response.data.data.bankDetails.accountNumber,
        bankName: response.data.data.bankDetails.bankName,
        branchName: response.data.data.bankDetails.branchName,
        ifscCode: response.data.data.bankDetails.ifscCode,
        upiCode: response.data.data.bankDetails.upiCode,
        name: response.data.data.individualProfile.name,
        designation: response.data.data.individualProfile.designation,
        description: response.data.data.individualProfile.description,
      });
      setProfiledata(response.data.data.individualProfile);
      setprofileFilename(response.data.data.individualProfile.profilePicture);
    });
  }, []);

  return (
    <>
      {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
        <div className="col-md-12" style={{ marginLeft: "0px" }}>
          <div className="m-3">
            <Form
              onFinish={onsubmit}
              form={form}
              // onFinishFailed={showerror}
              scrollToFirstError={true}
              name="validateOnly"
              labelCol={{ span: 8 }}
              autoComplete="off"
            >
              <div className="col-md-12  glasscard  p-4">
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ borderRight: "1px solid #f3f3f3" }}
                  >
                    <fieldset>
                      <div className=" p-0">
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          <legend className="para b themeCol-orange mx-3">
                            Personal Information:{" "}
                          </legend>
                          <div className="row mb-3">
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="name"
                                label="Name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter the  Name"
                                />
                              </Form.Item>
                            </div>

                            <div className="upload">
                              {isprofilempty ? (
                                <Form.Item
                                  name="profilePicture"
                                  label="ProfilePicture"
                                >
                                  <Upload maxCount={1} listType="picture">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                              ) : (
                                <>
                                  <Form.Item
                                    className=""
                                    name="profilePicture"
                                    label="ProfilePicture"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                  <Form.Item className="" name="" label=":">
                                    <Button
                                      type="button"
                                      className="btnnca btn-gold btn-sm ms-3"
                                      onClick={() =>
                                        callImageAPI(profileFilename)
                                      }
                                    >
                                      Preview
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="designation"
                                label="Designation"
                                rules={[
                                  {
                                    required: true,
                                    message: "Designation is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter the  Designation"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="description"
                                label="Description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Description is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  //showCount
                                  style={{ height: 100, resize: "none" }}
                                  placeholder="Enter Description"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="addressLine1"
                                label="Registered Address"
                                //colon={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Address is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  style={{ height: 100, resize: "none" }}
                                  placeholder="Registered Address"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="state"
                                label="State"
                              >
                                <Select
                                  placeholder="Select State"
                                  onChange={handleStateChange}
                                  value={selectedState}
                                  showSearch
                                >
                                  {states.map((state) => (
                                    <Option key={state} value={state}>
                                      {state}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="city"
                                label="City"
                              >
                                <Select
                                  placeholder="Select City"
                                  onChange={handleCityChange}
                                  showSearch
                                  value={selectedCity}
                                  disabled={!selectedState}
                                >
                                  {cities.map((city) => (
                                    <Option key={city.value} value={city.value}>
                                      {city.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="pinCode"
                                label="Pincode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Pincode is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter Your Pincode"
                                />
                              </Form.Item>
                            </div>
                            <legend className="para b themeCol-orange mx-3">
                              Contact Information:{" "}
                            </legend>
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="email"
                                label="Email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Email is required",
                                  },
                                  {
                                    type: "email",
                                    message: "Enter valid email",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter Your Email"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="phone"
                                label="Mobile"
                                rules={[
                                  {
                                    required: true,
                                    message: "phone number is required",
                                  },
                                  // {
                                  //   validator: validatePhoneNumber,
                                  // },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  type="number"
                                  placeholder="Enter Mobile Numner"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                      <div className=" p-0">
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          <div>
                            <Form.Item
                              name="website"
                              label="Website"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Website if you already have."
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className=""
                              name="facebook"
                              label="Facebook"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<FacebookOutlined />}
                                placeholder="FaceBook ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="instagram"
                              label="Instagram"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<InstagramOutlined />}
                                placeholder="Instagram ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="linkedin"
                              label="Linkedin"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<LinkedinOutlined />}
                                placeholder="LinkedIn ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="twitter"
                              label="Twitter"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<TwitterOutlined />}
                                placeholder="Twitter ID"
                                rules={[
                                  {
                                    type: "url",
                                    message: "please enter correct url",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </div>

                          {/* ************Bank Details************ */}
                          <legend className="para b themeCol-orange">
                            Banking Information:{" "}
                          </legend>
                          <div>
                            <Form.Item
                              name="bankName"
                              label=" Bank Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Bank name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Bank Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountName"
                              label="Account Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Account  name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Account Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="branchName"
                              label="Branch Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Branch name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Branch Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountNumber"
                              label=" Account Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Account Number is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                type="number"
                                placeholder="Your  Account Number"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="ifscCode"
                              label="IFSC Code"
                              rules={[
                                {
                                  required: true,
                                  message: "IFSC Code is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your IFSC Code"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="upiCode"
                              label=" UPI Code"
                              rules={[
                                {
                                  required: true,
                                  message: "  UPI Code is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your  UPI Code"
                              />
                            </Form.Item>
                          </div>

                          <div className="d-flex justify-content-center">
                            <Button
                              htmlType="submit"
                              className="btnnca btn-gold signupbtn"
                              style={{ width: "90px" }}
                            >
                              Update <i className="fa fa-arrow-right mx-1"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndividualProfile;
