import React, { useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { Form, Input, Button, Checkbox, message } from "antd";
import SHA256 from "crypto-js/sha256";

import BoyImage from "../../assets/da_img/login1.png";
import { useDispatch } from "react-redux";
import { uploadProfile } from "../../../slices/axiosSlice"; // Update the path

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
};

function Corp_Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const SigninformData = new FormData();
  const hashPassword = (password) => {
    const hashedPassword = SHA256(password).toString();
    return hashedPassword;
  };
  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };
  const onsubmit = (values) => {
    const userCred = {
      userId: values.email,
      userName: "",
      password: hashPassword(values.password),
      termsConditions: "",
      systemUpdateCommunication: "Email",
      userType: "CORP",
      userRole: "",
      associatedEntity: "",
      status: "",
      comments: "",
      passwordSetTime: "",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    if (values.remember) {
      localStorage.setItem("username", values.email);
      localStorage.setItem("password", values.password);
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }

    SigninformData.append("userCred", JSON.stringify(userCred));
    dispatch(uploadProfile({ url: "corp/login", formData: SigninformData }))
      .unwrap()
      .then((response) => {
        window.toastr.warning("Logged in successfully");
        navigate("/corpDashboard");
        sessionStorage.removeItem("key");
        sessionStorage.clear();

        sessionStorage.setItem(
          "da_userCred",
          JSON.stringify(response.data.userCred)
        );
        sessionStorage.setItem(
          "da_authMapping",
          JSON.stringify(response.data.userRoleAuthMapping)
        );
      })
      .catch((error) => {
        if (error.httpStatus === "UNAUTHORIZED") {
          window.toastr.warning("Unauthorized: Invalid credentials");
          navigate("/corpsignin");
        } else {
          window.toastr.warning("Kindly contact admin team for support");
          navigate("/corpsignin");
        }
      });
  };
  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");

    if (savedUsername && savedPassword) {
      form.setFieldsValue({
        email: savedUsername,
        password: savedPassword,
        remember: true,
      });
    }
  }, []);

  return (
    <>
      <div className="container-fluid bg_dtheme fontfamilynca">
        <div className="col-md-12 row">
          <div className="col-md-6  signupimg" style={boyImageCom}></div>

          <div className="col-md-6" style={{ height: "100vh" }}>
            <div className="row d-flex justify-content-center">
              <Form
                form={form}
                layout="vertical"
                initialValues={{ remember: false }}
                onFinish={onsubmit}
                onFinishFailed={showerror}
                className="row"
              >
                <div className="col-12 text-center">
                  <img
                    src={require("./../../assets/da_img/logo1.png")}
                    width={100}
                    height={100}
                    alt="DoAram_Logo"
                    className="rounded mx-auto d-block"
                  />
                  <small className="label text-white text-sm">
                    Let's Do Aram
                  </small>
                  <br />
                </div>

                <div className="row text-end d-flex justify-content-end ">
                  <div className="col">
                    <NavLink to="/" className="btn btn-outline-warning btn-sm">
                      <i className="fa fa-home"></i>
                    </NavLink>
                  </div>
                </div>

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text col">
                    <i
                      className="fa fa-user-circle themeCol-pink"
                      aria-hidden="true"
                    ></i>
                    &nbsp; LOGIN AS CORPORATE
                  </span>
                </div>

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-envelope  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating mb-3">
                    <Form.Item
                      name="email"
                      label="E-Mail"
                      rules={[
                        { required: true, message: "E-Mail is required" },
                        {
                          type: "email",
                          message: "Enter valid E-Mail address",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="doaram@gmail.com" bordered={false} />
                    </Form.Item>
                  </div>{" "}
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="input-group input-group-sm">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-key themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating mb-3">
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        { required: true, message: "Password is required" },
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Password" bordered={false} />
                    </Form.Item>
                  </div>{" "}
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="row mt-1">
                  <div className="form-check col-7 ">
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox
                        className="link-light font14 "
                        valuePropName="checked"
                      >
                        Remember me
                      </Checkbox>
                    </Form.Item>
                  </div>

                  <div className="col-5 text-end mt-1">
                    <NavLink className="link-light font14" to="/ForgotPassword">
                      ForgotPassword?
                    </NavLink>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="d-flex justify-content-center">
                    <Button
                      htmlType="submit"
                      className="btnnca btn-gold btn-lg col-md-4 text-lg"
                    >
                      Log in
                    </Button>
                  </div>
                </div>
              </Form>

              <div className="row">
                <div className="col text-center">
                  <span className="link-light font14 ">Not a member yet?</span>
                  <NavLink className="link-warning font14 " to="/corpsignup">
                    &nbsp; Sign up Now
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Corp_Login;
