import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Form, Input, Button, Upload, Tooltip, message, Select } from "antd";
import UploadService from "../../service/file-upload.service";
import { Country, State, City } from "country-state-city";
import SelectReact from "react-select";
import Loading from "../../assets/loading-circle.gif";
import { Modal } from "antd";
import $ from "jquery";

import {
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const OPTIONS = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function SignUpSelectors() {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  const allowedFileTypeswithpdf = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];
  const checkFileType = (file) => {
    const isFileTypeAllowed = allowedFileTypes.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUpload = (file) => {
    const isFileTypeAllowed = checkFileType(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };

  //////////////
  const checkFileTypewithpdf = (file) => {
    const isFileTypeAllowed = allowedFileTypeswithpdf.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUploadwithpdf = (file) => {
    const isFileTypeAllowed = checkFileTypewithpdf(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };
  const onsubmit = (values) => {
    setBlur(true); // Activate blur effect
    setLoading(true); // Show loader
    const formDataWithFiles = new FormData();
    const ngoProfile = {
      id: "",
      name: values.ngoProfile.name,
      category: values.ngoProfile.category.toString(),
      about: values.ngoProfile.about,
      history: values.ngoProfile.history,
      vision: values.ngoProfile.vision,
      mission: values.ngoProfile.mission,
      geoFocus: values.ngoProfile.geoFocus,
      staticWebPageLink: "",
      membership: "NGO_APPLICANT",
      certIncorpRoc:
        values.ngoProfile.certIncorpRoc != null
          ? values.ngoProfile.certIncorpRoc.file.name
          : "",
      moaAoa:
        values.ngoProfile.moaAoa != null
          ? values.ngoProfile.moaAoa.file.name
          : "",
      regTrustDeed:
        values.ngoProfile.regTrustDeed != null
          ? values.ngoProfile.regTrustDeed.file.name
          : "",
      pan: values.ngoProfile.pan != null ? values.ngoProfile.pan.file.name : "",
      tan: values.ngoProfile.tan != null ? values.ngoProfile.tan.file.name : "",
      csrForm1:
        values.ngoProfile.csrForm1 != null
          ? values.ngoProfile.csrForm1.file.name
          : "",
      cert12a:
        values.ngoProfile.cert12a != null
          ? values.ngoProfile.cert12a.file.name
          : "",
      cert80g:
        values.ngoProfile.cert80g != null
          ? values.ngoProfile.cert80g.file.name
          : "",
      certFcra:
        values.ngoProfile.certFcra != null
          ? values.ngoProfile.certFcra.file.name
          : "",
      itrForm:
        values.ngoProfile.itrForm != undefined
          ? values.ngoProfile.itrForm.file.name
          : "",
      form10B:
        values.ngoProfile.form10B != undefined
          ? values.ngoProfile.form10B.file.name
          : "",
      fcraReturns:
        values.ngoProfile.fcraReturns != undefined
          ? values.ngoProfile.fcraReturns.file.name
          : "",
      logo:
        values.ngoProfile.logo != undefined
          ? values.ngoProfile.logo.file.name
          : "",
      micrositeImages:
        values.ngoProfile.micrositeImages != undefined
          ? values.ngoProfile.micrositeImages.file.name
          : "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const contactInfo = {
      entityId: "",
      addressLine1: values.contactInfo.addressLine1,
      addressLine2: "",
      city: values.contactInfo.city.name,
      state: values.contactInfo.state.name,
      country: values.contactInfo.country.name,
      pinCode: values.contactInfo.pincode,
      email: values.contactInfo.email,
      phone: values.contactInfo.phone,
      website: values.contactInfo.website,
      facebook: values.contactInfo.facebook,
      instagram: values.contactInfo.instagram,
      linkedin: values.contactInfo.linkedin,
      twitter: values.contactInfo.twitter,
      otherlinks: "", //For now dont add anything to it
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const ngoCoordinator = {
      entityId: "",
      coordinatorEmail: values.ngoCoordinator.email,
      coordinatorName: values.ngoCoordinator.name,
      coordinatorPhone: values.ngoCoordinator.phone,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const bankDetail = {
      entityId: "",
      bankName: values.bankDetails.bankName,
      branchName: values.bankDetails.branchName,
      accountName: values.bankDetails.accountName,
      accountNumber: values.bankDetails.accountNumber,
      ifscCode: values.bankDetails.ifscCode,
      upiCode: values.bankDetails.upiCode,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append("ngoProfile", JSON.stringify(ngoProfile));
    formDataWithFiles.append("contactInfo", JSON.stringify(contactInfo));
    formDataWithFiles.append("ngoCoordinator", JSON.stringify(ngoCoordinator));
    formDataWithFiles.append("bankDetails", JSON.stringify(bankDetail));

    if (values.ngoProfile.logo != undefined) {
      formDataWithFiles.append(
        "logo",
        values.ngoProfile.logo.file.originFileObj
      );
    }
    if (values.ngoProfile.certIncorpRoc != undefined) {
      formDataWithFiles.append(
        "certIncorpRoc",
        values.ngoProfile.certIncorpRoc.file.originFileObj
      );
    }
    if (values.ngoProfile.moaAoa != undefined) {
      formDataWithFiles.append(
        "moaAoa",
        values.ngoProfile.moaAoa.file.originFileObj
      );
    }
    if (values.ngoProfile.regTrustDeed != undefined) {
      formDataWithFiles.append(
        "regTrustDeed",
        values.ngoProfile.regTrustDeed.file.originFileObj
      );
    }
    if (values.ngoProfile.pan != undefined) {
      formDataWithFiles.append("pan", values.ngoProfile.pan.file.originFileObj);
    }
    if (values.ngoProfile.tan != undefined) {
      formDataWithFiles.append("tan", values.ngoProfile.tan.file.originFileObj);
    }
    if (values.ngoProfile.csrForm1 != undefined) {
      formDataWithFiles.append(
        "csrForm1",
        values.ngoProfile.csrForm1.file.originFileObj
      );
    }
    if (values.ngoProfile.cert12a != undefined) {
      formDataWithFiles.append(
        "cert12a",
        values.ngoProfile.cert12a.file.originFileObj
      );
    }
    if (values.ngoProfile.cert80g != undefined) {
      formDataWithFiles.append(
        "cert80g",
        values.ngoProfile.cert80g.file.originFileObj
      );
    }
    if (values.ngoProfile.certFcra != undefined) {
      formDataWithFiles.append(
        "certFcra",
        values.ngoProfile.certFcra.file.originFileObj
      );
    }

    if (values.ngoProfile.itrForm != undefined) {
      values.ngoProfile.itrForm.fileList.forEach((file) => {
        formDataWithFiles.append("itrForm", file.originFileObj);
      });
    }
    if (values.ngoProfile.form10B != undefined) {
      values.ngoProfile.form10B.fileList.forEach((file) => {
        formDataWithFiles.append("form10B", file.originFileObj);
      });
    }
    if (values.ngoProfile.fcraReturns != undefined) {
      values.ngoProfile.fcraReturns.fileList.forEach((file) => {
        formDataWithFiles.append("fcraReturns", file.originFileObj);
      });
    }
    if (values.ngoProfile.micrositeImages != undefined) {
      values.ngoProfile.micrositeImages.fileList.forEach((file) => {
        formDataWithFiles.append("micrositeImages", file.originFileObj);
      });
    }

    UploadService.create("/ngo/signupWithPayment", formDataWithFiles)
      .then((response) => {
        let logSts = response.data.message.messageType;
        let logStsDesc = response.data.message.messageDetails[2];
        if (logSts == "Success") {
          setLoading(false); // Hide loader
          setBlur(false); // Deactivate blur effect
          window.toastr.warning(
            "Profile creation request received successfully"
          );
          sessionStorage.removeItem("key");
          sessionStorage.clear();

          sessionStorage.setItem(
            "da_userCred",
            JSON.stringify(response.data.data.userCred)
          );
          sessionStorage.setItem(
            "da_authMapping",
            JSON.stringify(response.data.data.userRoleAuthMapping)
          );
          navigate("/Upgrade");
        } else {
          window.toastr.danger(logStsDesc);
          navigate("/ngosignin");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error.message);
        window.toastr.warning("An error occurred. Please try again later.");
        setLoading(false); // Hide loader
        setBlur(false); // Deactivate blur effect // Make sure loading indicator is hidden
      });
  };

  const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };
  const movetohome = () => {
    navigate("/");
  };

  return (
    <>
     
      {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
        <div className="col-md-12 row fontfamilynca ">
          <Modal
            closeIcon={null}
            open={isModalOpen}
            //style={{ top: 20 }}
            // onOk={handleOk}
            // onCancel={handleCancel}
            footer={null}
          >
            <div className="d-flex justify-content-center mb-4">
              <img
                src={require("./../../assets/icons/check-icon.png")}
                alt="check-icon"
              />
            </div>
            <p>
              {" "}
              A verification link has been sent to your registered NGO
              authorized email id
            </p>
            <div className="d-flex justify-content-center mb-2">
              <Button type="primary" onClick={movetohome}>
                Home
              </Button>
            </div>
          </Modal>
          <div className="col-md-12" style={{ marginLeft: "0px" }}>
            <div className="">
              <div className="col-md-12 row text-center">
                <h6 className="b">
                  Fill in the details of your NGO
                  <br />
                  <small className="text-danger steps b  text-warning">
                    * Denotes Mandatory fields.
                  </small>
                </h6>
              </div>
              <Form
                //layout="vertical"
                onFinish={onsubmit}
                onFinishFailed={showerror}
                scrollToFirstError={true}
                name="validateOnly"
                labelCol={{ span: 8 }}
                //autoComplete="off"
              >
                <div className="col-md-12 m-3  glasscard ">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{ borderRight: "1px solid #f3f3f3" }}
                    >
                      <fieldset>
                        <div className=" p-0">
                          <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                            {/* Name of NGO*/}
                            <legend className="para b themeCol-orange mx-3">
                              NGO Information:{" "}
                            </legend>
                            <div className="row mb-3">
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["ngoProfile", "name"]}
                                  label="NGO Name"
                                  values={"NGO"}
                                  //colon={false}
                                  rules={[
                                    {
                                      required: true,
                                      message: "NGO name is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Name of  the NGO"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "phone"]}
                                  label="Contact No"
                                  values={"NGO"}
                                  //colon={false}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Contact number is required",
                                    },
                                    {
                                      validator: validatePhoneNumber,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    type="number"
                                    placeholder="Contact Number"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "addressLine1"]}
                                  label="Registered Address"
                                  //colon={false}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Address is required",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    //showCount
                                    style={{ height: 100, resize: "none" }}
                                    placeholder="Registered Address"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "country"]}
                                  label="Country"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Country is required",
                                    },
                                  ]}
                                >
                                  <SelectReact
                                    isClearable
                                    options={Country.getAllCountries()}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={selectedCountry}
                                    onChange={(item) => {
                                      setSelectedCountry(item);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "state"]}
                                  label="State"
                                  rules={[
                                    {
                                      required: true,
                                      message: "State is required",
                                    },
                                  ]}
                                >
                                  <SelectReact
                                    isClearable
                                    options={State?.getStatesOfCountry(
                                      selectedCountry?.isoCode
                                    )}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={selectedState}
                                    onChange={(item) => {
                                      setSelectedState(item);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "city"]}
                                  label="City"
                                  rules={[
                                    {
                                      required: true,
                                      message: "City is required",
                                    },
                                  ]}
                                >
                                  <SelectReact
                                    isClearable
                                    options={City.getCitiesOfState(
                                      selectedState?.countryCode,
                                      selectedState?.isoCode
                                    )}
                                    getOptionLabel={(options) => {
                                      return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                      return options["name"];
                                    }}
                                    value={selectedCity}
                                    onChange={(item) => {
                                      setSelectedCity(item);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "pincode"]}
                                  label="Pincode"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Pincode is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Pincode of  the NGO"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["contactInfo", "email"]}
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "NGO Email is required",
                                    },
                                    {
                                      type: "email",
                                      message: "Enter valid email",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Email of  the NGO"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["ngoProfile", "category"]}
                                  label="Category"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Category is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="multiple"
                                    placeholder="--NGO Category--"
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                    style={{
                                      width: "100%",
                                    }}
                                    options={filteredOptions.map((item) => ({
                                      value: item,
                                      label: item,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name={["contactInfo", "website"]}
                                  label="Website"
                                  values={"NGO"}
                                  requiredMark="optional"
                                  rules={[
                                    {
                                      type: "url",
                                      message: "please enter correct url",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Website if you already have."
                                  />
                                </Form.Item>
                              </div>
                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name={["ngoProfile", "logo"]}
                                  label="Logo"
                                >
                                  <Upload
                                    listType="picture"
                                    beforeUpload={customBeforeUpload}
                                    maxCount={1}
                                    accept=".jpg,.jpeg,.png"
                                  >
                                    <Tooltip title="Upload logo images of type jpg,jpeg,png and pdf Files only">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Tooltip>
                                  </Upload>
                                </Form.Item>
                              </div>

                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name={["ngoProfile", "micrositeImages"]}
                                  label=" Images for your website"
                                >
                                  <Upload
                                    maxCount={3}
                                    listType="picture"
                                    beforeUpload={customBeforeUpload}
                                    accept=".jpg,.jpeg,.png"
                                  >
                                    <Tooltip title="Upload  maximum of 3 images for website with file type jpg,jpeg,png and pdf Files only">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Tooltip>
                                  </Upload>
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name={["contactInfo", "facebook"]}
                                  label="Facebook"
                                  rules={[
                                    {
                                      type: "url",
                                      message: "please enter correct url",
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={<FacebookOutlined />}
                                    placeholder="FaceBook ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name={["contactInfo", "instagram"]}
                                  label="Instagram"
                                  rules={[
                                    {
                                      type: "url",
                                      message: "please enter correct url",
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={<InstagramOutlined />}
                                    placeholder="Instagram ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name={["contactInfo", "linkedin"]}
                                  label="Linkedin"
                                  rules={[
                                    {
                                      type: "url",
                                      message: "please enter correct url",
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={<LinkedinOutlined />}
                                    placeholder="LinkedIn ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name={["contactInfo", "twitter"]}
                                  label="Twitter"
                                  rules={[
                                    {
                                      type: "url",
                                      message: "please enter correct url",
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={<TwitterOutlined />}
                                    placeholder="Twitter ID"
                                    rules={[
                                      {
                                        type: "url",
                                        message: "please enter correct url",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>
                              {/* Dont use this for now
                             <div>
                              <Form.Item
                                className=""
                                name={["contactInfo", "otherlinks"]}
                                label="Other links"
                              >
                                <Input
                                  addonBefore={<LinkOutlined />}
                                  placeholder="Other Links(Optional)"
                                />
                              </Form.Item>
                            </div> */}
                              {/* *************************ngoCoordinator********************** */}
                              <legend className="para b themeCol-orange mx-4">
                                NGO Coordinator Information:{" "}
                              </legend>
                              <div>
                                <Form.Item
                                  name={["ngoCoordinator", "name"]}
                                  label="Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Coordinator name is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="NGO Coordinator Name"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name={["ngoCoordinator", "phone"]}
                                  label="Mobile"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Coordinator phone number is required",
                                    },
                                    {
                                      validator: validatePhoneNumber,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    type="number"
                                    placeholder="NGO Coordinator Mobile Numner"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name={["ngoCoordinator", "email"]}
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Coordinator email is required",
                                    },
                                    {
                                      type: "email",
                                      message: "Enter valid email",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="medium"
                                    placeholder="NGO Coordinator Email"
                                  />
                                </Form.Item>
                              </div>
                              {/* **********genenral info*********** */}
                              <legend className="para b themeCol-orange mx-4">
                                General NGO Information:{" "}
                              </legend>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["ngoProfile", "about"]}
                                  label="About"
                                  //colon={false}
                                  rules={[
                                    {
                                      required: true,
                                      message: "About is required",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    showCount
                                    maxLength={250}
                                    style={{ height: 150, resize: "none" }}
                                    placeholder="About the NGO (In 250 words)"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name={["ngoProfile", "history"]}
                                  label=" Brief History"
                                  //colon={false}
                                >
                                  <Input.TextArea
                                    showCount
                                    maxLength={250}
                                    style={{ height: 150, resize: "none" }}
                                    placeholder=" Brief History of the NGO (In 250 words)"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name={["ngoProfile", "vision"]}
                                  label="  Vision"
                                  rules={[
                                    {
                                      required: true,
                                      message: " Vision is required",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    showCount
                                    maxLength={150}
                                    style={{ height: 130, resize: "none" }}
                                    placeholder="  Vision of the NGO (In 150 words)"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name={["ngoProfile", "mission"]}
                                  label="  Mission"
                                >
                                  <Input.TextArea
                                    showCount
                                    minLength={100}
                                    maxLength={100}
                                    style={{ height: 130, resize: "none" }}
                                    placeholder="  Mission of the NGO (In 100 words)"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name={["ngoProfile", "geoFocus"]}
                                  label="Geography"
                                >
                                  {/* <Select
                                  showSearch
                                  placeholder="-- -- Geographic Focus of the activity -- --"
                                  optionFilterProp="children"
                                  options={[]}
                                /> */}
                                  <Input
                                    size="medium"
                                    placeholder="Geographic Focus of the activity"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-md-6">
                      <fieldset>
                        <div className=" p-0">
                          {/* *****************Certificate***************** */}
                          <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                            <legend className="para b themeCol-orange mx-2">
                              Copy of below Certificates to be uploaded:{" "}
                            </legend>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "certIncorpRoc"]}
                                label="Incorporation from ROC"
                              >
                                <Upload
                                  maxCount={1}
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "moaAoa"]}
                                label="MOA / AOA"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "regTrustDeed"]}
                                label="  Registered Trust Deed"
                                rules={[
                                  {
                                    required: true,
                                    message: "Trust Deed  is required",
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images of file type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>

                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "pan"]}
                                label="PAN"
                                rules={[
                                  {
                                    required: true,
                                    message: "PAN  is required",
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>

                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "tan"]}
                                label="TAN"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images of filetype  jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "cert12a"]}
                                label=" 12A Certificate"
                                rules={[
                                  {
                                    required: true,
                                    message: "12A Certificate is required",
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "cert80g"]}
                                label=" 80G Certificate"
                                rules={[
                                  {
                                    required: true,
                                    message: "80G certificate  is required",
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "csrForm1"]}
                                label=" CSR Form 1"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "certFcra"]}
                                label=" FCRA Reg"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={1}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <legend className="para b themeCol-orange">
                              Only for available Years. Upload all 3 years
                              together.
                            </legend>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "itrForm"]}
                                label="  ITR of last three AYs"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={3}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "form10B"]}
                                label=" Form 10B of last three AYs"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={3}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="upload">
                              <Form.Item
                                className=""
                                name={["ngoProfile", "fcraReturns"]}
                                label=" FCRA Returns of last three FYs"
                              >
                                <Upload
                                  listType="picture"
                                  beforeUpload={customBeforeUploadwithpdf}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  maxCount={3}
                                >
                                  <Tooltip title="Upload  images  of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>
                            {/* ************Bank Details************ */}
                            <legend className="para b themeCol-orange">
                              Banking Information:{" "}
                            </legend>
                            <div>
                              <Form.Item
                                name={["bankDetails", "bankName"]}
                                label=" Bank Name"
                                rules={[
                                  {
                                    required: true,
                                    message: " Bank name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Your Bank Name"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name={["bankDetails", "accountName"]}
                                label="Account Name"
                                rules={[
                                  {
                                    required: true,
                                    message: " Account  name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Your Account Name"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name={["bankDetails", "branchName"]}
                                label="Branch Name"
                                rules={[
                                  {
                                    required: true,
                                    message: " Branch name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Your Branch Name"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name={["bankDetails", "accountNumber"]}
                                label=" Account Number"
                                rules={[
                                  {
                                    required: true,
                                    message: "  Account Number is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  type="number"
                                  placeholder="Your  Account Number"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name={["bankDetails", "ifscCode"]}
                                label="IFSC Code"
                                rules={[
                                  {
                                    required: true,
                                    message: "IFSC Code is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Your IFSC Code"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name={["bankDetails", "upiCode"]}
                                label=" UPI Code"
                                rules={[
                                  {
                                    required: true,
                                    message: "  UPI Code is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Your  UPI Code"
                                />
                              </Form.Item>
                            </div>

                            <div className="row">
                              <div className="row">
                                <div className="col text-end">
                                  <NavLink to="/Signup">
                                    <Button
                                      name="signupback"
                                      className="btnnca"
                                      style={{ width: "90px" }}
                                    >
                                      {" "}
                                      <i className="fa fa-arrow-left mx-2"></i>
                                      Back
                                    </Button>
                                  </NavLink>
                                </div>
                                &nbsp;&nbsp;
                                <div className="col">
                                  {/* <NavLink to="/ngosignin"> */}
                                  <Button
                                    htmlType="submit"
                                    className="btnnca btn-gold signupbtn"
                                    style={{ width: "90px" }}
                                  >
                                    Submit{" "}
                                    <i className="fa fa-arrow-right mx-1"></i>
                                  </Button>
                                  {/* </NavLink> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignUpSelectors;
