import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";
import {  Tag } from "antd";
import {  useNavigate,  } from "react-router-dom";

function Corp_Dashboard(props) {
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState();
  const [ngoPercent, seNGOpercent] = useState();
  const [categoryPercent, setCategoryPercent] = useState();

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const CorpdashboardformData = new FormData();

  useEffect(() => {
    CorpdashboardformData.append("userCred", JSON.stringify(userCred));
    CorpdashboardformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    UploadService.create(
      "/corp/get/dashboard/summary",
      CorpdashboardformData
    ).then((response) => {
      setDashboardData(response.data.data.corporateDashboardSummary);
    });
  }, []);
  useEffect(() => {
    if (dashboardData) {
      let NGOtotalCount = dashboardData.totalNoActiveNGO;
      let NGOcount = dashboardData.ngoCountForMembershipLevels;
      seNGOpercent(
        NGOtotalCount > 0 ? ((NGOcount / NGOtotalCount) * 100).toFixed(2) : 0
      );

      let CategorytotalCount = dashboardData.totalNoActiveCauses;
      let Categorycount = dashboardData.activeCauseCountForCategories;
      setCategoryPercent(
        CategorytotalCount > 0
          ? ((Categorycount / CategorytotalCount) * 100).toFixed(2)
          : 0
      );
    }
  }, [dashboardData]);
  const displayMembershipLevels = (levels) => {
    return levels
      .split(",")
      .map((level) => level.replace("NGO_", ""))
      .join(",");
  };
  return (
    <>
      {dashboardData ? (
        <>
         
          <div className="col-md-12 row fontfamilynca">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mt-3 mb-2">
                  <div className="col-sm-10">
                    <h6 className="mb-3 tableheading">Corporate Dashboard</h6>
                  </div>
                </div>
              </div>
            </div>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <section className="col-md-12">
                    <div className="row">
                      <div
                        className="col-md-6"
                        onClick={() => {
                          navigate("/Corp_NGO");
                        }}
                      >
                        <div className="card">
                          <div className="mb-2">
                            <h3 className="tableheading b pb-3">
                              <i className="fa fa-chart-bar"></i> &nbsp; Prefered
                              Membership Level
                            </h3>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-5 d-flex justify-content-center">
                              <Progress
                                type="circle"
                                percent={ngoPercent}
                                strokeLinecap="square"
                                strokeWidth={15}
                                strokeColor={"#87677f"}
                                trailColor="#e9bf53"
                                width={200}
                                style={{ "--trail-width": "12px" }}
                                className="custom-progress"
                                format={(percent) =>
                                  percent === 100 ? `100%` : `${percent}%`
                                }
                              />
                            </div>
                            <div className="col-md-7">
                              <div className="clearfix">
                                <div className="squareindicator1 me-2"></div>
                                <p
                                  className="m-0 me-sm-5"
                                  style={{ display: "inline" }}
                                >
                                  {dashboardData.selectedMembershipLevels &&
                                    displayMembershipLevels(
                                      dashboardData.selectedMembershipLevels
                                    )}
                                </p>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div className="squareindicator2 me-2"></div>
                                <p className="m-0">Others </p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-center mt-4">
                            <Tag color="#87677f" className="mx-3">
                              Membership Count : &nbsp;
                              {dashboardData.ngoCountForMembershipLevels}
                            </Tag>
                            <Tag color="#e9bf53">
                              Total Count :&nbsp;
                              {dashboardData.totalNoActiveNGO}
                            </Tag>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        onClick={() => {
                          navigate("/Corp_Cause");
                        }}
                      >
                        <div className="card">
                          <div className="mb-2">
                            <h3 className="tableheading b pb-3">
                              <i className="fa fa-chart-bar"></i> &nbsp; Prefered
                              Cause Category
                            </h3>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-5 d-flex justify-content-center">
                              <Progress
                                type="circle"
                                percent={categoryPercent}
                                strokeLinecap="square"
                                strokeWidth={15}
                                strokeColor={"#87677f"}
                                trailColor="#e9bf53"
                                width={200}
                                format={(percent) =>
                                  percent === 100 ? `100%` : `${percent}%`
                                }
                                style={{ "--trail-width": "12px" }}
                                className="custom-progress"
                              />
                            </div>
                            <div className="col-md-7">
                              <div className="clearfix">
                                <div className="squareindicator1"></div>
                                <p
                                  className=" m-0 me-sm-5"
                                  style={{ display: "inline" }}
                                >
                                  {dashboardData.selectedCategories}{" "}
                                </p>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div className="squareindicator2 me-2"></div>
                                <p className="m-0">Others </p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-center mt-4">
                            <Tag color="#87677f" className="mx-3">
                              Category Count :&nbsp;
                              {dashboardData.activeCauseCountForCategories}
                            </Tag>
                            <Tag color="#e9bf53">
                              Total Count : &nbsp;
                              {dashboardData.totalNoActiveCauses}
                            </Tag>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>{" "}
          </div>
        </>
      ) : (
        "LOADING"
      )}
    </>
  );
}

export default Corp_Dashboard;
