import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import SelectReact from "react-select";
import { Form, Input, Button, Select, Tabs, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import UploadService from "../../service/file-upload.service";

import $ from "jquery";
import {
  LinkedinOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkOutlined,
} from "@ant-design/icons";
function Admin_Profile(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const userType = $.parseJSON(sessionStorage.getItem("da_userType"));
  const submitAdmin = (values) => {
    let adminProfile = {
      id: "ngo_rura_adi _2",
      firstName: values.firstName,
      lastName: values.lastName,
      role: "ADMIN",
      createdDate: null,
      createdBy: null,
      updatedDate: null,
      updatedBy: null,
      comments: "admin user",
    };
    let contactInfo = {
      entityId: "ngo_rura_adi _2",
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: values.country,
      pinCode: values.PinCode,
      email: values.userId,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: values.otherlinks,
      createdDate: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
    };
    const adminupdateformData = new FormData();
    adminupdateformData.append("userCred", JSON.stringify(userCred));
    adminupdateformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    adminupdateformData.append("adminProfile", JSON.stringify(adminProfile));
    adminupdateformData.append("contactInfo", JSON.stringify(contactInfo));
    UploadService.create("/admin/profile/update", adminupdateformData).then(
      (response) => {
        Modal.confirm({
          title: "Admin profile updated Sucessfully",
          onOk: () => {
            navigate("/Admin_Profile");
          },
        });
      }
    );
  };
  const onSubmit = (values) => {
    let newAdminUserCred = {
      userId: values.userId,
      userName: "",
      password: values.password,
      termsConditions: "Y",
      systemUpdateCommunication: "Email",
      userType: "ADMIN",
      userRole: "ADMIN_OPERATIONS",
      associatedEntity: "",
      status: "",
      createdDate: "",
      comments: "",
      passwordSetTime: "",
      emailVerified: "",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
    };
    let newAdminUserProfile = {
      id: "",
      firstName: values.firstName,
      lastName: values.lastName,
      role: "ADMIN_OPERATIONS",
      createdDate: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
      comments: "",
    };
    let newAdminContactInfo = {
      entityId: "",
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city.name,
      state: values.state.name,
      country: values.country.name,
      pinCode: values.PinCode,
      email: values.userId,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: values.otherlinks,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const adminprofileformData = new FormData();
    adminprofileformData.append("superAdminUserCred", JSON.stringify(userCred));
    adminprofileformData.append(
      "superAdminAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    adminprofileformData.append(
      "newAdminUserCred",
      JSON.stringify(newAdminUserCred)
    );
    adminprofileformData.append(
      "newAdminUserProfile",
      JSON.stringify(newAdminUserProfile)
    );
    adminprofileformData.append(
      "newAdminContactInfo",
      JSON.stringify(newAdminContactInfo)
    );

    UploadService.create("admin/signup", adminprofileformData).then(
      (response) => {
        Modal.confirm({
          title:"Admin Signup Sucessfull",
          onOk: () => {
            navigate("/Admin_Profile");
          },
        });
      }
    );
  };
  const getadminprofileformData = new FormData();
  getadminprofileformData.append("userCred", JSON.stringify(userCred));
  getadminprofileformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );

  useEffect(() => {
    
    UploadService.create("/admin/profile/get", getadminprofileformData).then(
      (response) => {
        form.setFieldsValue({
          adminfirstName: response.data.data.adminProfile.firstName,
          adminlastName: response.data.data.adminProfile.lastName,
          adminaddressLine1: response.data.data.contactInfo.addressLine1,
          adminuserId: response.data.data.contactInfo.email,
          admincity: response.data.data.contactInfo.city,
          adminstate: response.data.data.contactInfo.state,
          admincountry: response.data.data.contactInfo.country,
          adminpinCode: response.data.data.contactInfo.pinCode,
          adminemail: response.data.data.contactInfo.email,
          adminphone: response.data.data.contactInfo.phone,
          adminwebsite: response.data.data.contactInfo.website,
          adminfacebook: response.data.data.contactInfo.facebook,
          admininstagram: response.data.data.contactInfo.instagram,
          adminlinkedin: response.data.data.contactInfo.linkedin,
          admintwitter: response.data.data.contactInfo.twitter,
          adminotherlinks: response.data.data.contactInfo.otherlinks,
        });
      }
    );
  }, []);

  return (
    <>
    
      <div className="col-md-12 row fontfamilynca">
        <div className="content-header">
          <div className="container-fluid">
            {/* <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin Profile</h1>
              </div>
            </div> */}
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          centered
          type="card"
          large
          items={[
            {
              label: " Admin Profile",
              key: "1",
              children: (
                <div className="row ms-3 me-3">
                  <div className="glasscard">
                    <Form
                      autoComplete="off"
                      labelCol={{ span: 4 }}
                      onFinish={submitAdmin}
                      wrapperCol={{
                        span: 17,
                      }}
                      style={{ marginLeft: "0px" }}
                      form={form}
                      className="mt-3"
                    >
                      <div>
                        <Form.Item
                          name="adminfirstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Fisrt name required",
                            },
                          ]}
                        >
                          <Input size="medium" placeholder="First Name" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="adminlastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Last name is required",
                            },
                          ]}
                        >
                          <Input size="medium" placeholder="Last Name" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="adminuserId"
                          label="Email-ID"
                          rules={[
                            {
                              required: true,
                              message: "email is required",
                            },
                            {
                              type: "email",
                              message: "Enter valid email",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter Email address"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="adminaddressLine1"
                          label="Registered Address"
                          rules={[
                            {
                              required: true,
                              message: "Address is required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            style={{ height: 100, resize: "none" }}
                            placeholder="Registered Address"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange da_country"
                          name="admincountry"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Country is required",
                            },
                          ]}
                        >
                          <Select
                            countryid="101"
                            placeholder="-Select Country-"
                            // options={[
                            //   {
                            //     value: "India",
                            //     label: "India",
                            //   },
                            // ]}
                            disabled
                          ></Select>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange da_state"
                          name="adminstate"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "State is required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="-Select State-"
                            disabled
                            // options={[
                            //   {
                            //     value: "Tamilnadu",
                            //     label: "Tamilnadu",
                            //   },
                            // ]}
                          ></Select>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange da_cities"
                          name="admincity"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "City is required",
                            },
                          ]}
                        >
                          <Select
                            onChange={() => window.PopulateCountry()}
                            placeholder="-Select City-"
                            // options={[
                            //   {
                            //     value: "coimbatore",
                            //     label: "coimbatore",
                            //   },
                            // ]}
                            disabled
                          ></Select>
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="adminpinCode"
                          label="PinCode"
                          rules={[
                            {
                              required: true,
                              message: "PinCode is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input size="medium" placeholder="PinCode of NGO" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="adminphone"
                          label="Phone"
                          rules={[
                            {
                              required: true,
                              message: "contact number is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            addonBefore="IN(+91)"
                            size="medium"
                            placeholder="Contact number of NGO"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="adminwebsite"
                          label="Website"
                        >
                          <Input size="medium" placeholder="Website URL" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="adminfacebook"
                          label="Facebook"
                        >
                          <Input
                            addonBefore={<FacebookOutlined />}
                            placeholder="FaceBook ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="admininstagram"
                          label="Instagram"
                        >
                          <Input
                            addonBefore={<InstagramOutlined />}
                            placeholder="Instagram ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="adminlinkedin"
                          label="Linkedin"
                        >
                          <Input
                            addonBefore={<LinkedinOutlined />}
                            placeholder="LinkedIn ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="admintwitter"
                          label="Twitter"
                        >
                          <Input
                            addonBefore={<TwitterOutlined />}
                            placeholder="Twitter ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="adminotherlinks"
                          label="Other links"
                        >
                          <Input
                            addonBefore={<LinkOutlined />}
                            placeholder="Other Links(Optional)"
                          />
                        </Form.Item>
                      </div>

                      <div className="d-flex  justify-content-center">
                        <Button
                          htmlType="submit"
                          className="bg-yellowgra loginbtn"
                        >
                          Update Admin
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ),
            },
            {
              label: "Add user",
              key: "2",
              children: (
                <div className="row  ms-3 me-3">
                  <div className="glasscard">
                    <Form
                      autoComplete="off"
                      labelCol={{ span: 4 }}
                      wrapperCol={{
                        span: 17,
                      }}
                      className="mt-3"
                      onFinish={onSubmit}
                    >
                      <div>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Fisrt name required",
                            },
                          ]}
                        >
                          <Input size="medium" placeholder="First Name" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Last name is required",
                            },
                          ]}
                        >
                          <Input size="medium" placeholder="Last Name" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="userId"
                          label="Email-ID"
                          rules={[
                            {
                              required: true,
                              message: "email is required",
                            },
                            {
                              type: "email",
                              message: "Enter valid email",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter Email address"
                          />
                        </Form.Item>
                      </div>

                      <div className="admin">
                        <Form.Item
                          name="password"
                          label="Password"
                          rules={[
                            { required: true, message: "password is required" },
                            { min: 9, message: "Minimum length 9" },
                            { max: 15, message: "Maximum lenth is 15" },
                          ]}
                          hasFeedback
                        >
                          <Input.Password placeholder="Password" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="confirmpassword"
                          label="Confirm Password"
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: "Password",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Password Does not match"
                                );
                              },
                            }),
                          ]}
                          hasFeedback
                        >
                          <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="role"
                          label="Role"
                          rules={[
                            {
                              required: true,
                              message: "Role is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="-- -- Select Role -- --"
                            optionFilterProp="children"
                            options={[
                              {
                                value: "Admin",
                                label: "Admin",
                              },
                            ]}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="addressLine1"
                          label="Registered Address"
                          rules={[
                            {
                              required: true,
                              message: "Address is required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            style={{ height: 100, resize: "none" }}
                            placeholder="Registered Address"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="country"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Country is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedCountry}
                            onChange={(item) => {
                              setSelectedCountry(item);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "State is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={State?.getStatesOfCountry(
                              selectedCountry?.isoCode
                            )}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                              setSelectedState(item);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "City is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={City.getCitiesOfState(
                              selectedState?.countryCode,
                              selectedState?.isoCode
                            )}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedCity}
                            onChange={(item) => {
                              setSelectedCity(item);
                            }}
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="pinCode"
                          label="PinCode"
                          rules={[
                            {
                              required: true,
                              message: "PinCode is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input size="medium" placeholder="PinCode of NGO" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="phone"
                          label="Phone"
                          rules={[
                            {
                              required: true,
                              message: "contact number is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            addonBefore="IN(+91)"
                            size="medium"
                            placeholder="Contact number of NGO"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="website"
                          label="Website"
                        >
                          <Input size="medium" placeholder="Website URL" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="facebook"
                          label="Facebook"
                        >
                          <Input
                            addonBefore={<FacebookOutlined />}
                            placeholder="FaceBook ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="instagram"
                          label="Instagram"
                        >
                          <Input
                            addonBefore={<InstagramOutlined />}
                            placeholder="Instagram ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="linkedin"
                          label="Linkedin"
                        >
                          <Input
                            addonBefore={<LinkedinOutlined />}
                            placeholder="LinkedIn ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item className="" name="twitter" label="Twitter">
                          <Input
                            addonBefore={<TwitterOutlined />}
                            placeholder="Twitter ID"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className=""
                          name="otherlinks"
                          label="Other links"
                        >
                          <Input
                            addonBefore={<LinkOutlined />}
                            placeholder="Other Links(Optional)"
                          />
                        </Form.Item>
                      </div>

                      <div className="d-flex  justify-content-center">
                        <Button
                          htmlType="submit"
                          className="bg-yellowgra loginbtn"
                        >
                          Add Admin
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

export default Admin_Profile;
