import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, Select, Modal, message } from "antd";
import { HomeFilled, HomeOutlined } from "@ant-design/icons";
import UploadService from "../../service/file-upload.service";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import axios from "axios";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
function ForgotPassword() {
  const navigate = useNavigate();
  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };
  const onsubmit = (values) => {
    const userCred = {
      userId: values.email,
      userName: "",
      password: "",
      termsConditions: "",
      systemUpdateCommunication: "",
      userType: "",
      userRole: "",
      associatedEntity: "",
      status: "Inactive",
      comments: "",
      passwordSetTime: "",
      emailVerified: "Y",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const formDataForgotPassword = new FormData();
    formDataForgotPassword.append("userCred", JSON.stringify(userCred));

    UploadService.create("/ngo/forgotPassword", formDataForgotPassword).then(
      (response) => {
        Modal.info({
          content: (
            <div>
              <p>We have sent ResetLink To your Email Sucessfully!</p>
            </div>
          ),
          onOk() {navigate("/")},
        });
      }
    );
  };

  return (
    <div
      className="bg_dtheme fontfamilynca  col-md-12 row "
      style={{ height: "100vh" }}
    >
      <div className="row ">
        <div className="col-md-6  signupimg" style={boyImageCom}></div>

        <div className="col-md-6  mb-5 signup">

        <div className="row text-center d-flex justify-content-center ">
            <div className="col-md-3 mt-5"> 
                  <img
                    src={require("./../../assets/da_img/logo1.png")}
                    width={100}
                    height={100}
                    alt="DoAram_Logo"
                    className="rounded"
                  /> 
                  <br/>
                <small className="text-white ft-14 text-sm ">
                  Let's Do Aram
                </small> 
            </div>
            </div>
            <div className="row  d-flex justify-content-center ">
        
        <Form
              onFinishFailed={showerror}
              layout="vertical"
              autoComplete="off"
              onFinish={onsubmit}
              className="row g-3"
            >
                <div className="col-md-12">
                <div className="row d-flex justify-content-end align-content-center text-end pb-2">
                  <div className="col">
                    <NavLink to="/ngosignin" className="text-white font14">
                      Already User?
                      <span
                        className="text-warning text-sm toolTip top  "
                        data-tip="Login in with your existing account"
                      >
                        &nbsp;<b>Sign In</b>
                      </span>
                    </NavLink>
                    &nbsp;
                    <NavLink to="/" className="btn btn-outline-warning btn-sm">
                      <i className="fa fa-home"></i>
                    </NavLink>
                  </div>
                </div>
 
                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-envelope  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating mb-3">
                    <Form.Item
                      name="email"
                      label="E-Mail"
                      rules={[
                        { required: true, message: "E-Mail is required" },
                        {
                          type: "email",
                          message: "Enter valid E-Mail address",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="doaram@gmail.com" bordered={false} />
                    </Form.Item>
                  </div>{" "}
                  <span className="d-input-group-text border-0"></span>
                </div>
              
              </div>
              <div className="col-12  mt-4">
                <div className="d-flex  justify-content-center">
                  <Button
                    htmlType="submit"
                    className="btnnca btn-gold col-md-5 signupbtn"
                    style={{ height: "45px" }}
                  >
                    Send Email
                  </Button>
                </div>
                </div>
            </Form> 






            </div>
            </div>  
      </div>
    </div>
  );
}

export default ForgotPassword;
