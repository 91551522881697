import { createSlice } from '@reduxjs/toolkit';

const initialSignupState = {
  userId: "",
  userName: "",
  password: "",
  termsConditions: false, // Assuming it's a boolean
  systemUpdateCommunication: "Email",
  userType: "CORP",
  userRole: "CORP_USER",
  associatedEntity: "",
  status: "",
  comments: "",
  passwordSetTime: "",
  numLogin: 0,
  lastLoginTime: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
};

const signupSlice = createSlice({
  name: 'corpsignup',
  initialState: initialSignupState,
  reducers: {
    updateSignup(state, action) {
      return { ...state, ...action.payload };
    },
    resetSignup(state) {
      return initialSignupState;
    },
  },
});

export const { updateSignup, resetSignup } = signupSlice.actions;
export default signupSlice.reducer;
